import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import styled from "@emotion/styled";
import { AppBar, Card, Container } from "@mui/material";
import { ContentCopy, Print, QrCode } from "@mui/icons-material";

import SuiButton from "../components/soft-ui/SuiButton";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";

import mainPage from "../assets/images/main-page.jpeg";

const redeemUrl = `${document.location.origin}/redeem`;

const Page = styled.div(() => ({
  backgroundImage: `url(${mainPage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  width: "100%",
  height: "100vh",
}));

const Header = styled(AppBar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),
  background: "white",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
  "@media print": {
    display: "none",
  },
}));

const Paper = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    maxWidth: "unset !important",
    width: "100%",
  },
}));

const LogoImg = styled.img(({ theme }) => ({
  height: 128,
  marginBottom: theme.spacing(4),
}));

const CopyWrapper = styled(SuiBox)(() => ({
  "@media print": {
    display: "none",
  },
}));

function GCGenerate() {
  const [qrAddress, setQrAddress] = useState("");
  const [qrPrivate, setQrPrivate] = useState("");
  const [redeemUrlQr, setRedeemUrlQr] = useState("");
  const [copied, setCopied] = useState(false);

  const handlePrint = () => {
    window.print();
  };

  const handleRegenerate = () => {
    // Regenerate wallet
    const e = new Uint8Array(32);
    crypto.getRandomValues(e);
    const wallet = ethers.Wallet.fromMnemonic(ethers.utils.entropyToMnemonic(e));

    // Set variables
    setQrAddress(wallet.address);
    setQrPrivate(wallet.privateKey);
    setRedeemUrlQr(
      `${redeemUrl}#${ethers.utils.base64
        .encode(wallet.privateKey)
        .replace("+", "-")
        .replace("/", "_")
        .replace("=", "")}`
    );
  };

  useEffect(() => {
    handleRegenerate();
  }, []);

  return (
    <Page>
      <Header position="fixed">
        <SuiButton
          circular
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleRegenerate}
          startIcon={<QrCode />}
        >
          Regenerate
        </SuiButton>
        <SuiButton
          circular
          size="small"
          variant="contained"
          color="primary"
          onClick={handlePrint}
          startIcon={<Print />}
        >
          Print
        </SuiButton>
      </Header>
      <Paper sx={{ pt: 8 }}>
        <SuiBox
          sx={{
            mt: 4,
            ml: "auto",
            mr: 1,
            width: "fit-content",
          }}
        >
          <Card
            sx={{
              p: 0.5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              borderRadius: 1,
              border: `1px solid #ccc`,
            }}
          >
            <SuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
              <SuiTypography sx={{ fontSize: "14px" }}>Patriot Pay Gift Card</SuiTypography>
              <SuiTypography sx={{ fontSize: "10px", lineHeight: "12px" }}>
                {qrAddress}
              </SuiTypography>
            </SuiBox>
            <QRCode value={qrAddress} size={48} />
          </Card>
        </SuiBox>
        <SuiBox mt={6} mb={6} display="flex" flexDirection="column" alignItems="center">
          <LogoImg src="/logos/ppy.svg" />
          <SuiTypography variant="h4" mb={2}>
            Welcome to the Patriot Pay Community!
          </SuiTypography>
          <SuiTypography variant="body2" mb={2} textAlign="center">
            To redeem the Gift Card,
            <br />
            scan the following QR code using your phone&apos;s camera.
          </SuiTypography>
          <QRCode value={redeemUrlQr} size={200} />
          <SuiTypography variant="body2" mt={2}>
            No camera? Go to:{" "}
            <a target="_blank" rel="noreferrer" href={redeemUrl}>
              {redeemUrl}
            </a>
          </SuiTypography>
          <SuiTypography variant="body2">Then enter the following private key:</SuiTypography>
          <SuiBox display="flex" flexDirection="row" alignItems="center">
            <SuiTypography variant="caption" color="secondary">
              {qrPrivate}
            </SuiTypography>
            <CopyWrapper ml={2}>
              <CopyToClipboard text={qrPrivate} onCopy={() => setCopied(true)}>
                <ContentCopy
                  color={copied ? "secondary" : "primary"}
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                />
              </CopyToClipboard>
            </CopyWrapper>
          </SuiBox>

          <SuiTypography variant="body2" mt={8}>
            For support, please email us at{" "}
            <a href="mailto:support@patriotpay.org">support@patriotpay.org</a>
          </SuiTypography>
          <SuiTypography variant="caption" textAlign="center" mt={1}>
            Fine Print: PPY Coins may be deemed as financial instruments and are subject to risk of
            loss of principal. Past performance is not indicative of future results. PPY Coins are
            not securities or insurance products. Patriot Pay, LLC makes no guarantees regarding the
            performance or privileges related to PPY Coins.
          </SuiTypography>
        </SuiBox>
      </Paper>
    </Page>
  );
}

export default GCGenerate;
