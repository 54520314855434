import { Box } from "@mui/material";

import { isExpiredProduct } from "utils";
import { useVault } from "context";

export default function StatusMarks({ product, isDetail = false }) {
  const { user } = useVault();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isDetail ? "flex-start" : "center",
          fontSize: "14px",
          textTransform: "capitalize",
          color: "white.main",
          mt: 1,
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            p: "3px 8px",
            backgroundColor:
              product.approve_status === "Pending"
                ? "info.main"
                : product.approve_status === "Declined"
                ? "error.main"
                : "success.main",
          }}
        >
          {product.approve_status}
        </Box>
        <Box
          sx={{
            borderRadius: "5px",
            p: "3px 8px",
            backgroundColor: isExpiredProduct(product)
              ? "dark.main"
              : product.state === "Listed"
              ? "info.main"
              : product.state === "Escrowed"
              ? "warning.main"
              : product.state === "Disputed"
              ? "error.main"
              : "success.main",
            ml: 1,
          }}
        >
          {isExpiredProduct(product) ? "Expired" : product.state}
        </Box>
      </Box>
      {isDetail && user.id === product.user_id && product.approve_status === "Declined" && (
        <Box sx={{ mt: 1, fontSize: "12px" }}>
          <b>Reason:</b> {product.decline_reason}
        </Box>
      )}
    </>
  );
}
