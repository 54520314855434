import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import SuiButton from "components/soft-ui/SuiButton";
import useAPI from "utils/api";
import { useSnackbar } from "context";

export default function DeleteButton({ product, styles, size = "medium", callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await api("delete_product", {
        productId: product.id,
      });

      showSnackbar("Success", "Deleted successfully", "info");
      callback();
    } catch (err) {
      showSnackbar("Error", "Failed to delete product", "error");
    }

    setDeleting(false);
  };

  const onDeleteProduct = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDelete();
      }
    });
  };

  return (
    <SuiButton
      color="error"
      size={size}
      circular
      sx={{ textTransform: "capitalize", ...styles }}
      disabled={deleting}
      onClick={onDeleteProduct}
    >
      {deleting ? (
        <CircularProgress size={size === "small" ? 15 : 20} sx={{ color: "#fff" }} />
      ) : (
        "Delete"
      )}
    </SuiButton>
  );
}
