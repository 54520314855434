import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CopyToClipboard from "react-copy-to-clipboard";
import { useVault } from "../../context/vault";
import SuiBox from "../soft-ui/SuiBox";
import SuiButton from "../soft-ui/SuiButton";
import { vesRead } from "../../config/ves";

export default function MnemonicDialog({ open, onClose }) {
  const { user, vault } = useVault();
  const [phrase, setPhrase] = useState("");

  useEffect(() => {
    const getPhrase = async () => {
      if (vault) {
        const result = await vesRead(user.masterName);
        setPhrase(result);
      }
    };

    getPhrase();
  }, [vault, user.masterName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Wallet Recovery Mnemonic</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption">
          Write this down or save to a thumb drive and keep it in a safe place. Do not store this
          information online.
        </DialogContentText>
        <SuiBox p={4}>
          <CopyToClipboard text={phrase}>
            <DialogContentText variant="h6">{phrase}</DialogContentText>
          </CopyToClipboard>
        </SuiBox>
      </DialogContent>
      <DialogActions>
        <SuiButton onClick={onClose} fullWidth variant="outlined" color="dark">
          OK
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
