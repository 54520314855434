import { memo } from "react";
import { Box } from "@mui/material";

import loading from "../assets/images/loading.svg";

export default memo(() => (
  <Box
    sx={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
    }}
  >
    <img src={loading} alt="Loading" />
  </Box>
));
