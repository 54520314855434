import { useMemo } from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useVault } from "../context/vault";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiButton from "../components/soft-ui/SuiButton";
import mainPage from "../assets/images/main-page.jpeg";
import SuiInput from "../components/soft-ui/SuiInput";
import SuiTypography from "../components/soft-ui/SuiTypography";
import SuiSeparator from "../components/soft-ui/SuiSeparator";
import LogoImg from "../assets/images/logos/ppy-small.svg";

const PageWrapper = styled.div(() => ({
  backgroundImage: `url(${mainPage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

const ContentWrapper = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  maxWidth: 400,
}));

const ImageWrapper = styled.div(({ theme }) => ({
  width: "50%",
  minWidth: 240,
  maxWidth: 300,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: theme.spacing(2),

  "& > img": {
    width: "100%",
    height: "100%",
  },
}));

function Login() {
  const { loginWithVes, loginWithMagic } = useVault();

  // TODO: get rid of ves login
  // const [params] = useSearchParams();
  // const loginOption = params.get("login") ?? "magic";
  const loginOption = "magic";

  const initialValues = useMemo(() => {
    const loginSettings = localStorage.getItem("loginSettings") || "{}";

    return JSON.parse(loginSettings);
  }, []);

  const validationSchema = Yup.object().shape({
    email:
      loginOption === "magic"
        ? Yup.string().required("Email is required.").email("Invalid email format")
        : Yup.string(),
    username:
      loginOption === "webauthn" ? Yup.string().required("Username is required.") : Yup.string(),
  });

  const handleRegister = (username) => {
    loginWithMagic({ username, type: "register" });
    localStorage.setItem("loginSettings", JSON.stringify({ username }));
  };

  const handleSubmit = ({ email, username }) => {
    if (loginOption === "ves") {
      loginWithVes("/home");
    } else if (loginOption === "magic") {
      loginWithMagic({ email });
    } else if (loginOption === "webauthn") {
      loginWithMagic({ username, type: "login" });
    }
    localStorage.setItem("loginSettings", JSON.stringify({ email, username }));
  };

  return (
    <PageWrapper>
      <SuiBox
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width="100%"
        p={2}
      >
        <ContentWrapper>
          <ImageWrapper>
            <img src={LogoImg} alt="PatriotPay Logo" />
          </ImageWrapper>
          <SuiTypography variant="h6" fontWeight="medium" align="center">
            Type in your email to create an account or access your existing account. It&apos;s that
            easy!
          </SuiTypography>
          <SuiBox mt={5}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, isValid, handleChange }) => (
                <Form>
                  {loginOption === "webauthn" ? (
                    <>
                      <SuiBox mb={2}>
                        <SuiInput
                          id="username"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          placeholder="Username"
                          error={touched.username && errors.username}
                          helperText={errors.username}
                        />
                      </SuiBox>
                      <SuiBox display="flex" flexDirection="column" gap={2} alignItems="center">
                        <SuiButton
                          type="submit"
                          color="secondary"
                          borderRadius="lg"
                          fullWidth
                          circular
                          disabled={!isValid || isSubmitting}
                        >
                          Login
                        </SuiButton>
                        <SuiBox width="100%">
                          <SuiSeparator content="or" />
                        </SuiBox>
                        <SuiButton
                          color="secondary"
                          borderRadius="lg"
                          fullWidth
                          onClick={() => handleRegister(values.username)}
                          circular
                          variant="outlined"
                          disabled={!isValid || isSubmitting}
                        >
                          Register
                        </SuiButton>
                      </SuiBox>
                    </>
                  ) : (
                    <>
                      {loginOption === "magic" && (
                        <SuiBox mb={2}>
                          <SuiInput
                            type="email"
                            autoComplete="email"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            error={touched.email && errors.email}
                            helperText={errors.email}
                          />
                        </SuiBox>
                      )}
                      <SuiButton
                        type="submit"
                        color="secondary"
                        borderRadius="lg"
                        fullWidth
                        disabled={!isValid || isSubmitting}
                        circular
                      >
                        Login {loginOption === "ves" ? "with VES" : ""}
                      </SuiButton>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </SuiBox>
        </ContentWrapper>
      </SuiBox>
    </PageWrapper>
  );
}

export default Login;
