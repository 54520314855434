import axios from "axios";
import { useVault } from "context/vault";
import { useCallback } from "react";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_ORIGIN}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the Authorization header
apiClient.interceptors.request.use(
  (config) =>
    // const token = sessionStorage.getItem("apiToken");
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    config,
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 and 403 errors
// apiClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       const { status } = error.response;
//       if (status === 401 || status === 403) {
//         console.error(`Error ${status}: Access is unauthorized or forbidden.`);

//         sessionStorage.removeItem("apiToken");
//         window.location.href = "/";
//       }
//     }
//     return Promise.reject(error);
//   }
// );

const useAPI = () => {
  const { isAuthenticated, apiToken, logout } = useVault();

  return useCallback(
    (api, data, isIncludeFile = false) => {
      if (isAuthenticated) {
        return (async () => {
          try {
            const response = await apiClient.post(
              api,
              isIncludeFile ? data : JSON.stringify(data),
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${apiToken}`,
                  "Content-Type": isIncludeFile ? "multipart/form-data" : "application/json",
                },
              }
            );

            return response.data;
          } catch (err) {
            // TODO: Hook into an error feedback UI element like a snackbar or a toast.
            console.log("useAPI error", err.response);

            if ([401, 403].includes(err.response?.status)) {
              logout();
            }

            throw err.response.data;
          }
        })();
      } else {
        throw Error("Unauthenticated");
      }
    },
    [isAuthenticated, apiToken, logout]
  );
};

export default useAPI;
