import React, { useMemo } from "react";
import { Dialog, DialogContent, Box, styled } from "@mui/material";
import Loading from "../Loading";

const onramperSymbol = (symbol, network) => {
  const lowerCaseSymbol = symbol?.toLowerCase();
  const lowerCaseNetwork = network?.toLowerCase();

  const symbols = ["BNB", "POL", "USDT", "USDC", "WBTC"];
  if (symbols.includes(symbol)) {
    return `${lowerCaseSymbol}_${lowerCaseNetwork}`;
  }

  return lowerCaseSymbol;
};

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100vw",
      height: "calc(100% - 100px)",
      marginTop: 100,
      paddingBottom: 24,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  "& .MuiDialogContent-root": {
    width: 400,
    maxWidth: "calc(100vw - 64px)",
    minHeight: 350,
    position: "relative",
    padding: 0,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      maxWidth: "unset",
    },
  },
}));

const OnramperContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  maxWidth: 600,
  paddingTop: "90%",
  paddingBottom: "90%",

  "& > iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "unset",
    width: "100%",
    height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    "& > iframe": {
      position: "relative",
    },
  },
}));

export default function OnramperDialog({ open, onClose, mode, symbol, network, address, userId }) {
  const iframeUrl = useMemo(() => {
    const isBuy = mode === "pol_ppy" ? "buy" : mode;

    return [
      `https://buy.onramper.com?apiKey=${process.env.REACT_APP_ONRAMPER_KEY}`,
      `&mode=${isBuy === "buy" ? "buy" : "sell"}`,
      `&${isBuy === "buy" ? "" : "sell_"}onlyCryptos=${
        mode === "pol_ppy" ? "pol_polygon" : onramperSymbol(symbol, network)
      }`,
      isBuy === "buy"
        ? `&wallets=${
            mode === "pol_ppy" ? "pol_polygon" : onramperSymbol(symbol, network)
          }:${address}`
        : "",
      `&successRedirectUrl=${encodeURIComponent(`${window.location.origin}/assets`)}`,
      `&partnerContext=${userId}`,
    ].join("");
  }, [mode, symbol, network, address, userId]);

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        >
          <Loading />
        </Box>

        <OnramperContainer>
          {address && (
            <iframe
              src={iframeUrl}
              title="Onramper Widget"
              allow="accelerometer; autoplay; camera; gyroscope; payment"
            />
          )}
        </OnramperContainer>
      </DialogContent>
    </DialogWrapper>
  );
}
