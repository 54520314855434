/**
 *
 * detectIncognito v1.1.1 - (c) 2022 Joe Rutkowski <Joe@dreggle.com> (https://github.com/Joe12387/detectIncognito)
 *
 * */
const detectIncognito = () =>
  new Promise((resolve, reject) => {
    let browserName = "Unknown";

    function callback(isPrivate) {
      resolve({
        isPrivate,
        browserName,
      });
    }

    function identifyChromium() {
      const ua = navigator.userAgent;
      if (ua.match(/Chrome/)) {
        if (navigator.brave !== undefined) {
          return "Brave";
        } else if (ua.match(/Edg/)) {
          return "Edge";
        } else if (ua.match(/OPR/)) {
          return "Opera";
        }
        return "Chrome";
      } else {
        return "Chromium";
      }
    }

    function assertEvalToString(value) {
      // eslint-disable-next-line no-eval
      return value === eval.toString().length;
    }

    function isSafari() {
      const v = navigator.vendor;
      return v !== undefined && v.indexOf("Apple") === 0 && assertEvalToString(37);
    }

    function isChrome() {
      const v = navigator.vendor;
      return v !== undefined && v.indexOf("Google") === 0 && assertEvalToString(33);
    }

    function isFirefox() {
      return (
        document.documentElement !== undefined &&
        document.documentElement.style.MozAppearance !== undefined &&
        assertEvalToString(37)
      );
    }

    function isMSIE() {
      return navigator.msSaveBlob !== undefined && assertEvalToString(39);
    }

    /**
     * Safari (Safari for iOS & macOS)
     * */

    function newSafariTest() {
      try {
        const db = window.indexedDB.open("test", 1);
        db.onupgradeneeded = (i) => {
          const res = i.target.result;
          try {
            res
              .createObjectStore("test", {
                autoIncrement: true,
              })
              .put(new Blob());
            callback(false);
          } catch (e) {
            callback(/BlobURLs are not yet supported/.test(e.message));
          }
        };
      } catch (e) {
        callback(false);
      }
    }

    function oldSafariTest() {
      const openDB = window.openDatabase;
      const storage = window.localStorage;
      try {
        openDB(null, null, null, null);
      } catch (e) {
        return callback(true);
      }
      try {
        storage.setItem("test", "1");
        storage.removeItem("test");
      } catch (e) {
        return callback(true);
      }
      return callback(false);
    }

    function safariPrivateTest() {
      if (navigator.maxTouchPoints !== undefined) {
        newSafariTest();
      } else {
        oldSafariTest();
      }
    }

    /**
     * Chrome
     * */

    function getQuotaLimit() {
      const w = window;
      if (
        w.performance !== undefined &&
        w.performance.memory !== undefined &&
        w.performance.memory.jsHeapSizeLimit !== undefined
      ) {
        return performance.memory.jsHeapSizeLimit;
      }
      return 1073741824;
    }

    // >= 76
    function storageQuotaChromePrivateTest() {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        (usage, quota) => {
          callback(quota < getQuotaLimit());
        },
        (e) => {
          reject(new Error(`detectIncognito somehow failed to query storage quota: ${e.message}`));
        }
      );
    }

    // 50 to 75
    function oldChromePrivateTest() {
      const fs = window.webkitRequestFileSystem;
      const success = () => {
        callback(false);
      };
      const error = () => {
        callback(true);
      };
      fs(0, 1, success, error);
    }

    function chromePrivateTest() {
      if (Promise !== undefined && Promise.allSettled !== undefined) {
        storageQuotaChromePrivateTest();
      } else {
        oldChromePrivateTest();
      }
    }

    /**
     * Firefox
     * */

    function firefoxPrivateTest() {
      callback(navigator.serviceWorker === undefined);
    }

    /**
     * MSIE
     * */

    function msiePrivateTest() {
      callback(window.indexedDB === undefined);
    }

    function main() {
      if (isSafari()) {
        browserName = "Safari";
        safariPrivateTest();
      } else if (isChrome()) {
        browserName = identifyChromium();
        chromePrivateTest();
      } else if (isFirefox()) {
        browserName = "Firefox";
        firefoxPrivateTest();
      } else if (isMSIE()) {
        browserName = "Internet Explorer";
        msiePrivateTest();
      } else {
        reject(new Error("detectIncognito cannot determine the browser"));
      }
    }

    main();
  });

export default detectIncognito;
