import { Carousel } from "react-responsive-carousel";
import { Box, Backdrop } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./carousel.css";

export default function CarouselComponent({ items, open, onClose }) {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1000,
        background: "rgba(0, 0, 0, 0.9)",
      }}
      open={open}
    >
      <Box sx={{ maxWidth: "800px", width: "90%" }}>
        <Carousel showIndicators={false} emulateTouch dynamicHeight>
          {items.map((item, i) => (
            <img
              key={i}
              src={item}
              alt=""
              style={{
                objectFit: "contain",
                userSelect: "none",
              }}
            />
          ))}
        </Carousel>
      </Box>
      <CloseRoundedIcon
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "#fff",
          cursor: "pointer",
        }}
        onClick={onClose}
      />
    </Backdrop>
  );
}
