import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useVault } from "context/vault";
import SuiBox from "components/soft-ui/SuiBox";

import brand from "../../../assets/images/logos/ppy-small.svg";

export default function Header() {
  const navigate = useNavigate();
  const { user } = useVault();
  const [anchorEl, setAnchorEl] = useState(null);

  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  return (
    <SuiBox>
      <AppBar position="static" sx={{ borderBottom: "1px solid #E5EAF2" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <SuiBox component={NavLink} to="/" sx={{ display: "flex" }}>
            <SuiBox component="img" src={brand} alt="PatriotPay Logo" width="140px" />
          </SuiBox>
          <SuiBox>
            <IconButton color="inherit" onClick={showMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={hideMenu}
              onClick={hideMenu}
            >
              {user.is_admin && <MenuItem onClick={() => navigate("/admin")}>Admin</MenuItem>}
              <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
              <MenuItem onClick={() => navigate("/education")}>Education</MenuItem>
              <MenuItem onClick={() => navigate("/marketplace")}>Marketplace</MenuItem>
              <MenuItem onClick={() => navigate("/donations")}>Donations</MenuItem>
              <MenuItem onClick={() => navigate("/report")}>Report A Bug</MenuItem>
            </Menu>
          </SuiBox>
        </Toolbar>
      </AppBar>
    </SuiBox>
  );
}
