import { useNavigate } from "react-router-dom";

import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";
import SuiButton from "../components/soft-ui/SuiButton";
import ppyWhite from "../assets/images/logos/ppy-new.png";
import mainPage from "../assets/images/main-page.jpeg";
import GiftInfo from "../components/GiftWallet";

function PublicMain() {
  const navigate = useNavigate();

  // TODO: get rid of ves login
  // const [params] = useSearchParams();
  // const loginOption = params.get("login") ?? "magic";
  const loginOption = "magic";

  const handleLogin = () => {
    if (loginOption === "magic") {
      navigate("/login");
    } else {
      navigate(`/login?login=${loginOption || ""}`);
    }
  };

  return (
    <div
      className="loginbody"
      style={{
        backgroundImage: `url(${mainPage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <SuiBox
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width="100%"
        p={2}
      >
        <SuiBox maxWidth="350px" width="100%">
          <SuiBox
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="280px"
          >
            <SuiBox zIndex="2">
              <SuiTypography variant="h2" color="#15294e" fontWeight="bold">
                Welcome, Patriots!
              </SuiTypography>
            </SuiBox>
            <SuiBox
              zIndex="1"
              component="img"
              src={ppyWhite}
              alt="PatriotPay White Logo"
              width="300px"
              height="300px"
              opacity="1"
            />
          </SuiBox>
          <SuiBox px={3} py={4} bgColor="#e3dcdc" borderRadius="lg" mt={3}>
            <SuiButton color="secondary" borderRadius="lg" fullWidth onClick={handleLogin}>
              Access {loginOption === "ves" ? "with VES" : ""}
            </SuiButton>
          </SuiBox>
          <SuiBox marginTop="8px">
            <GiftInfo />
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </div>
  );
}

export default PublicMain;
