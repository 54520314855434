import { useCallback, useEffect, useState } from "react";
import { isValidAddress, useCanMessage, useStartConversation } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

import SuiTypography from "components/soft-ui/SuiTypography";
import MessageInput from "./MessageInput";

export default function NewMessage({ toAddress, conversationId, onSuccess }) {
  const [isOnNetwork, setIsOnNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { startConversation } = useStartConversation({
    conversationId,
    metadata: { productOwner: toAddress },
  });
  const { canMessage } = useCanMessage();

  const handleStartConversation = useCallback(
    async (message) => {
      if (toAddress && isOnNetwork) {
        setIsLoading(true);
        const result = await startConversation(toAddress, message);
        setIsLoading(false);
        if (result) {
          onSuccess?.(result.cachedConversation);
        }
      }
    },
    [isOnNetwork, onSuccess, toAddress, startConversation]
  );

  useEffect(() => {
    const checkAddress = async () => {
      if (isValidAddress(toAddress)) {
        setIsLoading(true);
        setIsOnNetwork(await canMessage(toAddress));
        setIsLoading(false);
      } else {
        setIsOnNetwork(false);
      }
    };

    checkAddress();
  }, [canMessage, toAddress]);

  let errorText;
  let isError = false;

  if (!isValidAddress(toAddress)) {
    errorText = "Product owner's address must be a valid 0x wallet address";
    isError = true;
  } else if (!isOnNetwork) {
    errorText = "Sorry, we can't message because product owner hasn't initialized XMTP yet";
    isError = true;
  }

  return (
    <Box>
      <MessageInput isDisabled={isLoading || isError} onSubmit={handleStartConversation} />
      <SuiTypography mt={1} fontSize={12} fontWeight="bold" color="error">
        {errorText}
      </SuiTypography>
    </Box>
  );
}
