import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Magic } from "magic-sdk";
import { Field, Form, Formik } from "formik";
import { Card, Grid } from "@mui/material";
import { TextField as FormikTextField } from "formik-mui";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ethers } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { Map } from "immutable";

import { getNetwork } from "../services";
import { magicSign, sign } from "../utils/eip712";
import { getSmartWalletAddress, INSTANCE_SALT } from "../utils/contracts";
import { CONTRACTS } from "../context/web3";
import IERC20 from "../contracts/IERC20.json";
import currencies from "../config/popular.json";
import { magicProvider } from "../config/magic";
import { useVault } from "../context/vault";
import useAPI from "../utils/api";

import SuiBox from "./soft-ui/SuiBox";
import SuiTypography from "./soft-ui/SuiTypography";
import SuiButton from "./soft-ui/SuiButton";
import Loading from "./Loading";
import PopupAck from "./dialog/PopupAck";
import EstimationDialog from "./dialog/EstimationDialog";

import PPYLogo from "../assets/images/coin-logos/PPY.svg";
import SwapOrderFormHeader from "../features/swap/SwapOrderFormHeader";
import AmountInputField from "../features/swap/AmountInputField";
import { extraPricePrecision, formatFloatByDecimal } from "../utils/numeric";
import { useQuotes } from "context";

const TOKENS = currencies.reduce((acc, item) => acc.set(item.symbol, item), Map());

const txAck = {
  title: "New Order Processing",
  description: `Your transaction is currently being processed. This may take several minutes to complete. You can hide this window and continue using the app. You will receive a confirmation once the transaction is complete.
  Thank you for your patience!`,
};

const estAck = {
  title: "Estimating Transaction Fee",
  description: `Your transaction is currently being prepared. This may take several seconds to complete. You can hide this window and continue using the app. You will receive a transaction estimate to confirm once it is complete.
  Thank you for your patience!`,
};

// type: CLAIM/BUY/SELL/SEND/TRADE
function SwapOrderForm({ onClose, onGetFunds, type, currencyItems, ...initialValues }) {
  const api = useAPI();
  const quotes = useQuotes();
  const [ack, setAck] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [busy, setBusy] = useState(false);
  const [validationSchema, setValidationSchema] = useState();
  const {
    email,
    user,
    refresh,
    vault,
    lock,
    unlock,
    account: vaultAccount,
    loginMethod,
  } = useVault();

  const [changenowEst, setChangenowEst] = useState(null);
  const [estError, setEstError] = useState();
  const [estErrorMessage, setEstErrorMessage] = useState();

  const currenciesByWallet = useMemo(
    () =>
      currencies.filter((currency) => {
        if (currency.symbol === initialValues.sourceCurrency) return false;
        if (!user?.use_smart_wallet) return true;
        if (!currency.native) return true;
        if (initialValues.sourceCurrency === "PPY" && currency.symbol === "POL") return true;
        return false;
      }),
    [user?.use_smart_wallet, initialValues.sourceCurrency]
  );

  const getAmountInUsd = useCallback(
    (amount, currency) => {
      const price = quotes[currency].rate;
      return amount * price;
    },
    [quotes]
  );

  const placeOrder = useCallback(
    async (values, account) => {
      let txLog;
      let tx;
      let txData;
      let errorMsg = "";

      try {
        setBusy(true);
        const provider = new ethers.providers.JsonRpcProvider(
          getNetwork(values.sourceNetwork).rpcUrl
        );
        const gasPrice = await provider.getGasPrice();
        const smartWalletAddress = getSmartWalletAddress(
          CONTRACTS.FreedomFactory.address,
          account.address
        );
        const wallet =
          loginMethod === "magic"
            ? magicProvider.getSigner()
            : new ethers.Wallet(account.privateKey, provider);

        if (type === "CLAIM") {
          // swapLog = await api("swap", { ...values });
        } else {
          let destAddress;
          let destAmount;
          let sourceTransactionHash;
          console.log("values:", JSON.stringify(values));

          if (values.destCurrency === values.sourceCurrency) {
            // send
            if (values.sourceAmount < 8000 && values.destCurrency === "PPY") {
              errorMsg = "Minimum requirement not met, please select at least 8000 PPY to transfer";
            } else {
              destAddress = values.destAddress;
              destAmount = values.sourceAmount;
            }
          } else {
            // trade
            const destCurrencyInfo = TOKENS.get(values.destCurrency);
            const { destAddressIntermediate, receiveAmount, error, message } = await api(
              "swap/reservation",
              {
                ...values,
                email,
                destAddress:
                  user.use_smart_wallet && !destCurrencyInfo.native
                    ? smartWalletAddress
                    : account.address,
              }
            );
            console.log("swapformapi", destAddressIntermediate, receiveAmount, error, message);

            if (error === "out_of_range") {
              errorMsg = message;
            } else {
              destAddress = destAddressIntermediate;
              destAmount = receiveAmount;
            }
          }

          if (destAddress) {
            setAck(txAck);

            const info = TOKENS.get(values.sourceCurrency);
            console.log("info:", JSON.stringify(info));

            // Initiate txn logs
            const amountInUsd = getAmountInUsd(values.sourceAmount, values.sourceCurrency);

            txLog = await api("swap", { ...values, amountInUsd, destAmount });

            if (info.native) {
              // For native tokens (e.g. POL/ETH/BNB)
              const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
                network: {
                  rpcUrl: getNetwork(values.sourceNetwork).rpcUrl,
                  chainId: info.chainId,
                },
              });
              const txProvider =
                loginMethod === "magic"
                  ? new ethers.providers.Web3Provider(magic.rpcProvider)
                  : provider;
              const walletForNative =
                loginMethod === "magic"
                  ? txProvider.getSigner()
                  : new ethers.Wallet(account.privateKey, provider);

              const accountBalance = await txProvider.getBalance(account.address);
              const amount = parseUnits(values.sourceAmount, info.decimals);
              const gasLimit = await txProvider.estimateGas({
                to: destAddress,
                value: amount,
              });
              const bufferMultiplier = info.network === "bsc" ? 10 : 2;
              const bufferGasLimit = gasLimit.mul(bufferMultiplier);
              const totalGasCost = gasPrice.mul(bufferGasLimit);

              if (accountBalance.lte(totalGasCost)) {
                errorMsg = `Minimum requirement not met, you should have more than ${formatFloatByDecimal(
                  Number(formatUnits(totalGasCost, info.decimals)),
                  5
                )} ${values.sourceCurrency}`;
              } else {
                let value = 0;
                if (accountBalance.lt(amount.add(totalGasCost))) {
                  value = accountBalance.sub(totalGasCost);
                } else {
                  value = amount;
                }

                const txprops = {
                  to: destAddress,
                  gasPrice,
                  gasLimit: bufferGasLimit,
                  value,
                };

                tx = await walletForNative.sendTransaction(txprops);
                txData = txprops;
              }
            } else if (user.use_smart_wallet) {
              // For smart wallet
              console.log("else if called", user.use_smart_wallet);
              const code = await provider.getCode(smartWalletAddress);
              console.log("code :", code);
              const smartWallet = new ethers.Contract(
                smartWalletAddress,
                CONTRACTS.FreedomWallet.abi,
                provider
              );
              const amount = parseUnits(values.sourceAmount, info.decimals);
              console.log(amount.toString(), "sendtoken amount");
              const erc20 = new ethers.Contract(info.tokenAddress, IERC20, wallet);
              const byProxy = erc20.interface.encodeFunctionData("transfer", [
                CONTRACTS.FreedomAgentV2.address,
                amount,
              ]);

              const innerTx = {
                token: info.tokenAddress,
                value: 0,
                data: byProxy,
                nonce: code === "0x" ? 0 : await smartWallet.nonce(),
                executor: CONTRACTS.FreedomAgentV2.address,
                gasLimit: 800000,
                gasPrice: Number(formatUnits(gasPrice, "wei")),
                chainId: Number(info.chainId),
              };

              let sig = "";
              if (loginMethod === "magic") {
                sig = await magicSign(CONTRACTS.FreedomFactory, account.address, innerTx);
              } else if (loginMethod === "ves") {
                sig = await sign(
                  CONTRACTS.FreedomFactory,
                  account.address,
                  account.privateKey,
                  info.chainId,
                  innerTx
                );
              }
              console.log("inner_tx", innerTx);

              const {
                signed_tx: signedTx,
                error,
                message,
              } = await api("wallet", {
                owner: account.address,
                instance: INSTANCE_SALT,
                receiver: destAddress,
                exists: code === "0x" ? 0 : 1,
                inner_tx: innerTx,
                v: sig.v,
                r: sig.r,
                s: sig.s,
                ...values,
                decimal: info.decimals,
              });

              if (error) {
                errorMsg = message;
              } else {
                tx = await provider.sendTransaction(signedTx);
                txData = signedTx;
              }
            } else {
              const erc20 = new ethers.Contract(info.tokenAddress, IERC20, wallet);
              const amount = parseUnits(values.sourceAmount, info.decimals);
              const gasLimit = 400000;

              const networkNativeCoin = getNetwork(values.sourceNetwork).token;
              const accountBalance = await provider.getBalance(account.address);
              const totalGasCost = gasPrice.mul(gasLimit);

              if (accountBalance.lt(totalGasCost)) {
                errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
                  Number(formatUnits(totalGasCost, 18)),
                  5
                )} ${networkNativeCoin}`;
              } else {
                tx = await erc20.transfer(destAddress, amount, {
                  gasPrice,
                  gasLimit,
                });
                txData = {
                  to: destAddress,
                  gasPrice,
                  gasLimit,
                  value: values.sourceAmount,
                };
              }
            }

            if (!errorMsg) {
              // Pending txn logs
              api("swap/update", {
                logId: txLog.id,
                logType: "Pending",
                transaction: txData,
              }).catch((e) => {
                console.error("Swap pending log error", e);
              });

              const txstatus = await tx.wait();
              sourceTransactionHash = txstatus.transactionHash;
              console.log(txstatus, "txstatus");

              // Completed txn logs
              api("swap/update", {
                logId: txLog.id,
                logType: "Completed",
                sourceTransactionHash,
              }).catch((e) => {
                console.error("Swap complete log error", e);
              });
            }

            setAck(null);
          }
        }

        if (errorMsg) {
          if (txLog?.id) {
            api("swap/update", {
              logId: txLog.id,
              logType: "Failed",
              error: errorMsg,
            }).catch((e) => {
              console.error("Swap failed log error", e);
            });
          }

          await Swal.fire({
            title: "Something went wrong.",
            text: errorMsg,
            icon: "info",
            confirmButtonText: "OK",
          });
        } else {
          setTimeout(refresh, 2500); // until notifications are ready, to see balance change
          setAck(null);
          await Swal.fire({
            title:
              values.destCurrency === "PPY" && (type === "CLAIM" || type === "BUY")
                ? "Receiving PPY"
                : "Success",
            text:
              values.destCurrency === "PPY" && (type === "CLAIM" || type === "BUY")
                ? "In 5 minutes or less your PPY will arrive.  You may safely continue to use the app."
                : "Your order was accepted and is now processing. You may safely continue to use the app.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        setBusy(false);
        onClose();
      } catch (err) {
        setBusy(false);
        console.log("placeOrder exception", err);

        if (txLog?.id) {
          api("swap/update", {
            logId: txLog.id,
            logType: "Failed",
            error: err,
          }).catch((e) => {
            console.error("Swap failed log error", e);
          });
        }

        await Swal.fire({
          title: "Unsuccessful",
          text: "There was a problem placing your order.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      setAck(null);
    },
    [api, email, onClose, refresh, type, user.use_smart_wallet, loginMethod]
  );

  const handleConfirm = useCallback(
    async (values) => {
      setConfirm(false);
      setAck(txAck);

      if (loginMethod === "magic") {
        await placeOrder(values, vaultAccount);
      } else if (loginMethod === "ves") {
        // unlock the vault, place the order, and relock the vault if previously locked
        unlock(user).then(({ m }) =>
          placeOrder(values, m.derivePath(ethers.utils.defaultPath)).then(() => vault || lock())
        );
      }
    },
    [lock, placeOrder, user, unlock, vault, loginMethod, vaultAccount]
  );

  const handleCancel = () => {
    setConfirm(false);
    onClose();
  };

  useEffect(() => {
    const minimumSourceValue = 1 / 10 ** initialValues.sourceDecimals;
    setValidationSchema(
      Yup.object().shape({
        sourceAmount: Yup.number()
          .required(`Enter the ${initialValues.sourceCurrency} amount to convert.`)
          .max(initialValues.sourceAvailable)
          .min(minimumSourceValue)
          .typeError("Enter a number"),

        destAddress: Yup.string()
          .when("destCurrency", (destCurrency, schema) =>
            destCurrency === initialValues.sourceCurrency ? schema.required() : schema
          )
          .matches(/0x[a-fA-F0-9]{40}/, "must be a valid address starting with 0x"),
      })
    );
  }, [initialValues.sourceDecimals, initialValues.sourceCurrency, initialValues.sourceAvailable]);

  // extra help for two step processes such as buying MUSDC to buy PPY
  const minimumSourceValue = 1 / 10 ** initialValues.sourceDecimals;
  const needsFunds = Number(initialValues.sourceAvailable) < minimumSourceValue;

  const handleClose = () => {
    if (!busy) {
      setAck(null);
      onClose();
    }
  };

  const handleSubmit = async (values) => {
    if (type === "TRADE" || (type === "BUY" && values.destCurrency === "PPY")) {
      setAck(estAck);
      try {
        const response = await api("swap/changenowEstimate", {
          ...values,
          useSmartWallet: user.use_smart_wallet,
        });
        setChangenowEst(response);
        console.log(response);
        setEstError(response.error);
        setEstErrorMessage(response.message);
        if (response.estimatedAmountC > 0) {
          setConfirm(true);
        }
      } catch (e) {
        console.log(e);
        setEstErrorMessage(String(e));
        setEstError(true);
      }
    } else {
      setConfirm(true);
    }

    setAck(null);
  };

  return (
    <SuiBox mt={-2} py={3}>
      <PopupAck {...(ack || {})} open={!!ack} onClose={handleClose} onHide={() => setAck(null)}>
        {ack ? <Loading /> : null}
      </PopupAck>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, isValid, dirty, setFieldValue, setFieldTouched }) => {
          let payCurrencies = [];
          payCurrencies.push(currencyItems.find((item) => item.symbol === values.sourceCurrency));
          if (type === "BUY" && values.destCurrency === "PPY") {
            payCurrencies.push(currencyItems.find((item) => item.symbol === "POL"));
          }
          payCurrencies.push({ symbol: "USD", price: 100, pxdec: 0, decimals: 0 });
          payCurrencies = payCurrencies.filter((item) => !!item);

          let receiveCurrencies = currenciesByWallet
            .map(({ symbol }) => currencyItems.find((item) => item.symbol === symbol))
            .filter((item) => !!item);
          if (type === "BUY" && values.destCurrency === "PPY") {
            receiveCurrencies = [];
            const ppyCurrency = currenciesByWallet.find(
              ({ symbol }) => symbol === "PPY" || symbol === "$FJB"
            );
            if (ppyCurrency) {
              receiveCurrencies.push(ppyCurrency);
            }
          }

          const selectedCurrency = currencyItems.find(
            (item) => item.symbol === values.destCurrency
          );
          const orgCurrency = currencyItems.find((item) => item.symbol === values.sourceCurrency);

          const destAmount = formatUnits(
            parseUnits(
              String(values.sourceAmount || 0),
              selectedCurrency.display + extraPricePrecision
            )
              .mul(orgCurrency.price)
              .mul(parseUnits("1", (selectedCurrency.pxdec || 0) + extraPricePrecision))
              .div(selectedCurrency.price)
              .div(parseUnits("1", (orgCurrency.pxdec || 0) + extraPricePrecision)),
            selectedCurrency.display + extraPricePrecision
          );

          return (
            <Form>
              <Card>
                <SuiBox p={2}>
                  <SwapOrderFormHeader type={type} values={values} />
                  <SuiBox mt={1.625}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <AmountInputField
                          label={
                            type === "SELL"
                              ? "Amount to sell"
                              : `You ${
                                  type === "CLAIM" ? "claim" : type === "SEND" ? "send" : "pay"
                                }`
                          }
                          fields={{ amountField: "sourceAmount", currencyField: "sourceCurrency" }}
                          values={{
                            amount: values.sourceAmount,
                            currency: values.sourceCurrency,
                            decimals: values.sourceDecimals,
                          }}
                          error={errors.sourceAmount}
                          balance={type !== "CLAIM" && values.sourceAvailable}
                          currencies={payCurrencies}
                          isCurrencyFixed
                          disabled={needsFunds || values.locked}
                          onChange={setFieldValue}
                          onTouched={setFieldTouched}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {type === "SEND" ? (
                          <SuiBox mb={1.5}>
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SuiTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                Destination
                              </SuiTypography>
                              <SuiTypography component="div" variant="caption">
                                Specify a <b>{values.destNetwork}</b> blockchain address
                              </SuiTypography>
                            </SuiBox>
                            <SuiBox display="flex" alignItems="center">
                              <SuiBox flexGrow={1}>
                                <Field
                                  inputProps={{ style: { fontSize: 13 } }}
                                  component={FormikTextField}
                                  name="destAddress"
                                  value={values.destAddress}
                                  fullWidth
                                  disabled={values.locked}
                                />
                              </SuiBox>
                            </SuiBox>
                          </SuiBox>
                        ) : (
                          type !== "SELL" && (
                            <AmountInputField
                              label="You receive"
                              fields={{
                                amountField: "destAmount",
                                currencyField: "destCurrency",
                                networkField: "destNetwork",
                              }}
                              values={{
                                amount: destAmount,
                                currency: values.destCurrency,
                                decimals: values.destDecimals,
                              }}
                              error={errors.destCurrency}
                              currencies={receiveCurrencies}
                              isCurrencyFixed={false}
                              disabled
                              onChange={setFieldValue}
                              onTouched={setFieldTouched}
                            />
                          )
                        )}
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <SuiButton
                          type="submit"
                          variant="contained"
                          color={type === "SELL" ? "secondary" : "primary"}
                          fullWidth
                          disabled={!(type === "CLAIM" ? true : dirty) || !isValid || isSubmitting}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {type === "BUY" && values.destCurrency === "PPY" && (
                            <img
                              src={PPYLogo}
                              alt="PPY logo"
                              width="30px"
                              style={{
                                marginTop: "-20px",
                                marginBottom: "7px",
                                border: "1.5px solid #14294f",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                          {type}
                        </SuiButton>
                        {estError && (
                          <SuiTypography
                            mt={2}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="error"
                          >
                            {estErrorMessage}
                          </SuiTypography>
                        )}
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>
              </Card>
              {confirm && (
                <EstimationDialog
                  open={confirm}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  type={type}
                  values={values}
                  estimate={changenowEst}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </SuiBox>
  );
}

export default SwapOrderForm;
