import { Box } from "@mui/material";

import ConversationCard from "./ConversationCard";

export default function Conversations({
  conversations,
  onConversationClick,
  selectedConversation,
}) {
  return (
    <Box sx={{ overflowX: "auto", borderRight: "1px solid", py: 1, width: "240px" }}>
      {conversations.map((conversation) => (
        <ConversationCard
          key={conversation.topic}
          conversation={conversation}
          isSelected={conversation.topic === selectedConversation?.topic}
          onConversationClick={onConversationClick}
        />
      ))}
    </Box>
  );
}
