import { Card, CardContent, Box, CardActions } from "@mui/material";

import Link from "components/Link";
import SuiButton from "components/soft-ui/SuiButton";
import { formatCurrency } from "utils/numeric";
import StatusMarks from "./StatusMarks";

export default function ProductCard({ product, isShowStatus }) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        background: "#E5EAF2",
        p: 1,
        alignItems: "center",
        fontSize: "16px",
        color: "inherit",
        transition: "transform 0.2s linear",

        "&:hover": {
          transform: "translateY(-12px)",
        },
      }}
    >
      <Link to={`/product/${product.id}`}>
        <Box
          sx={{
            display: "flex",
            aspectRatio: "1/1",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={product.attachments[0]?.image_url}
            alt={product.name}
            width="100%"
            height="100%"
            sx={{ objectFit: "cover" }}
          />
        </Box>
      </Link>
      <CardContent
        sx={{ flex: 1, textAlign: "center", width: "100%", p: 2, borderBottom: "1px solid #ccc" }}
      >
        <Box sx={{ fontSize: "20px" }}>{product.name}</Box>
        <Box>{formatCurrency(product.price)}</Box>
        {isShowStatus && <StatusMarks product={product} />}
      </CardContent>
      <CardActions sx={{ my: 1 }}>
        <Link to={`/product/${product.id}`}>
          <SuiButton
            variant="contained"
            color="primary"
            circular
            sx={{ textTransform: "capitalize" }}
          >
            Details
          </SuiButton>
        </Link>
      </CardActions>
    </Card>
  );
}
