const { Map } = require("immutable");

const chains = [
  {
    id: "0x1",
    token: "ETH",
    label: "Ethereum",
    network: "ethereum",
    rpcUrl: "https://rpc.ankr.com/eth",
  },
  {
    id: "0x4",
    token: "rETH",
    label: "Rinkeby",
    network: "rinkeby",
    rpcUrl: "https://rpc.ankr.com/eth/rinkeby",
  },
  {
    id: "0x38",
    token: "BNB",
    label: "Binance",
    network: "bsc",
    rpcUrl: "https://rpc.ankr.com/bsc",
  },
  {
    id: "0x89",
    token: "POL",
    label: "Polygon",
    network: "polygon",
    rpcUrl: "https://rpc.ankr.com/polygon",
  },
  // {
  //   id: "0xa",
  //   token: "ETH",
  //   label: "Optimism",
  //   network: "optimism",
  //   rpcUrl: "https://rpc.ankr.com/optimism",
  // },
  {
    id: "0xfa",
    token: "FTM",
    label: "Fantom",
    network: "fantom",
    rpcUrl: "https://rpc.ftm.tools/",
  },
];

const chainmap = chains.reduce((acc, chain) => acc.set(chain.id, chain), Map());

const getNetwork = (network) => chains.find((chain) => chain.network === network);

module.exports = {
  chains,
  chainmap,
  getNetwork,
};
