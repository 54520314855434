import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, DialogActions } from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import SuiButton from "../soft-ui/SuiButton";

export default function PopupAck({ title, description, children, onClose, onHide, open }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        )}
        <Box mx={12} mt={6} mb={4} display="flex" alignItems="center" justifyContent="center">
          {children}
        </Box>
      </DialogContent>
      {onHide && (
        <DialogActions>
          <SuiButton
            variant="outlined"
            color="primary"
            size="small"
            circular
            startIcon={<VisibilityOff fontSize="medium" />}
            onClick={onHide}
          >
            Hide
          </SuiButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
