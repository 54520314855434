import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useAPI from "utils/api";
import { useSnackbar } from "../../context";

export default function AnnouncementDialog() {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();

  const fetchSettings = useCallback(async () => {
    try {
      const res = await api("settings", { key: "announcement" });
      setMessage(res.value);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch announcement", "error");
    }
  }, [api, showSnackbar]);

  useEffect(() => {
    if (!localStorage.getItem("isShownAnnouncement")) {
      fetchSettings();
    }
  }, [fetchSettings]);

  useEffect(() => {
    if (message) {
      setOpen(true);
      localStorage.setItem("isShownAnnouncement", "true");
    }
  }, [message]);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontWeight: 700 }}>Announcement</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <Typography color="primary">{message}</Typography>
      </DialogContent>
    </Dialog>
  );
}
