import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { ethers } from "ethers";
import Swal from "sweetalert2";

import SuiButton from "components/soft-ui/SuiButton";
import currencies from "config/popular.json";
import useBalances from "utils/balances";
import { useQuotes, useVault, useSnackbar } from "context";
import useAPI from "utils/api";
import {
  INSTANCE_SALT,
  transferTokens,
  transferTokensForSmartWallet,
  getSignForSmartWallet,
  getSmartWalletAddress,
} from "utils/contracts";
import IERC20 from "contracts/IERC20.json";
import { magicProvider } from "config/magic";
import { CONTRACTS } from "context/web3";
import { formatFloatByDecimal } from "utils/numeric";

export default function BuyButton({ product, listingContract, styles, callback }) {
  const navigate = useNavigate();
  const api = useAPI();
  const { balances } = useBalances();
  const { user, account } = useVault();
  const quotes = useQuotes();
  const { showSnackbar } = useSnackbar();
  const [buying, setBuying] = useState(false);

  const tokenPrice = useMemo(() => quotes.PPY.rate, [quotes]);

  const tokenAmount = useMemo(
    () => Math.ceil(product.price / tokenPrice),
    [tokenPrice, product.price]
  );

  const ppyToken = useMemo(() => currencies.find((currency) => currency.symbol === "PPY"), []);

  const tokenBalance = useMemo(() => {
    const balance = balances.get("PPY") ?? [ethers.BigNumber.from(0), ethers.BigNumber.from(0)];
    const ppyBalance = product.is_business
      ? user.use_smart_wallet
        ? balance[1]
        : balance[0]
      : balance[0];

    return formatUnits(ppyBalance, ppyToken.decimals);
  }, [balances, ppyToken.decimals, product.is_business, user.use_smart_wallet]);

  const buyerAddress = useMemo(
    () =>
      user.use_smart_wallet
        ? getSmartWalletAddress(CONTRACTS.FreedomFactory.address, account.address)
        : account.address,
    [user.use_smart_wallet, account.address]
  );

  const handleBuy = async () => {
    let tx;
    let txData;
    let txLog;

    if (Math.floor(tokenBalance) < tokenAmount) {
      await Swal.fire({
        title: `<h6>Insufficient PPY balance, please purchase PPY to buy item</h6>`,
        text: `Balance: ${Number(tokenBalance).toFixed(2)} PPY`,
        icon: "info",
        confirmButtonText: "Purchase PPY",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/assets#!ppy");
        }
      });

      return;
    }

    setBuying(true);

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const basicGasLimit = 400000;
      const gasLimit = product.is_business ? basicGasLimit : basicGasLimit * 2;
      const accountBalance = await magicProvider.getBalance(account.address);
      const gasCost = gasPrice.mul(gasLimit);

      if (!product.is_business || !user.use_smart_wallet) {
        if (accountBalance.lt(gasCost)) {
          const errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
            Number(formatUnits(gasCost, 18)),
            5
          )} POL`;

          await Swal.fire({
            text: errorMsg,
            icon: "info",
            confirmButtonText: "OK",
          });

          setBuying(false);
          return;
        }
      }

      // Initiate txn
      const logData = {
        sourceCurrency: "PPY",
        sourceNetwork: "polygon",
        sourceAmount: tokenAmount,
        sourceAddress: buyerAddress,
        destCurrency: "PPY",
        destNetwork: "polygon",
        destAddress: product.wallet_address,
        amountInUsd: product.price,
        txType: "Purchase",
      };
      txLog = await api("swap", { ...logData });

      if (product.is_business) {
        if (user.use_smart_wallet) {
          const { sign, innerTx, code } = await getSignForSmartWallet(
            account,
            tokenAmount.toString(),
            ppyToken
          );
          const {
            signed_tx: signedTx,
            error,
            message,
          } = await api("wallet", {
            owner: account.address,
            instance: INSTANCE_SALT,
            receiver: product.wallet_address,
            exists: code === "0x" ? 0 : 1,
            inner_tx: innerTx,
            v: sign.v,
            r: sign.r,
            s: sign.s,
            sourceAmount: tokenAmount.toString(),
            sourceCurrency: ppyToken.symbol,
            sourceNetwork: ppyToken.network,
            decimal: ppyToken.decimals,
          });

          if (error) {
            await Swal.fire({
              text: message,
              icon: "info",
              confirmButtonText: "OK",
            });

            setBuying(false);
            return;
          } else {
            tx = await transferTokensForSmartWallet(signedTx);
            txData = signedTx;
          }
        } else {
          tx = await transferTokens(product.wallet_address, tokenAmount.toString(), ppyToken);
          txData = {
            to: product.wallet_address,
            gasPrice,
            gasLimit,
            value: tokenAmount.toString(),
          };
        }
      } else {
        const ppyContract = new ethers.Contract(
          ppyToken.tokenAddress,
          IERC20,
          magicProvider.getSigner()
        );
        const parsedAmount = parseUnits(tokenAmount.toString(), ppyToken.decimals);

        const approveTx = await ppyContract.approve(product.listing_address, parsedAmount, {
          gasPrice,
          gasLimit,
        });
        await approveTx.wait();

        tx = await listingContract.depositFunds(parsedAmount, {
          gasPrice,
          gasLimit,
        });
        txData = {
          to: product.wallet_address,
          gasPrice,
          gasLimit,
          value: tokenAmount.toString(),
        };
      }

      // Pending tx logs
      if (txLog.id) {
        api("swap/update", {
          logId: txLog.id,
          logType: "Pending",
          transaction: txData,
        }).catch((e) => {
          console.error("Swap pending log error", e);
        });
      }

      const txStatus = await tx.wait();

      await api("buy_product", {
        product_id: product.id,
        price: product.price,
        transaction_hash: txStatus.transactionHash,
        is_business: product.is_business,
        swapLogId: txLog.id,
      });

      const message = product.is_business ? "Purchased successfully" : "Escrowed successfully";
      showSnackbar("Success", message, "info");
      callback();
    } catch (err) {
      if (txLog?.id) {
        api("swap/update", {
          logId: txLog.id,
          logType: "Failed",
          error: err,
        }).catch((e) => {
          console.error("Swap failed log error", e);
        });
      }

      const message = product.is_business ? "Failed to purchase" : "Failed to escrow";
      showSnackbar("Error", message, "error");
    }

    setBuying(false);
  };

  const onBuyProduct = async () => {
    await Swal.fire({
      text: `To finalize your $${product.price} purchase, please click 'Confirm'`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleBuy();
      }
    });
  };

  return (
    <SuiButton
      color="primary"
      circular
      sx={{ textTransform: "capitalize", ...styles }}
      disabled={buying}
      onClick={onBuyProduct}
    >
      {buying ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Buy"}
    </SuiButton>
  );
}
