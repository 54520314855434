import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import SuiButton from "components/soft-ui/SuiButton";
import PageWrapper from "components/layout/PageWrapper";
import BackButton from "components/BackButton";
import PageLoading from "components/PageLoading";
import { useVault } from "context/vault";

import useAPI from "utils/api";
import { useSnackbar } from "../../context";
import AddEducationDialog from "./Admin/AddEducationDialog";
import { convertToEmbedUrl } from "utils/format";

export default function EducationDetail() {
  const navigate = useNavigate();
  const api = useAPI();
  const { user } = useVault();
  const { showSnackbar } = useSnackbar();
  const { educationId } = useParams();
  const [education, setEducation] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const fetchEducation = useCallback(async () => {
    setPageLoading(true);

    try {
      const { educations } = await api("educations", { educationId });
      setEducation(educations);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch education", "error");
    }

    setPageLoading(false);
  }, [api, showSnackbar, educationId]);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await api("delete_education", {
        educationId: education.id,
      });

      showSnackbar("Success", "Deleted successfully", "info");
      navigate("/education");
    } catch (err) {
      showSnackbar("Error", "Failed to delete education", "error");
    }

    setDeleting(false);
  };

  const onDeleteEducation = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDelete();
      }
    });
  };

  useEffect(() => {
    fetchEducation();
  }, [fetchEducation]);

  return (
    <PageWrapper>
      {pageLoading && <PageLoading />}
      {education && (
        <>
          <Box display="flex" mb={4}>
            <BackButton to="/education" />
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  aspectRatio: "9/16",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <iframe
                  src={convertToEmbedUrl(education.media_url)}
                  title="Education Video"
                  width="100%"
                  height="100%"
                  frameborder="0"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                rowGap={1}
                direction="column"
                sx={{ border: "1px solid red", p: 2, borderRadius: "5px" }}
              >
                <Grid item>
                  <span>
                    <b>Title: </b>
                  </span>
                  <span>{education.title}</span>
                </Grid>
                {education.description && (
                  <Grid item>
                    <span>
                      <b>Description: </b>
                    </span>
                    <span style={{ whiteSpace: "pre-line" }}>{education.description}</span>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mt: 3 }}>
                {user.is_admin && (
                  <Box sx={{ mt: 1 }}>
                    <SuiButton
                      color="info"
                      circular
                      sx={{ width: "80px", textTransform: "capitalize", mb: 1, mr: 1 }}
                      onClick={() => {
                        setOpenEditDialog(true);
                      }}
                    >
                      Edit
                    </SuiButton>
                    <SuiButton
                      color="error"
                      circular
                      sx={{ width: "80px", textTransform: "capitalize", mb: 1 }}
                      disabled={deleting}
                      onClick={onDeleteEducation}
                    >
                      {deleting ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Delete"}
                    </SuiButton>
                    <AddEducationDialog
                      open={openEditDialog}
                      onClose={() => setOpenEditDialog(false)}
                      educationData={education}
                      callback={fetchEducation}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </PageWrapper>
  );
}
