import LibVES from "libves";

const VESconf = { domain: "fjbizweb" };

if (process.env.REACT_APP_VES_API) VESconf.apiUrl = process.env.REACT_APP_VES_API;
if (process.env.REACT_APP_VES_WWW) VESconf.wwwUrl = process.env.REACT_APP_VES_WWW;

export const VES = new LibVES(VESconf);

export const composeMasterKey = (masterName, email) => ({
  domain: "fjbizweb",
  externalId: `${email.toLowerCase()}!${masterName ?? "master"}`,
});

export const vesGetEmail = async (ves) => {
  const me = await (ves ?? VES).me();
  const email = await me.getEmail();
  return email;
};

export const vesRead = async (masterName) => {
  const email = await vesGetEmail();
  const result = await VES.getValue(composeMasterKey(masterName, email));

  return result;
};
