import React from "react";
import { adjustSymbol } from "../../utils/format";

import SuiBox from "../../components/soft-ui/SuiBox";
import SuiTypography from "../../components/soft-ui/SuiTypography";

export default function SwapOrderFormHeader({ type, values }) {
  const sourceCurrency = adjustSymbol(values.sourceCurrency);
  const destCurrency = adjustSymbol(values.destCurrency);
  const { sourceNetwork } = values;

  return (
    <SuiBox lineHeight={0}>
      <SuiTypography variant="h3" fontWeight="bold">
        {type}
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="bold" color="text">
        {type === "BUY" && `Buy ${destCurrency} using ${sourceCurrency}.`}
        {type === "SELL" && `Sell ${sourceCurrency} for ${destCurrency}.`}
        {type === "SEND" && `Send ${sourceCurrency} to another ${sourceNetwork} address.`}
        {type === "CLAIM" && `Claim ${sourceCurrency} to your wallet.`}
        {type === "TRADE" && `Trade your ${sourceCurrency} for another asset.`}
      </SuiTypography>
    </SuiBox>
  );
}
