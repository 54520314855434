import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import red from "@mui/material/colors/red";

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  countdown: {
    color: red[700],
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function SessionTimeoutDialog({ open, countdown, onLogout, onContinue }) {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }} TransitionComponent={Transition}>
      <DialogTitle>Session Timeout</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{" "}
          <span className={classes.countdown}>{countdown}</span> seconds.
        </Typography>
        <Typography variant="body2">Would you like to continue the session?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onLogout}
          variant="contained"
          className={clsx(classes.logout, classes.button)}
        >
          Logout
        </Button>
        <Button onClick={onContinue} color="primary" variant="contained" className={classes.button}>
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionTimeoutDialog;
