import { useLastMessage } from "@xmtp/react-sdk";
import { Box, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { format } from "date-fns";

import { shortenAddress, truncateText } from "../../utils/format";

export default function ConversationCard({ conversation, onConversationClick, isSelected }) {
  const lastMessage = useLastMessage(conversation.topic);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 1.5,
        py: 1,
        background: isSelected ? "#eee" : "none",
        cursor: "pointer",
        "&:hover": { background: "#ddd" },
      }}
      onClick={() => onConversationClick(conversation)}
    >
      <Avatar>
        <PersonIcon />
      </Avatar>
      <Box sx={{ ml: 1, fontSize: "12px", flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ mr: 2, fontWeight: 700 }}>{shortenAddress(conversation.peerAddress)}</Box>
          <Box>{lastMessage?.sentAt && format(lastMessage.sentAt, "MM/dd/yy")}</Box>
        </Box>
        <Box>{lastMessage?.content && truncateText(lastMessage?.content, 15)}</Box>
      </Box>
    </Box>
  );
}
