import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import { formatUnits } from "ethers/lib/utils";

import useAPI from "../../utils/api";
import { formatCurrency, formatFloatByDecimal } from "../../utils/numeric";
import { useVault, useSnackbar, useWeb3 } from "../../context";
import SuiBox from "../../components/soft-ui/SuiBox";
import SuiButton from "../../components/soft-ui/SuiButton";
import SuiInput from "../../components/soft-ui/SuiInput";
import DataTable from "../../components/soft-ui/SuiDataTable";
import NoData from "../../components/NoData";
import PageLoading from "../../components/PageLoading";
import PopupConfirm from "../../components/dialog/PopupConfirm";
import { isExpiredProduct } from "utils";
import DeleteButton from "views/Marketplace/ProductDetail/DeleteButton";
import AddProductDialog from "views/Marketplace/AddProductDialog";
import { magicProvider } from "config/magic";
import Link from "components/Link";

export default function Products() {
  const api = useAPI();
  const { account } = useVault();
  const { showSnackbar } = useSnackbar();
  const { getContract } = useWeb3();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approving, setApproving] = useState(0);
  const [declining, setDeclining] = useState(0);
  const [declineReason, setDeclineReason] = useState("");
  const [action, setAction] = useState(null);
  const [productId, setProductId] = useState(null);
  const [product, setProduct] = useState(null);
  const [recipient, setRecipient] = useState("buyer");
  const [resolving, setResolving] = useState(0);
  const [productBuyer, setProductBuyer] = useState();
  const [confirming, setConfirming] = useState(0);
  const gasLimit = 800000;

  const filterdProducts = useMemo(() => products.filter((p) => !p.is_business), [products]);

  const listingContract = useMemo(() => {
    if (!productId) return null;

    const prod = filterdProducts.find((p) => p.id === productId);
    return getContract("Listing", prod.listing_address);
  }, [filterdProducts, getContract, productId]);

  const fetchProductBuyer = async () => {
    const buyer = await listingContract.buyer();
    setProductBuyer(buyer);
  };

  const fetchProducts = useCallback(async () => {
    setLoading(true);

    try {
      const res = await api("products");
      setProducts(res);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch products", "error");
    }

    setLoading(false);
  }, [api, showSnackbar]);

  const handleUpdateStatus = async (id, status) => {
    if (status === "Approved") {
      setApproving(id);
    } else {
      setDeclining(id);
    }

    try {
      await api("update_product", {
        productId: id,
        data: { approve_status: status, decline_reason: declineReason },
      });
      await fetchProducts();
      setDeclineReason("");
      showSnackbar("Success", "Updated status successfully", "info");
    } catch (err) {
      showSnackbar("Error", "Failed to update status", "error");
    }

    if (status === "Approved") {
      setApproving(0);
    } else {
      setDeclining(0);
    }
  };

  const onSaveReason = async () => {
    if (!declineReason) return;

    setProductId(null);
    setAction(null);
    await handleUpdateStatus(productId, "Declined");
  };

  const handleResolveDispute = async () => {
    setResolving(productId);

    const product = filterdProducts.find((p) => p.id === productId);
    const state = recipient === "buyer" ? "Listed" : "Sold";
    const receiver = recipient === "buyer" ? productBuyer : product.wallet_address;

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const accountBalance = await magicProvider.getBalance(account.address);
      const gasCost = gasPrice.mul(gasLimit);

      if (accountBalance.lt(gasCost)) {
        const errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
          Number(formatUnits(gasCost, 18)),
          5
        )} POL`;

        await Swal.fire({
          text: errorMsg,
          icon: "info",
          confirmButtonText: "OK",
        });

        setResolving(0);
        return;
      }

      const tx = await listingContract.resolveDispute(receiver, { gasPrice, gasLimit });
      await tx.wait();

      const payload = { state };
      if (recipient === "buyer") {
        payload.tracking_number = null;
      }
      await api("resolve_product_dispute", { productId, data: payload });

      await fetchProducts();
      showSnackbar("Success", "Resolved successfully", "info");
    } catch (err) {
      showSnackbar("Error", "Failed to resolve dispute", "error");
    }

    setResolving(0);
  };

  const onResolveDispute = async () => {
    setProductId(null);
    setAction(null);
    await handleResolveDispute();
  };

  const handleConfirm = async () => {
    setConfirming(productId);

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const accountBalance = await magicProvider.getBalance(account.address);
      const gasCost = gasPrice.mul(gasLimit);

      if (accountBalance.lt(gasCost)) {
        const errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
          Number(formatUnits(gasCost, 18)),
          5
        )} POL`;

        await Swal.fire({
          text: errorMsg,
          icon: "info",
          confirmButtonText: "OK",
        });

        setConfirming(0);
        return;
      }

      const tx = await listingContract.confirmDelivery({ gasPrice, gasLimit: 800000 });
      await tx.wait();

      await api("update_product", { productId, data: { state: "Sold" } });

      await fetchProducts();
      showSnackbar("Success", "Confirmed successfully", "info");
    } catch (err) {
      showSnackbar("Error", "Failed to confirm", "error");
    }

    setConfirming(0);
  };

  const onConfirmDelivery = async () => {
    setProductId(null);
    setAction(null);
    await handleConfirm();
  };

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (!listingContract) return;

    fetchProductBuyer();
  }, [listingContract]);

  return (
    <SuiBox>
      {!!filterdProducts.length && (
        <DataTable
          table={{
            columns: [
              {
                Header: "Images",
                accessor: "attachments",
                width: "10%",
                disableSortBy: true,
                Cell: ({ value }) => (
                  <Grid container direction="column" rowGap={1}>
                    {value.map((attachment) => (
                      <Grid item key={attachment.image_url}>
                        <SuiBox
                          component="img"
                          src={attachment.image_url}
                          alt="image_file"
                          width="100%"
                          sx={{ objectFit: "cover", aspectRatio: "1/1" }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ),
              },
              {
                id: "Name",
                Header: "Name",
                accessor: "id",
                width: "20%",
                Cell: ({ value }) => {
                  const product = filterdProducts.find((p) => p.id === value);
                  return (
                    <Link to={`/product/${product.id}`}>
                      <b>{product.name}</b>
                    </Link>
                  );
                },
              },
              { Header: "Description", accessor: "description" },
              {
                Header: "Price",
                accessor: "price",
                width: "10%",
                Cell: ({ value }) => formatCurrency(value),
              },
              { Header: "Approve Status", accessor: "approve_status", width: "10%" },
              {
                id: "state",
                Header: "State",
                accessor: "id",
                width: "10%",
                Cell: ({ value }) => {
                  const product = filterdProducts.find((p) => p.id === value);
                  return <span>{isExpiredProduct(product) ? "Expired" : product.state}</span>;
                },
              },
              {
                id: "actions",
                Header: "Actions",
                accessor: "id",
                width: "10%",
                disableSortBy: true,
                Cell: ({ value }) => {
                  const product = filterdProducts.find((p) => p.id === value);
                  return (
                    <Grid container direction="column" rowGap={1}>
                      {product.approve_status === "Pending" && (
                        <>
                          <Grid item>
                            <SuiButton
                              color="primary"
                              size="small"
                              circular
                              sx={{ width: "80px", p: 0, textTransform: "capitalize" }}
                              disabled={approving === value}
                              onClick={() => handleUpdateStatus(value, "Approved")}
                            >
                              {approving === value ? (
                                <CircularProgress size={15} sx={{ color: "#fff" }} />
                              ) : (
                                "Approve"
                              )}
                            </SuiButton>
                          </Grid>
                          <Grid item>
                            <SuiButton
                              color="error"
                              size="small"
                              circular
                              sx={{ width: "80px", p: 0, textTransform: "capitalize" }}
                              disabled={declining === value}
                              onClick={() => {
                                setProductId(value);
                                setAction("decline");
                              }}
                            >
                              {declining === value ? (
                                <CircularProgress size={15} sx={{ color: "#fff" }} />
                              ) : (
                                "Decline"
                              )}
                            </SuiButton>
                          </Grid>
                        </>
                      )}
                      {product.state === "Escrowed" && (
                        <Grid item>
                          <SuiButton
                            color="primary"
                            size="small"
                            circular
                            sx={{ width: "80px", textTransform: "capitalize", p: "5px 0" }}
                            disabled={confirming === value}
                            onClick={() => {
                              setProductId(value);
                              setAction("confirm");
                            }}
                          >
                            {confirming === value ? (
                              <CircularProgress size={15} sx={{ color: "#fff" }} />
                            ) : (
                              "Confirm Delivery"
                            )}
                          </SuiButton>
                        </Grid>
                      )}
                      {product.state === "Disputed" && (
                        <Grid item>
                          <SuiButton
                            color="primary"
                            size="small"
                            circular
                            sx={{ width: "80px", textTransform: "capitalize", p: "5px 0" }}
                            disabled={resolving === value}
                            onClick={() => {
                              setProductId(value);
                              setAction("resolve");
                            }}
                          >
                            {resolving === value ? (
                              <CircularProgress size={20} sx={{ color: "#fff" }} />
                            ) : (
                              "Resolve Dispute"
                            )}
                          </SuiButton>
                        </Grid>
                      )}
                      <Grid item>
                        <SuiButton
                          color="info"
                          size="small"
                          circular
                          sx={{ width: "80px", p: 0, textTransform: "capitalize" }}
                          onClick={() => setProduct(product)}
                        >
                          Edit
                        </SuiButton>
                      </Grid>
                      <Grid item>
                        <DeleteButton
                          product={product}
                          size="small"
                          styles={{ width: "80px", p: 0 }}
                          callback={fetchProducts}
                        />
                      </Grid>
                    </Grid>
                  );
                },
              },
            ],
            rows: filterdProducts,
          }}
          entriesPerPage={{ defaultValue: 10, entries: [5, 10, 20] }}
          pagination={{ color: "primary", variant: "contained" }}
        />
      )}
      {loading ? <PageLoading /> : !filterdProducts.length && <NoData />}

      <PopupConfirm
        open={!!productId}
        onConfirm={() => {
          if (action === "decline") {
            onSaveReason();
          } else if (action === "resolve") {
            onResolveDispute();
          } else if (action === "confirm") {
            onConfirmDelivery();
          }
        }}
        onCancel={() => {
          setProductId(null);
          setAction(null);

          if (action === "decline") {
            setDeclineReason("");
          }
        }}
        title={
          action === "decline"
            ? "Reason"
            : action === "resolve"
            ? "Recipient"
            : action === "confirm"
            ? "Confirm Delivery"
            : ""
        }
        color="primary"
      >
        {action === "decline" ? (
          <SuiInput
            placeholder="Type here..."
            value={declineReason}
            multiline
            rows={5}
            error={!declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        ) : action === "resolve" ? (
          <FormControl>
            <RadioGroup value={recipient} onChange={(e) => setRecipient(e.target.value)}>
              <FormControlLabel
                value="buyer"
                control={<Radio />}
                label="Buyer"
                sx={{ m: 0, mb: 0.5 }}
              />
              <FormControlLabel value="seller" control={<Radio />} label="Seller" sx={{ m: 0 }} />
            </RadioGroup>
          </FormControl>
        ) : action === "confirm" ? (
          <SuiBox sx={{ fontSize: "18px" }}>Are you sure? Please click 'Confirm'</SuiBox>
        ) : (
          <></>
        )}
      </PopupConfirm>

      <AddProductDialog
        open={!!product}
        onClose={() => setProduct(null)}
        productData={product}
        callback={fetchProducts}
      />
    </SuiBox>
  );
}
