import { useState } from "react";
import SuiBox from "./soft-ui/SuiBox";
import SuiTypography from "./soft-ui/SuiTypography";
import SuiButton from "./soft-ui/SuiButton";

function AppDownload({ renderFailure, device }) {
  const { href } = window.location;
  const [appOpened, setAppOpened] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const openApp = () => {
    const timeout = setTimeout(() => {
      if (!appOpened) {
        setShowFailure(true);
      }
    }, 2000);

    window.location.href = href.replace(/[?#].*/, "") + "?open=1" + href.match(/(#.*|$)/)[1];

    window.addEventListener("blur", () => {
      setAppOpened(true);
      clearTimeout(timeout);
    });
  };

  return (
    <SuiBox px={3} py={4} bgColor="white" borderRadius="lg" mt={3} textAlign="center">
      <SuiBox marginBottom="8px">
        <SuiTypography
          variant="h4"
          fontWeight="light"
          color="primary"
          textAlign="center"
          fontSize={{ xs: 20, lg: 24 }}
        >
          This link should be opened in the PatriotPay App
        </SuiTypography>
      </SuiBox>
      <SuiBox marginBottom="8px">
        {device === "android" && (
          <SuiButton href={process.env.REACT_APP_DOWNLOAD_ANDROID} color="primary">
            Download from Google App Store
          </SuiButton>
        )}
        {device === "apple" && (
          <SuiButton href={process.env.REACT_APP_DOWNLOAD_APPLE} color="primary">
            Download from Apple App Store
          </SuiButton>
        )}
      </SuiBox>
      <SuiBox marginBottom="8px">
        <SuiTypography variant="body3" fontWeight="regular" color="primary" textAlign="center">
          When the app is installed,
        </SuiTypography>
      </SuiBox>
      <SuiBox marginBottom="8px">
        <SuiButton onClick={openApp} color="primary">
          Open this page in the App
        </SuiButton>
      </SuiBox>
      <SuiBox marginBottom="8px">
        <SuiTypography variant="body3" fontWeight="regular" color="primary" textAlign="center">
          or, scan the QR code again
        </SuiTypography>
      </SuiBox>
      {showFailure && renderFailure()}
    </SuiBox>
  );
}

export default AppDownload;
