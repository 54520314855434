import { useState, useEffect, useCallback } from "react";
import { Box, CircularProgress } from "@mui/material";

import SuiButton from "components/soft-ui/SuiButton";
import SuiInput from "components/soft-ui/SuiInput";
import useAPI from "utils/api";
import PageLoading from "components/PageLoading";
import { useSnackbar } from "../../context";

export default function Announcement() {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState("");

  const fetchSettings = useCallback(async () => {
    setLoading(true);

    try {
      const res = await api("settings", { key: "announcement" });
      setValue(res.value);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch announcement", "error");
    }

    setLoading(false);
  }, [api, showSnackbar]);

  const handleSave = async () => {
    setSaving(true);

    try {
      await api("save_settings", { key: "announcement", value });
      showSnackbar("Success", "Saved successfully", "info");
    } catch (err) {
      showSnackbar("Error", "Failed to save announcement", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <Box>
      {loading && <PageLoading />}
      <SuiInput value={value} onChange={(e) => setValue(e.target.value)} />
      <SuiButton
        variant="contained"
        color="primary"
        circular
        sx={{ width: "80px", textTransform: "capitalize", mt: 2 }}
        disabled={saving}
        onClick={handleSave}
      >
        {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
      </SuiButton>
    </Box>
  );
}
