import { isValidAddress } from "@xmtp/react-sdk";

export const adjustSymbol = (currency) => (currency === "$FJB" ? "PPY" : currency);

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const shortenAddress = (address) => {
  if (isValidAddress(address)) {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }

  return address;
};

export const isSameAddress = (addr1, addr2) => addr1?.toLowerCase() === addr2?.toLowerCase();

const extractVideoId = (url) => {
  const videoId = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/);
  return videoId && videoId[1] ? videoId[1] : null;
};

export const convertToThumbnailUrl = (url) => {
  const videoId = extractVideoId(url);
  return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null;
};

export const convertToEmbedUrl = (url) => {
  const videoId = extractVideoId(url);
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};
