import { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useVault, useSnackbar } from "../../context";
import useAPI from "../../utils/api";

import AddCandidateDialog from "./Admin/AddCandidateDialog";
import PageWrapper from "../../components/layout/PageWrapper";
import SuiTypography from "../../components/soft-ui/SuiTypography";
import SuiButton from "../../components/soft-ui/SuiButton";
import NoData from "../../components/NoData";
import PageLoading from "../../components/PageLoading";
import CandidateCard from "./CandidateCard";

export default function DonationList() {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const { user } = useVault();

  const fetchCandidates = useCallback(async () => {
    setLoading(true);

    try {
      const { candidates: result } = await api("candidates");
      setCandidates(result ?? []);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch candidates", "error");
    }

    setLoading(false);
  }, [api, showSnackbar]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  return (
    <PageWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <SuiTypography sx={{ fontSize: "24px" }}>Donations</SuiTypography>
        {user.is_admin && (
          <SuiButton
            color="primary"
            circular
            sx={{ textTransform: "capitalize" }}
            startIcon={<AddIcon />}
            onClick={() => setOpenAddDialog(true)}
          >
            Add
          </SuiButton>
        )}
      </Box>
      {!!candidates.length && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
            gap: "40px",
          }}
        >
          {candidates.map((candidate) => (
            <CandidateCard key={candidate.id} candidate={candidate} />
          ))}
        </Box>
      )}
      {loading ? <PageLoading /> : !candidates.length && <NoData />}
      <AddCandidateDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        callback={fetchCandidates}
      />
    </PageWrapper>
  );
}
