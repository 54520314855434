import React, { createContext, useState, useContext, useCallback, useMemo } from "react";

const SnackbarContext = createContext();

const initialState = {
  open: false,
  title: "",
  content: "",
  icon: "info",
};

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState(initialState);

  const showSnackbar = useCallback(
    (title, content, icon) => {
      setSnackbar({
        open: true,
        title,
        content,
        icon,
      });
    },
    [setSnackbar]
  );

  const hideSnackbar = useCallback(() => {
    setSnackbar(initialState);
  }, [setSnackbar]);

  const contextValues = useMemo(
    () => ({ snackbar, showSnackbar, hideSnackbar }),
    [snackbar, showSnackbar, hideSnackbar]
  );

  return <SnackbarContext.Provider value={contextValues}>{children}</SnackbarContext.Provider>;
}

export const useSnackbar = () => useContext(SnackbarContext);
