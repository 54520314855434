import React, { useState } from "react";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";

import SuiBox from "components/soft-ui/SuiBox";
import SuiButton from "components/soft-ui/SuiButton";
import CustomTooltip from "components/Tooltip";
import useAPI from "utils/api";
import { useVault } from "context/vault";

import discord from "assets/images/logos/discord.svg";
import telegram from "assets/images/logos/telegram.svg";

export default function Footer() {
  const { user, setSession } = useVault();
  const [switching, setSwitching] = useState(false);
  const api = useAPI();

  const useSmartwallet = user?.use_smart_wallet;

  const handleChange = async () => {
    try {
      setSwitching(true);

      setSession((prev) => ({
        ...(prev || {}),
        user: {
          ...(prev?.user || {}),
          use_smart_wallet: !useSmartwallet,
        },
      }));

      await api("config/use-smart-wallet", { value: !useSmartwallet });
    } catch (err) {
      setSession((prev) => ({
        ...(prev || {}),
        user: {
          ...(prev?.user || {}),
          use_smart_wallet: useSmartwallet,
        },
      }));
      console.log(err, "error from smart wallet");
    } finally {
      setSwitching(false);
    }
  };

  return (
    <SuiBox
      pt={1.75}
      pb={1.75}
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="center"
      alignItems="center"
      bgColor="linear-gradient(to bottom right, #dadde1, #bdbdbd);"
      boxShadow="5px 5px 10px #0A1929, -5px -5px 10px #dadde1"
    >
      <SuiBox
        mt={{ xs: 0.5, md: 0 }}
        mb={{ xs: 1, lg: 0 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <FormGroup>
          <div style={{ flex: 1, flexDirection: "row" }}>
            <label
              style={{
                display: "inline-flex",
                fontSize: "0.875rem",
                fontWeight: 700,
                color: "#344767",
                lineHeight: 1,
                transform: "translateY(0.0625rem)",
                marginLeft: "0.25rem",
                alignItems: "center",
              }}
            >
              Expert Wallet
              <CustomTooltip longText="Operates like a traditional crypto wallet. Users must transfer gas into the wallet to ensure successful transactions." />
            </label>
            <FormControlLabel
              control={<Switch onChange={handleChange} checked={!!useSmartwallet} />}
              style={{
                display: "inline",
                margin: 0,
                padding: 0,
                marginInline: 5,
              }}
              disabled={switching}
            />
            <label
              style={{
                display: "inline-flex",
                fontSize: "0.875rem",
                fontWeight: 700,
                color: "#344767",
                lineHeight: 1,
                transform: "translateY(0.0625rem)",
                marginLeft: "0.25rem",
                alignItems: "center",
              }}
            >
              Smart Wallet
              <CustomTooltip longText=" No need to worry about gas, transactions are handled seamlessly in the background, eliminating the hassle of depositing gas for each transaction." />
            </label>
          </div>
        </FormGroup>
      </SuiBox>

      <SuiBox
        mt={{ xs: 0.5, md: 0 }}
        mb={{ xs: 1, lg: 0 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SuiButton
          variant="text"
          color="dark"
          href="https://discord.gg/5DKBkGY3f2"
          target="_blank"
          sx={{ p: 0, minWidth: 48 }}
        >
          <img src={discord} alt="discord" width="24px" height="24px" color="dark" />
        </SuiButton>
        <SuiButton
          variant="text"
          color="dark"
          href="https://t.me/PatriotPay"
          target="_blank"
          sx={{ p: 0, minWidth: 48 }}
        >
          <img src={telegram} alt="telegram" width="24px" height="24px" color="dark" />
        </SuiButton>
        <SuiBox
          sx={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: 48 }}
          component="a"
          href="mailto:support@patriotpay.org"
        >
          <EmailRoundedIcon color="white" fontSize="medium" />
        </SuiBox>
      </SuiBox>
      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <SuiButton
          variant="text"
          size="small"
          color="dark"
          href="https://patriotpay.org/app-privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </SuiButton>
        <SuiButton
          variant="text"
          size="small"
          color="dark"
          href="https://patriotpay.org/app-disclaimer/"
          target="_blank"
        >
          Disclaimer
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}
