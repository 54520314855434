import React from "react";
import { Field, getIn } from "formik";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";

const InputWrapper = styled(TextField)(({ theme, disabled, error }) => {
  const { palette, boxShadows, functions, typography } = theme;

  const { inputColors, grey, white } = palette;
  const { inputBoxShadow } = boxShadows;
  const { pxToRem, boxShadow } = functions;
  const { size: fontSize } = typography;

  // styles for the focused state of the input
  let focusedBorderColorValue = inputColors.borderColor.focus;

  if (error) {
    focusedBorderColorValue = inputColors.error;
  }

  let focusedBoxShadowValue = boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1);

  if (error) {
    focusedBoxShadowValue = inputBoxShadow.error;
  }

  return {
    "& .MuiInputBase-root": {
      backgroundColor: disabled ? `${grey[200]} !important` : white.main,
      pointerEvents: disabled ? "none" : "auto",
      fontSize: fontSize.xs,
      paddingRight: "8px !important",
      ...(error && {
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E\")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: `right ${pxToRem(12)} center`,
        backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
        borderColor: inputColors.error,
      }),
    },

    "& .Mui-focused": {
      borderColor: focusedBorderColorValue,
      boxShadow: focusedBoxShadowValue,
      outline: 0,
    },
  };
});

const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...rest } = props;

  return <NumberFormat {...rest} getInputRef={ref} isNumericString onValueChange={onChange} />;
});

function NumberComponent(props) {
  const {
    disabled,
    form: { errors, isSubmitting, setFieldValue, setFieldTouched, touched },
    field,
    children,
    value,
    onChange,
    ...rest
  } = props;

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <InputWrapper
      {...rest}
      value={value ?? field.value}
      isNumericString
      error={showError}
      // helperText={showError ? fieldError : props.helperText}
      disabled={disabled || isSubmitting}
      onChange={({ value: newValue }) => {
        if (onChange) {
          onChange(newValue);
        } else {
          setFieldTouched(field.name);
          setFieldValue(field.name, newValue);
        }
      }}
      InputProps={{
        ...(rest?.InputProps || {}),
        inputComponent: NumericFormatCustom,
      }}
    />
  );
}

function NumericField(props) {
  return <Field type="text" component={NumberComponent} {...props} />;
}

export default NumericField;
