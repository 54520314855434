import { Map } from "immutable";
import { useEffect, useReducer, useState } from "react";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { CONTRACTS } from "context/web3";
import popular from "../config/popular.json";
import { chainmap } from "../services";
import IERC20 from "../contracts/IERC20.json";
import { useVault } from "../context/vault";
import { getSmartWalletAddress } from "./contracts";

const TOKENS = popular.reduce((acc, item) => acc.set(item.symbol, item), Map());

const updateBalance = (balances, [symbol, balance]) => {
  const bal = balances.get(symbol);
  if (bal) {
    if (bal[0] === balance[0] && bal[1] === balance[1]) {
      return balances;
    } else {
      return balances.set(symbol, balance);
    }
  } else {
    return balances.set(symbol, balance);
  }
};

// @Find
export const useBalances = (legacy) => {
  const { account } = useVault();
  const [nonce, setNonce] = useState(0);
  const [newBalances, setNewBalances] = useReducer(updateBalance, Map());
  const [balances, setBalances] = useState(Map()); // output, merged with legacy
  const [smartWalletAddress, setSmartWalletAddress] = useState();

  useEffect(() => {
    const timer = setInterval(() => {
      setNonce((prev) => prev + 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  // Initially
  useEffect(() => {
    if (!account) return;
    const deterministicWalletAddress = getSmartWalletAddress(
      CONTRACTS.FreedomFactory.address,
      account.address
    );
    setSmartWalletAddress(deterministicWalletAddress);
    console.log("smart wallet", deterministicWalletAddress);
    popular.forEach((item) => {
      const walletTotal = localStorage.getItem(`${item.chainId}:${account.address}:${item.symbol}`);
      if (walletTotal) {
        let walletBalances;
        try {
          walletBalances = JSON.parse(walletTotal);
        } catch (e) {
          walletBalances = null;
        }
        if (!(walletBalances instanceof Array)) walletBalances = [walletTotal, walletTotal];
        setNewBalances([
          item.symbol,
          [
            ethers.utils.parseUnits(walletBalances[0], item.decimals),
            ethers.utils.parseUnits(walletBalances[1], item.decimals),
          ],
        ]);
      }
    });
  }, [account]);

  useEffect(() => {
    if (account && smartWalletAddress) {
      popular.forEach((item) =>
        (async () => {
          const chain = chainmap.get(ethers.utils.hexStripZeros(Number(item.chainId)));
          if (chain) {
            try {
              let walletTotals;
              if (item.native) {
                const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
                walletTotals = await Promise.all([
                  provider.getBalance(account.address),
                  provider.getBalance(account.address),
                ]);
              } else {
                const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
                const erc20 = new ethers.Contract(item.tokenAddress, IERC20, provider);
                walletTotals = await Promise.all([
                  erc20.balanceOf(account.address),
                  erc20.balanceOf(smartWalletAddress),
                ]);
              }
              // const walletTotal = walletTotals[0].add(walletTotals[1])
              // console.log('bal', item.symbol, walletTotals, ethers.utils.formatUnits(walletTotal, item.decimals))
              setNewBalances([item.symbol, [walletTotals[0], walletTotals[1]]]);
              localStorage.setItem(
                `${item.chainId}:${account.address}:${item.symbol}`,
                JSON.stringify([
                  ethers.utils.formatUnits(walletTotals[0], item.decimals),
                  ethers.utils.formatUnits(walletTotals[1], item.decimals),
                ])
              );
            } catch (err) {
              console.warn(`${chain.rpcUrl} failure`);
            }
          }
        })()
      );
    }
  }, [account, nonce, smartWalletAddress]);

  useEffect(() => {
    // console.log("legacy:",legacy);
    setBalances(
      legacy
        ? Object.keys(legacy).reduce((acc, key) => {
            // console.log("acc:",acc,"key:",key,"legacy[key]:",legacy[key],"TOKENS.get(key).decimals:",TOKENS.get(key).decimals);
            // console.log("parseUnits:",parseUnits(`${legacy[key]}`, TOKENS.get(key).decimals));
            // console.log("add :",acc.get(key) ?? ethers.BigNumber.from(0))
            try {
              return acc.set(key, [
                parseUnits(`${legacy[key]}`, TOKENS.get(key).decimals).add(
                  acc.get(key) ? acc.get(key)[0] : ethers.BigNumber.from(0)
                ),
                parseUnits(`${legacy[key]}`, TOKENS.get(key).decimals).add(
                  acc.get(key) ? acc.get(key)[1] : ethers.BigNumber.from(0)
                ),
              ]);
            } catch (e) {
              console.log(e);
              return acc;
            }
          }, newBalances)
        : newBalances
    );
  }, [legacy, newBalances]);

  return { balances };
};

export default useBalances;
