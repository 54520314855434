import React from "react";
import { Container } from "@mui/material";

import SuiBox from "components/soft-ui/SuiBox";
import Header from "../Header";
import Footer from "../Footer";

export default function PageWrapper({ children, wrapperSx }) {
  return (
    <SuiBox sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header />
      <Container
        sx={{
          flex: 1,
          py: 5,
          ...wrapperSx,
        }}
      >
        {children}
      </Container>
      <Footer />
    </SuiBox>
  );
}
