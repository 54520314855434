import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, CircularProgress } from "@mui/material";
import { formatUnits } from "ethers/lib/utils";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import SumsubWebSdk from "@sumsub/websdk-react";

import SuiButton from "components/soft-ui/SuiButton";
import SuiInput from "components/soft-ui/SuiInput";
import PageWrapper from "components/layout/PageWrapper";
import BackButton from "components/BackButton";
import PageLoading from "components/PageLoading";
import { useQuotes } from "context";
import { useVault } from "context/vault";
import { useBalances } from "utils/balances";
import currencies from "config/popular.json";
import {
  INSTANCE_SALT,
  transferTokens,
  transferTokensForSmartWallet,
  getSignForSmartWallet,
  getSmartWalletAddress,
} from "utils/contracts";
import useAPI from "utils/api";
import SumsubDialog from "components/dialog/SumsubDialog";
import { useSnackbar } from "../../context";
import AddCandidateDialog from "./Admin/AddCandidateDialog";
import { CONTRACTS } from "context/web3";
import { magicProvider } from "config/magic";
import { formatFloatByDecimal } from "utils/numeric";

const DONATION_AMOUNT_25 = 25;
const DONATION_AMOUNT_50 = 50;

export default function CandidateDetail() {
  const navigate = useNavigate();
  const { balances } = useBalances();
  const api = useAPI();
  const { account, user } = useVault();
  const quotes = useQuotes();
  const { showSnackbar } = useSnackbar();
  const { candidateId } = useParams();
  const [candidate, setCandidate] = useState();
  const [isDonated, setIsDonated] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [donateLoading, setDonateLoading] = useState(0);
  const [donateMoreAmount, setDonateMoreAmount] = useState("");
  const [tokenForKyc, setTokenForKyc] = useState();
  const [showKycDialog, setShowKycDialog] = useState(false);
  const [isCompletedKyc, setIsCompletedKyc] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const tokenPrice = useMemo(() => quotes.PPY.rate, [quotes]);

  const calculateDonateAmount = useCallback(
    (amount) => Math.ceil(amount / tokenPrice),
    [tokenPrice]
  );

  const ppyToken = useMemo(() => currencies.find((currency) => currency.symbol === "PPY"), []);

  const tokenBalance = useMemo(() => {
    const balance = balances.get("PPY") ?? [ethers.BigNumber.from(0), ethers.BigNumber.from(0)];

    return formatUnits(user.use_smart_wallet ? balance[1] : balance[0], ppyToken.decimals);
  }, [balances, ppyToken.decimals, user.use_smart_wallet]);

  const donaterAddress = useMemo(
    () =>
      user.use_smart_wallet
        ? getSmartWalletAddress(CONTRACTS.FreedomFactory.address, account.address)
        : account.address,
    [user.use_smart_wallet, account.address]
  );

  const fetchCandidate = useCallback(async () => {
    setPageLoading(true);

    try {
      const result = await api("candidates", { candidateId });
      setCandidate(result.candidates);
      setIsDonated(result.is_donated);
      setIsCompletedKyc(result.is_kyc);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch candidate", "error");
    }

    setPageLoading(false);
  }, [api, showSnackbar, candidateId]);

  const getAccessTokenForKyc = useCallback(async () => {
    try {
      const { token } = await api("kyc-token");
      setTokenForKyc(token);
    } catch (err) {
      showSnackbar("Error", "Failed to get KYC token", "error");
    }
  }, [api, showSnackbar]);

  const errorHandler = (err) => {
    console.log("onError", err);
  };

  const messageHandler = async (type, payload) => {
    if (type !== "idCheck.onApplicantStatusChanged") return;

    if (
      payload.reviewStatus === "completed" &&
      payload.reviewResult.reviewAnswer === "GREEN" &&
      !isCompletedKyc
    ) {
      try {
        await api("users/update", { is_kyc: true });
        setIsCompletedKyc(true);
      } catch (err) {
        showSnackbar("Error", "Failed to update user", "error");
      }
    }
  };

  const onChangeDonateAmount = (e) => {
    setDonateMoreAmount(e.target.value);
  };

  const donate = async (donateAmount) => {
    let tx;
    let txData;
    let txLog;
    const amount = calculateDonateAmount(donateAmount);

    if (Math.floor(tokenBalance) < amount) {
      await Swal.fire({
        title: `<h6>Insufficient PPY balance, please purchase PPY to donate</h6>`,
        text: `Balance: ${Number(tokenBalance).toFixed(2)} PPY`,
        icon: "info",
        confirmButtonText: "Purchase PPY",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/assets#!ppy");
        }
      });

      return;
    }

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const gasLimit = 400000;
      const accountBalance = await magicProvider.getBalance(account.address);
      const gasCost = gasPrice.mul(gasLimit);

      if (!user.use_smart_wallet) {
        if (accountBalance.lt(gasCost)) {
          const errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
            Number(formatUnits(gasCost, 18)),
            5
          )} POL`;

          await Swal.fire({
            text: errorMsg,
            icon: "info",
            confirmButtonText: "OK",
          });

          return;
        }
      }

      // Initiate txn
      const logData = {
        sourceCurrency: "PPY",
        sourceNetwork: "polygon",
        sourceAmount: amount,
        sourceAddress: donaterAddress,
        destCurrency: "PPY",
        destNetwork: "polygon",
        destAddress: candidate.wallet_address,
        amountInUsd: donateAmount,
        txType: "Donate",
      };
      txLog = await api("swap", { ...logData });

      if (user.use_smart_wallet) {
        const { sign, innerTx, code } = await getSignForSmartWallet(
          account,
          amount.toString(),
          ppyToken
        );
        const {
          signed_tx: signedTx,
          error,
          message,
        } = await api("wallet", {
          owner: account.address,
          instance: INSTANCE_SALT,
          receiver: candidate.wallet_address,
          exists: code === "0x" ? 0 : 1,
          inner_tx: innerTx,
          v: sign.v,
          r: sign.r,
          s: sign.s,
          sourceAmount: amount.toString(),
          sourceCurrency: ppyToken.symbol,
          sourceNetwork: ppyToken.network,
          decimal: ppyToken.decimals,
        });

        if (error) {
          await Swal.fire({
            text: message,
            icon: "info",
            confirmButtonText: "OK",
          });

          return;
        } else {
          tx = await transferTokensForSmartWallet(signedTx);
          txData = signedTx;
        }
      } else {
        tx = await transferTokens(candidate.wallet_address, amount.toString(), ppyToken);
        txData = {
          to: candidate.wallet_address,
          value: amount.toString(),
        };
      }

      // Pending tx logs
      if (txLog.id) {
        api("swap/update", {
          logId: txLog.id,
          logType: "Pending",
          transaction: txData,
        }).catch((e) => {
          console.error("Swap pending log error", e);
        });
      }

      const txStatus = await tx.wait();

      // TODO: add txStatus for orders history
      console.log(txStatus);
      await api("donate", {
        candidateId: candidate.id,
        amount: donateAmount,
        transaction_hash: txStatus.transactionHash,
        swapLogId: txLog.id,
      });
      setIsDonated(true);
      setShowKycDialog(false);
      showSnackbar("Success", "Donated successfully", "info");
    } catch (err) {
      if (txLog?.id) {
        api("swap/update", {
          logId: txLog.id,
          logType: "Failed",
          error: err,
        }).catch((e) => {
          console.error("Swap failed log error", e);
        });
      }

      showSnackbar("Error", "Failed to donate", "error");
    }
  };

  const handleDonate = async (amount, isMore) => {
    await Swal.fire({
      text: `To finalize your $${amount} donation, please click 'Confirm'`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isMore) {
          setDonateLoading(100);
        } else {
          setDonateLoading(amount);
        }

        await donate(amount);
      }
    });

    setDonateLoading(0);
  };

  const handleDonateMore = async () => {
    if (!donateMoreAmount || parseInt(donateMoreAmount, 10) <= 0) return;

    await handleDonate(parseInt(donateMoreAmount, 10), true);
  };

  const onDonateMore = () => {
    if (tokenForKyc) {
      setShowKycDialog(true);
    }
  };

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await api("delete_candidate", {
        candidateId: candidate.id,
      });

      showSnackbar("Success", "Deleted successfully", "info");
      navigate("/donations");
    } catch (err) {
      showSnackbar("Error", "Failed to delete candidate", "error");
    }

    setDeleting(false);
  };

  const onDeleteCandidate = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDelete();
      }
    });
  };

  useEffect(() => {
    fetchCandidate();
    getAccessTokenForKyc();
  }, [fetchCandidate, getAccessTokenForKyc]);

  return (
    <PageWrapper>
      {pageLoading && <PageLoading />}
      {candidate && (
        <>
          <Box display="flex" mb={4}>
            <BackButton to="/donations" />
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  src={candidate.photo}
                  alt={candidate.name}
                  width="100%"
                  height="100%"
                  sx={{ objectFit: "cover" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                rowGap={1}
                direction="column"
                sx={{ border: "1px solid red", p: 2, borderRadius: "5px" }}
              >
                <Grid item>
                  <span>
                    <b>Name: </b>
                  </span>
                  <span>{candidate.name}</span>
                </Grid>
                <Grid item>
                  <span>
                    <b>Position: </b>
                  </span>
                  <span>{candidate.position}</span>
                </Grid>
                <Grid item>
                  <span>
                    <b>Website: </b>
                  </span>
                  <span>
                    <a
                      href={candidate.website}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "inherit" }}
                    >
                      {candidate.website}
                    </a>
                  </span>
                </Grid>
                {candidate.description && (
                  <Grid item>
                    <span>
                      <b>Description: </b>
                    </span>
                    <span style={{ whiteSpace: "pre-line" }}>{candidate.description}</span>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mt: 3 }}>
                <Box>
                  <SuiButton
                    variant="contained"
                    color="primary"
                    circular
                    sx={{ width: "140px", textTransform: "capitalize", mr: 1, mb: 1 }}
                    disabled={
                      donateLoading === DONATION_AMOUNT_25 || (!isCompletedKyc && isDonated)
                    }
                    onClick={() => handleDonate(DONATION_AMOUNT_25, false)}
                  >
                    {donateLoading === DONATION_AMOUNT_25 ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : (
                      "Donate $25"
                    )}
                  </SuiButton>
                  <SuiButton
                    variant="contained"
                    color="primary"
                    circular
                    sx={{ width: "140px", textTransform: "capitalize", mr: 1, mb: 1 }}
                    disabled={
                      donateLoading === DONATION_AMOUNT_50 || (!isCompletedKyc && isDonated)
                    }
                    onClick={() => handleDonate(DONATION_AMOUNT_50, false)}
                  >
                    {donateLoading === DONATION_AMOUNT_50 ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : (
                      "Donate $50"
                    )}
                  </SuiButton>
                  <SuiButton
                    variant="contained"
                    color="primary"
                    circular
                    sx={{ width: "140px", textTransform: "capitalize", mb: 1 }}
                    onClick={onDonateMore}
                  >
                    Donate More
                  </SuiButton>
                </Box>
                {!isCompletedKyc && isDonated && (
                  <Box sx={{ mt: 1, fontSize: "14px" }}>
                    You&apos;ve reached the maximum anonymous donation limit for this candidate. To
                    donate more, please select <b>Donate More</b> to complete KYC verification.
                    <br />
                    Thank you for your support!
                  </Box>
                )}
                {user.is_admin && (
                  <Box sx={{ mt: 1 }}>
                    <SuiButton
                      color="info"
                      circular
                      sx={{ width: "80px", textTransform: "capitalize", mb: 1, mr: 1 }}
                      onClick={() => {
                        setOpenEditDialog(true);
                      }}
                    >
                      Edit
                    </SuiButton>
                    <SuiButton
                      color="error"
                      circular
                      sx={{ width: "80px", textTransform: "capitalize", mb: 1 }}
                      disabled={deleting}
                      onClick={onDeleteCandidate}
                    >
                      {deleting ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Delete"}
                    </SuiButton>
                    <AddCandidateDialog
                      open={openEditDialog}
                      onClose={() => setOpenEditDialog(false)}
                      candidateData={candidate}
                      callback={fetchCandidate}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <SumsubDialog open={showKycDialog} onClose={() => setShowKycDialog(false)}>
            {isCompletedKyc ? (
              <Box textAlign="center">
                <SuiInput type="number" value={donateMoreAmount} onChange={onChangeDonateAmount} />
                <SuiButton
                  variant="contained"
                  color="primary"
                  circular
                  disabled={donateLoading === 100}
                  sx={{ width: "100px", textTransform: "none", mt: 3 }}
                  onClick={handleDonateMore}
                >
                  {donateLoading === 100 ? (
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  ) : (
                    "Confirm"
                  )}
                </SuiButton>
              </Box>
            ) : (
              <SumsubWebSdk
                accessToken={tokenForKyc}
                expirationHandler={getAccessTokenForKyc}
                onMessage={messageHandler}
                onError={errorHandler}
              />
            )}
          </SumsubDialog>
        </>
      )}
    </PageWrapper>
  );
}
