import { Card, CardContent, Box, CardActions } from "@mui/material";

import Link from "components/Link";
import SuiButton from "components/soft-ui/SuiButton";
import { convertToThumbnailUrl } from "utils/format";

export default function EducationCard({ education }) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        background: "#E5EAF2",
        p: 1,
        alignItems: "center",
        fontSize: "16px",
        color: "inherit",
        transition: "transform 0.2s linear",

        "&:hover": {
          transform: "translateY(-12px)",
        },
      }}
    >
      <Link to={`/education/${education.id}`}>
        <Box
          sx={{
            display: "flex",
            aspectRatio: "1/1",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <img
            src={convertToThumbnailUrl(education.media_url)}
            alt="Education Video Thumbnail"
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
            }}
          />
        </Box>
      </Link>
      <CardContent
        sx={{ flex: 1, textAlign: "center", width: "100%", p: 2, borderBottom: "1px solid #ccc" }}
      >
        <Box sx={{ fontSize: "20px" }}>{education.title}</Box>
      </CardContent>
      <CardActions sx={{ my: 1 }}>
        <Link to={`/education/${education.id}`}>
          <SuiButton
            variant="contained"
            color="primary"
            circular
            sx={{ textTransform: "capitalize" }}
          >
            Details
          </SuiButton>
        </Link>
      </CardActions>
    </Card>
  );
}
