import { ethers } from "ethers";

import FreedomWallet from "../contracts/FreedomWallet.json";
import { createMagicWithFallback } from "../config/magic";

const INSTANCE_SALT = "0x0000000000000000000000000000000000000000000000000000000000000000";

// EIP712 Precomputed hashes:
// keccak256("EIP712Domain(string name,string version,uint256 chainId,address verifyingContract,bytes32 salt)")
const EIP712_DOMAINTYPE_HASH = "0xd87cd6ef79d4e2b95e15ce8abf732db51ec771f1ca2edccf22a46c729ac56472";

// keccak256("FreedomWallet")
const NAME_HASH = "0xc928069db9738122d42f41bf3d58cdeee8e5fdb61e64ec8b1d15ba776cb0d080";

// keccak256("1")
const VERSION_HASH = "0xc89efdaa54c0f20c7adf612882df0950f5a951637e0307cdcb4c672f298b8bc6";

// keccak256("SignedTransaction(address destination,uint256 value,bytes data,uint256 nonce,address executor,uint256 gasLimit)")
const TXTYPE_HASH = "0xb2743a4bb0cb3cc82bc7804a803be38fdb3587e5fd092ef3d1ee3ea65bd5875f";

const SALT = "0x9de06115391ae9892df837de56cee2a6e966cf778042d209176c84a9d6e0188f";

const getSmartWalletInitCode = (owner) =>
  `0x${FreedomWallet.bytecode}000000000000000000000000${owner.slice(2)}`;

const getSmartWalletAddress = (factory, owner) =>
  `0x${ethers.utils
    .keccak256(
      `0xff${factory.slice(2)}${INSTANCE_SALT.slice(2)}${ethers.utils
        .keccak256(getSmartWalletInitCode(owner))
        .slice(2)}`
    )
    .slice(26)}`;

export const domainSeparator = (factory, ownerAddress, chainId) => {
  const coder = new ethers.utils.AbiCoder();
  return ethers.utils.keccak256(
    coder.encode(
      ["bytes32", "bytes32", "bytes32", "uint256", "address", "bytes32"],
      [
        EIP712_DOMAINTYPE_HASH,
        NAME_HASH,
        VERSION_HASH,
        chainId,
        getSmartWalletAddress(factory.address, ownerAddress),
        SALT,
      ]
    )
  );
};

export const sign = async (factory, ownerAddress, ownerPrivateKey, chainId, tx) => {
  const coder = new ethers.utils.AbiCoder();
  const txInput = coder.encode(
    ["bytes32", "address", "uint256", "bytes32", "uint256", "address", "uint256"],
    [
      TXTYPE_HASH,
      tx.token,
      tx.value,
      ethers.utils.keccak256(tx.data),
      tx.nonce,
      tx.executor,
      tx.gasLimit,
    ]
  );
  const txInputHash = ethers.utils.keccak256(txInput);
  const txDomainSeparator = domainSeparator(factory, ownerAddress, chainId);
  const txInputHashEIP712 = `0x1901${txDomainSeparator.slice(2)}${txInputHash.slice(2)}`;
  const txHashEIP712 = ethers.utils.keccak256(txInputHashEIP712);

  const signingKey = new ethers.utils.SigningKey(ownerPrivateKey);
  return signingKey.signDigest(txHashEIP712);
};

export const magicSign = async (factory, ownerAddress, tx) => {
  const signTypedDataV4Payload = {
    domain: {
      chainId: tx.chainId,
      name: "FreedomWallet",
      verifyingContract: getSmartWalletAddress(factory.address, ownerAddress),
      version: "1",
      salt: SALT,
    },
    message: {
      destination: tx.token,
      value: tx.value,
      data: tx.data,
      nonce: ethers.BigNumber.from(tx.nonce).toNumber(),
      executor: tx.executor,
      gasLimit: tx.gasLimit,
    },
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
        { name: "salt", type: "bytes32" },
      ],
      SignedTransaction: [
        { name: "destination", type: "address" },
        { name: "value", type: "uint256" },
        { name: "data", type: "bytes" },
        { name: "nonce", type: "uint256" },
        { name: "executor", type: "address" },
        { name: "gasLimit", type: "uint256" },
      ],
    },
    primaryType: "SignedTransaction",
  };

  const { magic } = await createMagicWithFallback();
  const signature = await magic.rpcProvider.request({
    method: "eth_signTypedData_v4",
    params: [ownerAddress, signTypedDataV4Payload],
  });
  return ethers.utils.splitSignature(signature);
};
