import { useCallback, useEffect, useState } from "react";
import { useVault } from "context/vault";
import { ethers } from "ethers";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";
import SuiButton from "../components/soft-ui/SuiButton";
import { useBalances } from "../utils/balances";
import { useQuotes } from "../context/quotes";
import popular from "../config/popular.json";

function Recovery() {
  const vault = useVault();
  const [seed, setSeed] = useState();
  const [seedError, setSeedError] = useState();
  const [address, setAddress] = useState();
  const [balance, setBalance] = useState("...");
  const [saving, setSaving] = useState(false);
  const legacyWallet = vault.wallet;
  const { balances } = useBalances(legacyWallet?.availableBalances);
  const quotes = useQuotes();

  const prepSeed = useCallback(() => seed.replace(/\s+/g, " ").replace(/^\s|\s$/, ""), [seed]);

  useEffect(() => {
    const setSeedData = async () => {
      try {
        setSeedError(undefined);
        if (seed) await vault.setSeed(prepSeed());
      } catch (e) {
        console.log(e);
        setSeedError(e);
      }
    };

    setSeedData();
  }, [seed, vault, prepSeed]);

  useEffect(() => {
    setAddress(vault.account?.address);
  }, [vault.account]);

  const saveSeed = async () => {
    try {
      setSaving(true);
      if (vault.isAuthenticated) {
        await vault.saveSeed(prepSeed());
        document.location = "/login";
      } else {
        const s = prepSeed();
        await vault.setSeed(s);
        sessionStorage.setItem("seed", s);

        // eslint-disable-next-line
        const rt = document.location.search.match(/[\?\&]return_url=([^\&]*)/);
        document.location = rt ? decodeURIComponent(rt[1]) : "/login";
      }
    } catch (e) {
      setSaving(false);
      console.log(e);
      setSeedError(e);
    }
  };

  useEffect(() => {
    if (!address) {
      setBalance("...");
      return;
    }

    const b = popular.reduce((acc, item) => {
      try {
        const symbolBalance = balances.get(item.symbol);
        let bal0 = symbolBalance
          ? Number(
              ethers.utils.formatUnits(symbolBalance[0] || ethers.BigNumber.from(0), item.decimals)
            )
          : 0;
        const bal1 = symbolBalance
          ? Number(
              ethers.utils.formatUnits(symbolBalance[1] || ethers.BigNumber.from(0), item.decimals)
            )
          : 0;
        if (bal1 > bal0) bal0 = bal1;
        const price = quotes?.[item.alt]?.rate;
        return price ? acc + price * bal0 : bal0 > 0 ? NaN : acc;
      } catch (e) {
        return acc;
      }
    }, 0);

    setBalance(Number.isNaN(b) ? "$$" : `$${b.toFixed(2)}`);
  }, [balances, quotes, address]);

  return (
    <SuiBox
      margin="auto"
      maxWidth="550px"
      width="100%"
      px={3}
      py={4}
      bgColor="white"
      borderRadius="lg"
      mt={3}
    >
      <SuiTypography
        component="div"
        variant="caption"
        textAlign="center"
        fontSize={{ xs: 16, lg: 20 }}
      >
        Enter the Seed Phrase to recover your wallet
      </SuiTypography>
      <textarea
        style={{ width: "100%" }}
        rows={6}
        value={seed}
        onChange={(e) => {
          setAddress(undefined);
          setSeed(e.target.value);
        }}
      />
      <SuiBox py={4}>
        {seedError ? (
          <SuiTypography component="div" variant="caption" color="error">
            {(seedError && seedError.message) || String(seedError)}
          </SuiTypography>
        ) : address ? (
          <SuiBox>
            <SuiTypography component="div" variant="caption">
              Wallet Address:
            </SuiTypography>
            <SuiTypography component="div" variant="caption">
              {address}
            </SuiTypography>
            <SuiTypography component="div" variant="caption" fontSize={{ xs: 20, lg: 24 }}>
              Balance: {balance}
            </SuiTypography>
            <SuiButton
              color="secondary"
              borderRadius="lg"
              fullWidth
              onClick={saveSeed}
              disabled={saving}
            >
              Continue
            </SuiButton>
          </SuiBox>
        ) : (
          <SuiTypography component="div" variant="caption">
            If you lost your Seed Phrase, you may be able to recover it through your{" "}
            <a href="/login">VES account</a> left menu options
          </SuiTypography>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default Recovery;
