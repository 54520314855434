import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import Loading from "../components/Loading";
import AppDownload from "../components/AppDownload";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";
import SuiButton from "../components/soft-ui/SuiButton";
import SuiInput from "../components/soft-ui/SuiInput";

function Redeem() {
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const { hash } = useLocation();

  const gccode = useMemo(() => {
    // eslint-disable-next-line
    const gc = hash.replace(/^\#/, "").replace("_", "/").replace("-", "+");
    let result = null;
    if (gc.length) {
      try {
        result = ethers.utils.base64.decode(gc);
      } catch (e) {
        console.log(e);
        setError(true);
      }
    }

    return result;
  }, [hash]);

  const device = useMemo(() => {
    const ua = navigator.userAgent;
    let deviceName;

    if (!ua.match(/\bgonative\b/i)) {
      if (ua.match(/\bandroid/i)) {
        if (process.env.REACT_APP_DOWNLOAD_ANDROID) {
          deviceName = "android";
        }
      } else if (ua.match(/\b(iphone|ipad)\b/i)) {
        if (process.env.REACT_APP_DOWNLOAD_APPLE) {
          deviceName = "apple";
        }
      }
    }

    return deviceName;
  }, []);

  const redeemGiftCard = (gc) => {
    console.log(gc);
    const w = new ethers.Wallet(gc);
    sessionStorage.setItem("gc_redeem", w.privateKey);
    window.location = "/home";
  };

  const handleRedeem = () => {
    setError(false);
    try {
      redeemGiftCard(code);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  const renderFailure = () => (
    <SuiBox marginTop="16px" textAlign="left">
      <SuiTypography
        variant="body3"
        fontWeight="regular"
        color="secondary"
        textAlign="left"
        fontSize={{ xs: 10, lg: 12 }}
      >
        The link didn&apos;t open in the PatriotPay app?
        <ul>
          {gccode ? (
            <li>
              Copy the following gift card private key:
              <br />
              {ethers.utils.hexValue(gccode)}
            </li>
          ) : null}
          <li>Open the PatriotPay app</li>
          <li>Access your PatriotPay account, or set up a new one</li>
          <li>Click the Settings button (a cog wheel) at the top bar</li>
          <li>
            Click <q>Redeem a Gift Card</q>
          </li>
          <li>
            Paste or type your gift card private key in the field, and click <q>Redeem</q>
          </li>
        </ul>
      </SuiTypography>
    </SuiBox>
  );

  if (device) {
    return <AppDownload renderFailure={renderFailure} device={device} />;
  }

  if (gccode) {
    redeemGiftCard(gccode);
    return <Loading />;
  }

  return (
    <SuiBox px={3} py={4} bgColor="white" borderRadius="lg" mt={3}>
      <SuiBox marginBottom="8px">
        <SuiTypography
          variant="h4"
          fontWeight="light"
          color="primary"
          textAlign="center"
          fontSize={{ xs: 20, lg: 24 }}
        >
          Redeem Patriot Pay Gift Card
        </SuiTypography>
      </SuiBox>
      <SuiBox component="form" role="form">
        <SuiInput
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={error}
          success={success}
          placeholder="0x0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef"
        />
        <SuiBox marginTop="8px">
          <SuiButton color="secondary" borderRadius="lg" fullWidth onClick={handleRedeem}>
            Redeem
          </SuiButton>
        </SuiBox>
        <SuiBox marginTop="8px">
          <SuiButton color="white" borderRadius="lg" fullWidth href="/">
            Back
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default Redeem;
