import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";
import SuiBox from "./soft-ui/SuiBox";
import SuiTypography from "./soft-ui/SuiTypography";

function AssetCard({ bgColor, title, price, percentage, coinCount, coinValue, image, alttext }) {
  return (
    <Grid container alignItems="center" pr={0}>
      <SuiBox
        bgColor="white"
        position="absolute"
        left="-12px"
        width="42px"
        height="42px"
        borderRadius="42px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={image}
          alt={alttext}
          width="32px"
          height="32px"
          style={
            title.text === "PPY"
              ? { border: "1.5px solid #14294f", borderRadius: "50%" }
              : undefined
          }
        />
      </SuiBox>
      <SuiBox
        position="absolute"
        right={{ xs: 6, lg: 10 }}
        width="18px"
        height="18px"
        bgColor="transparent"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon>more_vert</Icon>
      </SuiBox>
      <Grid item xs={3}>
        <SuiBox lineHeight={1}>
          <SuiTypography
            variant="button"
            color={bgColor === "white" ? "text" : "white"}
            opacity={bgColor === "white" ? 1 : 0.7}
            textTransform="capitalize"
            fontWeight={title.fontWeight}
          >
            {title.text === "WBTC" ? "BTC" : title.text}
          </SuiTypography>
          <SuiTypography
            variant="h5"
            fontWeight="bold"
            color={bgColor === "white" ? "dark" : "white"}
            fontSize={{ xs: 14, lg: 18 }}
            letterSpacing="-0.02em"
          >
            {price}
          </SuiTypography>
          <SuiTypography
            variant="button"
            color={percentage.color}
            fontWeight="bold"
            fontSize={{ xs: 11, lg: 13 }}
            lineHeight={1}
          >
            {percentage.text}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={5}>
        <SuiBox lineHeight={1}>
          <SuiTypography variant="button">Available</SuiTypography>
          <SuiTypography fontSize={{ xs: 14, lg: 16 }} letterSpacing="-0.01em">
            {coinCount}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={4}>
        <SuiBox lineHeight={1}>
          <SuiTypography variant="button">Value</SuiTypography>
          <SuiTypography fontSize={{ xs: 14, lg: 16 }} letterSpacing="-0.01em">
            {coinValue}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of MiniStatisticsCard
AssetCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
};

// Typechecking props for the MiniStatisticsCard
AssetCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  coinCount: PropTypes.string.isRequired,
  coinValue: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alttext: PropTypes.string.isRequired,
};

export default AssetCard;
