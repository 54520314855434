import { useState } from "react";
import { CircularProgress } from "@mui/material";

import SuiButton from "components/soft-ui/SuiButton";
import SuiInput from "components/soft-ui/SuiInput";
import useAPI from "utils/api";
import { useSnackbar } from "context";
import PopupConfirm from "components/dialog/PopupConfirm";

export default function SetTrackingInfo({ product, styles, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState("");

  const handleSaveTracking = async () => {
    setSaving(true);

    try {
      await api("update_product", {
        productId: product.id,
        data: { tracking_number: trackingInfo },
      });

      setTrackingInfo("");
      showSnackbar("Success", "Saved successfully", "info");
      callback();
    } catch (err) {
      showSnackbar("Error", "Failed to save tracking info", "error");
    }

    setSaving(false);
  };

  const onSaveTracking = async () => {
    if (!trackingInfo) return;

    setShowInput(false);
    await handleSaveTracking();
  };

  return (
    <>
      <SuiButton
        color="primary"
        circular
        sx={{ textTransform: "capitalize", ...styles }}
        disabled={saving}
        onClick={() => setShowInput(true)}
      >
        {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Set tracking"}
      </SuiButton>
      <PopupConfirm
        open={showInput}
        onConfirm={() => onSaveTracking(product.id)}
        onCancel={() => {
          setShowInput(false);
          setTrackingInfo("");
        }}
        title="Tracking Info"
        color="primary"
      >
        <SuiInput
          placeholder="Type here..."
          value={trackingInfo}
          error={!trackingInfo}
          onChange={(e) => setTrackingInfo(e.target.value)}
        />
      </PopupConfirm>
    </>
  );
}
