import { format } from "date-fns";
import { ContentTypeId } from "@xmtp/react-sdk";
import { ContentTypeReply } from "@xmtp/content-type-reply";
import { Box } from "@mui/material";

import MessageContent from "./MessageContent";
import ReplyContent from "./ReplyContent";

export default function Message({ message, isIncoming }) {
  const contentType = ContentTypeId.fromString(message.contentType);

  return (
    <Box sx={{ display: "flex", justifyContent: isIncoming ? "flex-start" : "flex-end", my: 1 }}>
      <Box
        sx={{
          maxWidth: "70%",
          display: "inline-flex",
          bgcolor: "#344767",
          color: "#fff",
          py: 1,
          px: 2,
          borderRadius: "16px",
          alignItems: "flex-end",
        }}
      >
        {contentType.sameAs(ContentTypeReply) ? (
          <ReplyContent message={message} />
        ) : (
          <MessageContent message={message} />
        )}
        <Box sx={{ ml: 3, fontSize: "12px", textWrap: "nowrap" }}>
          {format(message.sentAt, "h:mm a")}
        </Box>
      </Box>
    </Box>
  );
}
