import { createContext, useContext, useState, useEffect } from "react";
import { ethers } from "ethers";

import { magicProvider } from "config/magic";
// Ethereum ABIs
import FreedomAgentV1 from "../contracts/FreedomAgentV1.json";
import FreedomAgentV2 from "../contracts/FreedomAgentV2.json";
import FreedomFactory from "../contracts/FreedomFactory.json";
import FreedomWallet from "../contracts/FreedomWallet.json";
import EscrowFactory from "../contracts/EscrowFactory.json";
import Listing from "../contracts/Listing.json";

export const CONTRACTS = {
  FreedomAgentV1: {
    ...FreedomAgentV1,
    address: "0xFBD14c743651719442332Fa589979fA6eb8da174",
  },
  FreedomAgentV2: {
    ...FreedomAgentV2,
    address: "0x128dc5618C56C26e07F590DEa44C43D8D3E21776",
  },
  FreedomFactory: {
    ...FreedomFactory,
    address: "0x10843bC70f2F1aD601cE7Fb41BF838075A5b0CC7",
  },
  FreedomWallet,
  EscrowFactory: {
    ...EscrowFactory,
    address: "0x5270fC4c93e61E160E0045aFd21c05559AA44B70",
  },
  Listing,
};

const ContractsContext = createContext();

export function Web3Provider({ children }) {
  const [escrowFactoryContract, setEscrowFactoryContract] = useState(null);

  const getContract = (name, address) =>
    new ethers.Contract(address, CONTRACTS[name].abi, magicProvider.getSigner());

  const getEscrowFactoryContract = () => {
    const contract = getContract("EscrowFactory", CONTRACTS["EscrowFactory"].address);
    setEscrowFactoryContract(contract);
  };

  useEffect(() => {
    getEscrowFactoryContract();
  }, []);

  return (
    <ContractsContext.Provider value={{ getContract, escrowFactoryContract }}>
      {children}
    </ContractsContext.Provider>
  );
}

export const useWeb3 = () => useContext(ContractsContext);
