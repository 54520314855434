import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { XMTPProvider, replyContentTypeConfig } from "@xmtp/react-sdk";

import App from "./App";

import theme from "./assets/theme";
import {
  VaultProvider,
  SoftUIControllerProvider,
  QuotesProvider,
  SnackbarProvider,
  Web3Provider,
} from "./context";

const contentTypeConfigs = [replyContentTypeConfig];

export default function Root() {
  return (
    <SoftUIControllerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <XMTPProvider contentTypeConfigs={contentTypeConfigs}>
            <SnackbarProvider>
              <VaultProvider>
                <QuotesProvider>
                  <Web3Provider>
                    <App />
                  </Web3Provider>
                </QuotesProvider>
              </VaultProvider>
            </SnackbarProvider>
          </XMTPProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SoftUIControllerProvider>
  );
}
