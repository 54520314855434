import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

export default function CustomTooltip({ longText }) {
  const [open, setOpen] = React.useState(false);

  return (
    // eslint-disable-next-line
    <div
      style={{ display: "inline" }}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <CustomWidthTooltip
        title={longText}
        placement="top"
        open={open}
        onClick={() => setOpen((e) => !e)}
      >
        <IconButton size="large">
          <HelpIcon />
        </IconButton>
      </CustomWidthTooltip>
    </div>
  );
}
