import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { GlobalStyles, Box } from "@mui/material";
import ReactGA from "react-ga4";

import useAPI from "./utils/api";
import { useSnackbar, useVault } from "./context";

import Loading from "./components/Loading";
import SessionTimeout from "./components/SessionTimeout";
import SuiSnackbar from "./components/soft-ui/SuiSnackbar";

import PublicMain from "./views/PublicMain";
import Home from "./views/Home";
import Assets from "./views/Assets";
import Login from "./views/Login";
import Redeem from "./views/Redeem";
import GCGenerate from "./views/GCGenerate";
import Recovery from "./views/Recovery";
import Education from "./views/Education";
import EducationDetail from "./views/Education/EducationDetail";
import Marketplace from "./views/Marketplace";
import Donations from "./views/Donations";
import CandidateDetail from "./views/Donations/CandidateDetail";
import ProductDetail from "./views/Marketplace/ProductDetail";
import Admin from "./views/Admin";
import Report from "./views/Report";

ReactGA.initialize("G-SQMC071W2L");

export default function App() {
  const { isAuthenticated, isLoading, isLost, error, logout, user } = useVault();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const { pathname, search } = useLocation();
  const api = useAPI();

  // Remember ref tags so that if the anon user completes registration and logs in
  // then we can pass the tags to the web service for affiliate tag tracking.
  //
  useEffect(() => {
    const ref = new URLSearchParams(search).get("ref");
    if (ref) {
      // Use local storage to avoid passing query params through authentication redirects.
      // We could call the ref API here but that'd be unreliable since some
      // rogue affiliate could generate fake traffic anyways.
      //
      localStorage.setItem("_ref", ref);
    }
  }, [search]);

  // Grab any ref tags that were set when the user first visited the site.
  //
  useEffect(() => {
    if (isAuthenticated) {
      const ref = localStorage.getItem("_ref");
      if (ref) {
        const tags = ref.split(",");
        api("config/ref", { tags }).then(() => localStorage.removeItem("_ref"));
      }
    }
  }, [api, isAuthenticated]);

  useEffect(() => {
    // Scroll to top when the route changes.
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    ReactGA.send("pageview");
  }, [pathname]);

  useEffect(() => {
    if (error && showSnackbar) {
      showSnackbar("Error", String(error), "error");
    }
  }, [error, showSnackbar]);

  if (isLoading) {
    return (
      <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <GlobalStyles
        styles={{
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "12px"
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: "10px",
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: "#eee",
            borderRadius: "10px",
          },
        }}
      />
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={logout} />
      <SuiSnackbar
        color={snackbar.icon}
        icon={snackbar.icon}
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        dateTime=""
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        close={hideSnackbar}
        onClose={hideSnackbar}
      />

      {isLost ? (
        <Routes>
          <Route exact path="/recovery" element={<Recovery />} />
          <Route path="*" element={<Navigate to="/recovery" />} />
        </Routes>
      ) : isAuthenticated ? (
        <Routes>
          <Route exact path="/home" element={<Home />} />
          {user.is_admin && <Route exact path="/admin" element={<Admin />} />}
          <Route exact path="/assets" element={<Assets />} />
          <Route exact path="/redeem" element={<Redeem />} />
          <Route exact path="/education" element={<Education />} />
          <Route exact path="/education/:educationId" element={<EducationDetail />} />
          <Route exact path="/marketplace" element={<Marketplace />} />
          <Route exact path="/product/:productId" element={<ProductDetail />} />
          <Route exact path="/donations" element={<Donations />} />
          <Route exact path="/donation/:candidateId" element={<CandidateDetail />} />
          <Route exact path="/report" element={<Report />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/" element={<PublicMain />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/redeem" element={<Redeem />} />
          <Route exact path="/giftcard-generate" element={<GCGenerate />} />
          <Route exact path="/recovery" element={<Recovery />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </>
  );
}
