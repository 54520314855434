import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") {
  datadogLogs.init({
    clientToken: "pub319da51f3ca72a87176e30199919931c",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sampleRate: 100,
  });
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
