import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import SuiButton from "../soft-ui/SuiButton";

export default function PopupConfirm({ color, open, onConfirm, onCancel, title, children }) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SuiButton onClick={onConfirm} color={color} circular>
          Confirm
        </SuiButton>
        <SuiButton onClick={onCancel} color={color} circular variant="outlined">
          Cancel
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
