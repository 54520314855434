import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useClient } from "@xmtp/react-sdk";

import SuiButton from "components/soft-ui/SuiButton";
import PageWrapper from "components/layout/PageWrapper";
import BackButton from "components/BackButton";
import PageLoading from "components/PageLoading";
import { useVault } from "context/vault";
import useAPI from "utils/api";
import { formatCurrency } from "utils/numeric";
import { isSameAddress } from "utils/format";
import SuiTypography from "components/soft-ui/SuiTypography";
import MessagesBox from "components/MessagesBox";
import XMTPConnect from "components/XMTPConnect";
import { useSnackbar, useWeb3 } from "../../../context";
import AddProductDialog from "../AddProductDialog";
import StatusMarks from "../StatusMarks";
import DeleteButton from "./DeleteButton";
import CarouselComponent from "components/Carousel";
import SetTrackingInfo from "./SetTrackingInfo";
import DisputeButton from "./DisputeButton";
import BuyButton from "./BuyButton";
import ConfirmDelivery from "./ConfirmDelivery";
import { isExpiredProduct } from "utils";

export default function ProductDetail() {
  const { client } = useClient();
  const navigate = useNavigate();
  const api = useAPI();
  const { user, account } = useVault();
  const { showSnackbar } = useSnackbar();
  const { getContract } = useWeb3();
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [productBuyer, setProductBuyer] = useState();

  const productImages = useMemo(
    () => product?.attachments.map((attachment) => attachment.image_url),
    [product]
  );

  const listingContract = useMemo(() => {
    if (!product || product.is_business) return null;
    return getContract("Listing", product.listing_address);
  }, [getContract, product]);

  const fetchProductBuyer = async () => {
    const buyer = await listingContract.buyer();
    setProductBuyer(buyer);
  };

  const fetchProduct = useCallback(async () => {
    setPageLoading(true);

    try {
      const res = await api("products", { productId });
      setProduct(res);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch product", "error");
    }

    setPageLoading(false);
  }, [api, productId, showSnackbar]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    if (!listingContract) return;

    fetchProductBuyer();
  }, [listingContract]);

  return (
    <PageWrapper>
      {pageLoading && <PageLoading />}
      {product && (
        <>
          <Box display="flex" mb={4}>
            <BackButton to="/marketplace" />
          </Box>
          <SuiTypography variant="h3" mb={1}>
            {product.name}
          </SuiTypography>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  cursor: "pointer",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                onClick={() => setShowImages(true)}
              >
                <Box
                  component="img"
                  src={product.attachments[0]?.image_url}
                  alt={product.name}
                  width="100%"
                  height="100%"
                  sx={{ objectFit: "cover" }}
                />
              </Box>
              <CarouselComponent
                items={productImages}
                open={showImages}
                onClose={() => setShowImages(false)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                rowGap={1}
                direction="column"
                sx={{ border: "1px solid red", p: 2, borderRadius: "5px" }}
              >
                <Grid item>
                  <span>
                    <b>Price: </b>
                  </span>
                  <span>{formatCurrency(product.price)}</span>
                </Grid>
                {product.description && (
                  <Grid item>
                    <span>
                      <b>Description: </b>
                    </span>
                    <span style={{ whiteSpace: "pre-line" }}>{product.description}</span>
                  </Grid>
                )}
                {product.tracking_number &&
                  (isSameAddress(account.address, productBuyer) || user.id === product.user_id) && (
                    <Grid item>
                      <span>
                        <b>Tracking Number: </b>
                      </span>
                      <span>{product.tracking_number}</span>
                    </Grid>
                  )}
              </Grid>
              <Box sx={{ mt: 3 }}>
                {((product.is_business && !user.is_admin) ||
                  (!product.is_business &&
                    user.id !== product.user_id &&
                    product.approve_status === "Approved" &&
                    product.state === "Listed" &&
                    !isExpiredProduct(product))) && (
                  <BuyButton
                    product={product}
                    listingContract={listingContract}
                    styles={{ width: "80px", mb: 1, mr: 1 }}
                    callback={fetchProduct}
                  />
                )}

                {!product.is_business &&
                  user.id === product.user_id &&
                  product.state === "Escrowed" && (
                    <>
                      <SetTrackingInfo
                        product={product}
                        styles={{ width: "140px", mb: 1, mr: 1 }}
                        callback={fetchProduct}
                      />
                    </>
                  )}

                {!product.is_business &&
                  isSameAddress(account.address, productBuyer) &&
                  product.state === "Escrowed" && (
                    <ConfirmDelivery
                      product={product}
                      listingContract={listingContract}
                      styles={{ width: "160px", mb: 1, mr: 1 }}
                      callback={fetchProduct}
                    />
                  )}

                {!product.is_business &&
                  (isSameAddress(account.address, productBuyer) || user.id === product.user_id) &&
                  product.state === "Escrowed" && (
                    <DisputeButton
                      product={product}
                      listingContract={listingContract}
                      styles={{ width: "100px", mb: 1, mr: 1 }}
                      callback={fetchProduct}
                    />
                  )}

                {(user.is_admin || user.id === product.user_id) && (
                  <>
                    <SuiButton
                      color="info"
                      circular
                      sx={{ width: "80px", textTransform: "capitalize", mb: 1, mr: 1 }}
                      onClick={() => setOpenEditDialog(true)}
                    >
                      Edit
                    </SuiButton>
                    <DeleteButton
                      product={product}
                      styles={{ width: "100px", mb: 1 }}
                      callback={() => navigate("/marketplace")}
                    />
                    <AddProductDialog
                      open={openEditDialog}
                      onClose={() => setOpenEditDialog(false)}
                      productData={product}
                      callback={fetchProduct}
                    />
                  </>
                )}
              </Box>

              {!product.is_business && <StatusMarks product={product} isDetail />}
            </Grid>
          </Grid>
          {client ? (
            <MessagesBox productOwner={product.wallet_address} productId={product.id} />
          ) : (
            <XMTPConnect />
          )}
        </>
      )}
    </PageWrapper>
  );
}
