import React, { useMemo } from "react";
import styled from "@emotion/styled";

import SuiBox from "../soft-ui/SuiBox";
import SuiTypography from "../soft-ui/SuiTypography";
import PopupConfirm from "./PopupConfirm";
import { formatDecimal } from "../../utils/numeric";
import { useQuotes } from "context";

const OrderRow = styled(SuiBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(100vw - 100px)",
  },

  "& > *:first-child": {
    minWidth: 200,
  },
}));

function EstimationDialog({ open, onCancel, onConfirm, type, values, estimate }) {
  const quotes = useQuotes();
  const { estimateFeeC, estimatedAmountC, destCurrencyPrice } = estimate || {};

  const estimateFee = estimateFeeC ?? "0.0";
  const estimatedAmount = estimatedAmountC ?? values.sourceAmount - estimateFee;

  const actualPrice = useMemo(
    () => quotes[values.destCurrency.toUpperCase()].rate,
    [quotes, values.destCurrency]
  );

  const price = useMemo(() => {
    if (destCurrencyPrice) {
      return formatDecimal(destCurrencyPrice);
    }
    return "";
  }, [destCurrencyPrice]);

  const slippage = useMemo(() => {
    if (destCurrencyPrice && actualPrice) {
      const diff = (Math.abs(destCurrencyPrice - actualPrice) / destCurrencyPrice) * 100;
      return formatDecimal(diff);
    }
    return "";
  }, [destCurrencyPrice, actualPrice]);

  return (
    <PopupConfirm
      title="Send Order"
      color={type === "SELL" ? "secondary" : "primary"}
      open={open}
      onConfirm={() => onConfirm(values)}
      onCancel={onCancel}
    >
      <SuiBox display="flex" flexDirection="column" gap={2}>
        <OrderRow>
          <SuiTypography variant="caption">Sending</SuiTypography>
          <SuiTypography>
            {formatDecimal(values.sourceAmount)} {values.sourceCurrency}
          </SuiTypography>
        </OrderRow>
        <OrderRow>
          <SuiBox sx={{ minWidth: 200, flex: 1 }}>
            <SuiTypography variant="caption">Receive</SuiTypography>
          </SuiBox>
          <SuiTypography>
            {formatDecimal(estimatedAmount)} {values.destCurrency}
          </SuiTypography>
        </OrderRow>
        <OrderRow>
          <SuiTypography variant="caption">Current Price</SuiTypography>
          <SuiTypography>{!price ? "*" : `${price} USD`}</SuiTypography>
        </OrderRow>
        <OrderRow>
          <SuiTypography variant="caption">Slippage</SuiTypography>
          <SuiTypography>{!slippage ? "*" : `~ ${slippage}%`}</SuiTypography>
        </OrderRow>
        {estimateFeeC ? (
          <OrderRow>
            <SuiTypography variant="caption">Fees</SuiTypography>
            <SuiTypography>~ {formatDecimal(estimateFee)} USD</SuiTypography>
          </OrderRow>
        ) : null}
      </SuiBox>
    </PopupConfirm>
  );
}

export default EstimationDialog;
