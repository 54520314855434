import { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";

import { useVault } from "../context/vault";
import SuiBox from "./soft-ui/SuiBox";
import SuiTypography from "./soft-ui/SuiTypography";
import { useQuotes, useSnackbar } from "../context";
import popular from "../config/popular.json";
import { chainmap, getNetwork } from "../services";
import IERC20 from "../contracts/IERC20.json";
import useAPI from "../utils/api";

const stLoading = "Retrieving information...";
const stProcessing = "Redeeming the gift card...";
const stInvalid = "Invalid gift card code or already redeemed.";
const stComplete = "The gift card is redeemed.";
const stError = "Redemption failed. Please contact support@patriotpay.org for assistance.";
const stUnlock = "Access the wallet to redeem.";

const balHidden = "(hidden)";
const gcColor = "#00001f";

export default function GiftInfo() {
  const { account, email } = useVault();
  const [value, setValue] = useState("...");
  const [status, setStatus] = useState(null);
  const quotes = useQuotes();
  const { showSnackbar } = useSnackbar();
  const [gift, setGift] = useState(null);
  const api = useAPI();

  const removeGift = () => {
    sessionStorage.removeItem("gc_redeem");
  };

  const getGiftBalances = async () => {
    const balances = {};

    const balancePromises = popular.map(async (item) => {
      const chain = chainmap.get(ethers.utils.hexStripZeros(Number(item.chainId)));
      const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);

      let balance;
      if (!item.native) {
        const erc20 = new ethers.Contract(item.tokenAddress, IERC20, provider);
        balance = await erc20.balanceOf(gift.address);
      }

      balances[item.symbol] = balance;
    });

    await Promise.all(balancePromises);
    return balances;
  };

  const getGiftValues = async () => {
    const balances = await getGiftBalances();
    console.log("GiftBalances", balances);

    return popular.reduce((acc, item) => {
      const balance = Number(
        ethers.utils.formatUnits(balances[item.symbol] || ethers.BigNumber.from(0), item.decimals)
      );
      const price = quotes?.[item.alt]?.rate;
      return price ? acc + price * balance : balance ? NaN : acc;
    }, 0);
  };

  const getAmountInUsd = useCallback(
    (amount, currency) => {
      const price = quotes[currency].rate;
      return amount * price;
    },
    [quotes]
  );

  const placeOrder = async (values, giftAccount) => {
    let swapLog;

    try {
      const provider = new ethers.providers.JsonRpcProvider(
        getNetwork(values.sourceNetwork).rpcUrl
      );
      const gasPrice = await provider.getGasPrice();
      const wallet = new ethers.Wallet(giftAccount.privateKey, provider);
      const info = popular.find((item) => item.symbol === values.sourceCurrency);

      let destAddress;
      let destAmount;
      const { destAddressIntermediate, receiveAmount, error, message } = await api(
        "swap/reservation",
        {
          ...values,
          email,
        }
      );
      console.log("reservation: ", destAddressIntermediate, receiveAmount, error, message);

      if (error === "out_of_range") {
        showSnackbar("Error", message, "error");
        throw Error(error);
      } else {
        destAddress = destAddressIntermediate;
        destAmount = receiveAmount;
      }

      if (destAddress) {
        let tx;
        let sourceTransactionHash;

        // Initiate txn
        const amountInUsd = getAmountInUsd(values.sourceAmount, values.sourceCurrency);
        swapLog = await api("swap", { ...values, amountInUsd, destAmount });

        const gasLimit = 200000;
        const amount = parseUnits(values.sourceAmount, info.decimals);

        if (info.native) {
          // const txprops = {
          //   to: values.destAddress,
          //   value: amount,
          //   gasPrice,
          //   gasLimit: 60000,
          // };
          // tx = await wallet.sendTransaction(txprops);
          // sourceTransactionHash = (await tx.wait()).transactionHash;
          return;
        } else {
          const contract = new ethers.Contract(info.tokenAddress, IERC20, wallet);
          tx = await contract.transfer(destAddress, amount, {
            gasPrice,
            gasLimit,
          });
        }

        // Pending txn
        api("swap/update", {
          logId: swapLog.id,
          logType: "Pending",
          transaction: {
            to: destAddress,
            gasPrice,
            gasLimit,
            value: values.sourceAmount,
          },
        }).catch((e) => {
          console.error("Swap pending log error", e);
        });

        const txstatus = await tx.wait();
        sourceTransactionHash = txstatus.transactionHash;
        console.log("tx hash", sourceTransactionHash);

        // when txn completed
        api("swap/update", {
          logId: swapLog.id,
          logType: "Completed",
          sourceTransactionHash,
        }).catch((e) => {
          console.error("Swap complete log error", e);
        });

        showSnackbar("Success", `Redeemed ${values.sourceCurrency} successfully`, "info");
      }
    } catch (err) {
      if (swapLog?.id) {
        api("swap/update", {
          logId: swapLog.id,
          logType: "Failed",
          error: err,
        }).catch((e) => {
          console.error("Swap failed log error", e);
        });
      }

      if (err.message !== "out_of_range") {
        showSnackbar("Error", `Failed to redeem ${values.sourceCurrency}`, "error");
      }

      return Promise.reject(err);
    }
  };

  useEffect(() => {
    const processGifts = async () => {
      try {
        setStatus(stLoading);

        const val = await getGiftValues();
        console.log("GiftValue", val);
        setValue(Number.isNaN(val) ? balHidden : `$${val.toFixed(2)}`);

        if (Number.isNaN(val) || val > 0) {
          if (!account) {
            setStatus(stUnlock);
            return;
          }
          setStatus(stProcessing);

          const balanceList = await getGiftBalances();
          const destTokenInfo = popular.find((item) => item.symbol === "PPY");

          await Promise.all(
            popular.map(async (item) => {
              const balance = balanceList[item.symbol];
              if (balance && balance.gt(0)) {
                await placeOrder(
                  {
                    sourceNetwork: item.network,
                    sourceCurrency: item.symbol,
                    sourceAddress: gift.address,
                    sourceAmount: ethers.utils.formatUnits(balance, item.decimals),
                    destCurrency: destTokenInfo.symbol,
                    destNetwork: destTokenInfo.network,
                    destAddress: account.address,
                    txType: "GC Redeem",
                  },
                  gift
                );
              }
            })
          );

          setStatus(stComplete);
        } else {
          setStatus(stInvalid);
        }
      } catch (e) {
        console.log("redeem error", e);
        setStatus(stError);
      } finally {
        removeGift();
      }
    };

    if (gift) {
      processGifts();
    }
  }, [gift, account]);

  useEffect(() => {
    const storedRedeemValue = sessionStorage.getItem("gc_redeem");
    if (storedRedeemValue) {
      setGift(new ethers.Wallet(storedRedeemValue));
    } else {
      setGift(null);
    }
  }, []);

  if (!gift) return null;

  return (
    <SuiBox
      p={3}
      mb={{ xs: 2, lg: 3 }}
      bgColor="linear-gradient(to bottom right, #92bb27, #d8ff1d, #92bb27);"
      borderRadius="lg"
      boxShadow="4px 4px 8px #050d15, -4px -4x 8px #0c2135"
    >
      <SuiBox>
        <SuiTypography
          variant="h4"
          fontWeight="light"
          color={gcColor}
          textAlign="center"
          fontSize={{ xs: 20, lg: 24 }}
        >
          Gift Card Balance
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <SuiTypography
          variant="h1"
          fontSize={{ xs: 44, lg: 60 }}
          fontWeight="light"
          color={gcColor}
          textAlign="center"
        >
          {value}
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <SuiTypography
          variant="h5"
          fontWeight="light"
          color={gcColor}
          textAlign="center"
          fontSize={{ xs: 10, lg: 12 }}
        >
          {status}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}
