import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import SuiBox from "../../soft-ui/SuiBox";
import SuiTypography from "../../soft-ui/SuiTypography";

import { useSoftUIController, setTransparentNavbar, useVault, useQuotes } from "../../../context";
import { formatPrice, parseQuote } from "../../../utils/numeric";

import SettingsDialog from "../../dialog/SettingsDialog";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton } from "./styles";

import brand from "../../../assets/images/logos/ppy-eagle.svg";
import popular from "../../../config/popular.json";
import SuiBadge from "components/soft-ui/SuiBadge";

function DashboardNavbar({ absolute, light, isMini, hideZeroBalance }) {
  const {
    email,
    badge,
    isAuthenticated,
    loginWithVes,
    logout,
    lock,
    unlock,
    user,
    vault,
    loginMethod,
  } = useVault();
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [openSettings, setOpenSettings] = useState(false);
  const quotes = useQuotes();
  const [quote, setQuote] = useState("");
  const [hideZeroBal, setHideZeroBal] = useState(false);

  useEffect(() => {
    const raw = quotes.PPY?.rate;
    if (raw) {
      try {
        const PPY = popular.filter((x) => x.alt === "PPY")[0];
        setQuote(formatPrice(PPY, parseQuote(PPY, raw)));
      } catch (err) {
        setQuote("");
      }
    }
  }, [quotes]);

  useEffect(() => {
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleSettingsToggle = () => setOpenSettings(!openSettings);

  useEffect(() => {
    (async () => {
      const d = await localStorage.getItem("hide_zero_bal");
      if (d) {
        const fd = JSON.parse(d);
        setHideZeroBal(fd.show);
        hideZeroBalance(fd.show);
      }
    })();
  }, [hideZeroBalance]);

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <SettingsDialog open={openSettings} onClose={handleSettingsToggle} />
      <Toolbar disableGutters sx={(theme) => navbarContainer(theme)}>
        <SuiBox mt={1} color="inherit" mb={1} sx={(theme) => navbarRow(theme, { isMini })}>
          <SuiBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <SuiBox
              component={NavLink}
              to="/"
              display="flex"
              alignItems="center"
              width="100%"
              maxWidth="250px"
            >
              <SuiBox component="img" src={brand} alt="PatriotPay Logo" width="50%" />
            </SuiBox>
            <SuiBox color="#15294E" width="138px" ml={4}>
              <SuiTypography
                color="black"
                fontSize={16}
                lineHeight={1}
                fontWeight="medium"
                textAlign="center"
                mb={0.5}
              >
                PPY Live Price
              </SuiTypography>
              <SuiTypography
                color="#15294E"
                lineHeight={1}
                fontSize={27}
                fontWeight="extra"
                textAlign="center"
              >
                {quote || ""}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          {/* <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon> */}
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SuiBox color="#15294E" fontSize={14} width="100%">
              {!isAuthenticated && (
                <IconButton sx={navbarIconButton} size="small" onClick={loginWithVes}>
                  <Icon
                    sx={({ palette: { lightblue } }) => ({
                      color: light ? lightblue.main : lightblue.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <SuiTypography variant="button" fontWeight="medium" color="#15294E">
                    Sign in
                  </SuiTypography>
                </IconButton>
              )}
              {isAuthenticated && (
                <SuiBox
                  sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                  <SuiTypography color="#15294E" fontWeight="medium">
                    {email}
                  </SuiTypography>
                  {!user.is_admin && (
                    <SuiBadge badgeContent={badge} color="dark" size="md" container />
                  )}
                </SuiBox>
              )}
              {isAuthenticated && (
                <IconButton sx={navbarIconButton} size="small" onClick={logout}>
                  <Icon
                    sx={({ palette: { lightblue } }) => ({
                      color: light ? lightblue.main : lightblue.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <SuiTypography variant="button" fontWeight="medium" color="#15294E">
                    Sign out
                  </SuiTypography>
                </IconButton>
              )}
              {/* <IconButton
                display="none"
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton> */}
              <IconButton
                display="none"
                size="small"
                color="#15294E"
                sx={navbarIconButton}
                onClick={handleSettingsToggle}
              >
                <Icon>settings</Icon>
              </IconButton>
              {loginMethod === "ves" && (
                <Button
                  onClick={() => (vault ? lock() : unlock(user))}
                  style={{ color: "#15294E" }}
                >
                  {vault ? "LOCK" : "UNLOCK"}
                </Button>
              )}
            </SuiBox>
          </SuiBox>
        )}
        <SuiBox display="flex" alignItems="center" justifyContent="end" width="100%">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setHideZeroBal(e.target.checked);
                    hideZeroBalance(e.target.checked);
                    localStorage.setItem(
                      "hide_zero_bal",
                      JSON.stringify({ show: e.target.checked })
                    );
                  }}
                  checked={hideZeroBal}
                />
              }
              label="Hide Zero Balance Coins"
            />
          </FormGroup>
        </SuiBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
