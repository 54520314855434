import React, { useEffect, useRef, useState, useCallback } from "react";
import { createChart } from "lightweight-charts";

import useAPI from "../utils/api";
import useInterval from "../utils/interval";
import SuiBox from "./soft-ui/SuiBox";
import { useSnackbar } from "context";

export default function LightweightChart({ asset, height = 220 }) {
  const chartContainerRef = useRef();
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState();

  // eslint-disable-next-line
  useEffect(() => {
    if (data) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height,
        layout: {
          backgroundColor: "transparent",
          textColor: "#15294E",
        },
        grid: {
          vertLines: {
            color: "rgba(250, 250, 250, 0.4)",
          },
          horzLines: {
            color: "rgba(250, 250, 250, 0.4)",
          },
        },
        localization: {
          priceFormatter: (price) => `${price.toFixed(5)}`,
        },
      });
      chart.timeScale().fitContent();

      const newSeries = chart.addAreaSeries({
        topColor: "#0F52BA",
        bottomColor: "#0E1F33",
        lineColor: "#0E1F33",
      });
      newSeries.setData(data);

      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        chart.timeScale().fitContent();
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);

        chart.remove();
      };
    }
  }, [data, height]);

  const cb = useCallback(async () => {
    if (asset === "USD") {
      setData([]);
    } else {
      const end = 1 + Math.floor(new Date().getTime() / 86400000);
      const start = end - 365;

      try {
        const res = await api("history", {
          code: asset,
          start: start * 86400,
          end: end * 86400,
          currency: "USD",
        });
        setData(
          res.map((x) => ({
            time: new Date(x.date).toISOString().split("T")[0],
            value: x.rate,
          }))
        );
      } catch (err) {
        showSnackbar("Error", "Failed to chart data", "error");
      }
    }
  }, [api, asset, showSnackbar]);

  // Refetch price chart every 10 mins
  useInterval(cb, 600000);

  useEffect(() => {
    cb();
  }, [cb]);

  return (
    <SuiBox
      bgColor="white"
      border="0px solid rgba(0, 0, 0, 0.125)"
      borderRadius="lg"
      boxShadow="rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem"
      width="100%"
      p={{ xs: 2, lg: 3 }}
      mb={{ xs: 2, lg: 0 }}
    >
      <div ref={chartContainerRef} />
    </SuiBox>
  );
}
