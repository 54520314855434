import React, { useState, useCallback, useMemo } from "react";
import { useClient, useConversations, useStreamConversations } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

import Loading from "components/Loading";
import { isSameAddress } from "../../utils/format";
import Messages from "./Messages";
import NewMessage from "./NewMessage";
import Conversations from "./Conversations";

export default function MessagesBox({ productOwner, productId }) {
  const { client } = useClient();
  const { conversations, isLoading } = useConversations();
  useStreamConversations();
  const [selectedConversation, setSelectedConversation] = useState();

  const conversationId = useMemo(() => `product_${productId}`, [productId]);

  const conversationsByProduct = useMemo(
    () => conversations.filter((c) => c.context?.conversationId === conversationId),
    [conversations, conversationId]
  );

  const handleConversationClick = useCallback((convo) => {
    setSelectedConversation(convo);
  }, []);

  const handleStartNewConversationSuccess = useCallback((convo) => {
    setSelectedConversation(convo);
  }, []);

  return (
    <Box sx={{ mt: 5, fontSize: "14px" }}>
      {isLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <Loading />
        </Box>
      ) : conversationsByProduct.length ? (
        <Box
          sx={{
            display: "flex",
            border: "1px solid",
            borderRadius: "10px",
            height: "500px",
          }}
        >
          <Conversations
            conversations={conversationsByProduct}
            onConversationClick={handleConversationClick}
            selectedConversation={selectedConversation}
          />
          {selectedConversation && <Messages conversation={selectedConversation} />}
        </Box>
      ) : (
        !isSameAddress(client.address, productOwner) && (
          <NewMessage
            toAddress={productOwner}
            conversationId={conversationId}
            onSuccess={handleStartNewConversationSuccess}
          />
        )
      )}
    </Box>
  );
}
