import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { formatUnits } from "ethers/lib/utils";

import SuiButton from "components/soft-ui/SuiButton";
import { useVault, useSnackbar } from "context";
import useAPI from "utils/api";
import { magicProvider } from "config/magic";
import { formatFloatByDecimal } from "utils/numeric";

export default function DisputeButton({ product, listingContract, styles, callback }) {
  const api = useAPI();
  const { account } = useVault();
  const { showSnackbar } = useSnackbar();
  const [disputing, setDisputing] = useState(false);

  const handleDispute = async () => {
    setDisputing(true);

    try {
      const gasPrice = await magicProvider.getGasPrice();
      const gasLimit = 800000;
      const accountBalance = await magicProvider.getBalance(account.address);
      const gasCost = gasPrice.mul(gasLimit);

      if (accountBalance.lt(gasCost)) {
        const errorMsg = `Minimum requirement not met, you should have at least ${formatFloatByDecimal(
          Number(formatUnits(gasCost, 18)),
          5
        )} POL`;

        await Swal.fire({
          text: errorMsg,
          icon: "info",
          confirmButtonText: "OK",
        });

        setDisputing(false);
        return;
      }

      const tx = await listingContract.raiseDispute({ gasPrice, gasLimit });
      await tx.wait();

      await api("update_product", { productId: product.id, data: { state: "Disputed" } });

      showSnackbar("Success", "Disputed successfully", "info");
      callback();
    } catch (err) {
      showSnackbar("Error", "Failed to dispute", "error");
    }

    setDisputing(false);
  };

  const onDisputeProduct = async () => {
    await Swal.fire({
      text: `Are you sure? Please click 'Confirm'`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDispute();
      }
    });
  };

  return (
    <>
      <SuiButton
        color="error"
        circular
        sx={{ textTransform: "capitalize", ...styles }}
        disabled={disputing}
        onClick={onDisputeProduct}
      >
        {disputing ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Dispute"}
      </SuiButton>
    </>
  );
}
