import React, { useCallback, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import { ErrorMessage, Form, Formik } from "formik";
import { Card, Grid, Icon, InputAdornment } from "@mui/material";
import * as Yup from "yup";
import SuiButton from "./soft-ui/SuiButton";
import SuiTypography from "./soft-ui/SuiTypography";
import SuiBox from "./soft-ui/SuiBox";
import NumericField from "./formik/NumericField";
import useAPI from "../utils/api";
import { useVault } from "../context/vault";

const networkName = (network) => {
  if (network === "matic") {
    return "Polygon";
  } else {
    return network[0].toUpperCase() + network.slice(1);
  }
};

const antiNetworkName = (network) => {
  if (network !== "ethereum") {
    return ` Ethereum or`;
  }
  return "";
};

const nounPhrase = (name, network) => {
  if (name === "BTC") {
    return networkName(network);
  } else {
    return `${name} on ${networkName(network)} network`;
  }
};

function AmountToReceive({ sourceNetwork, sourceCurrency, asset, address, onError, onSubmit }) {
  const api = useAPI();
  const { email } = useVault();

  const handleSubmit = async (values) => {
    const { destAddress, destAddressIntermediate } = await api("swap/reservation", {
      sourceNetwork,
      sourceCurrency,
      sourceAmount: values.amountToReceive,
      destNetwork: asset.network,
      destCurrency: asset.alt,
      destAddress: address,
      email,
    });
    if (destAddress === address) {
      onSubmit({ ...values, intermediateAddress: destAddressIntermediate });
    } else {
      console.error(
        `Exchange reservation has unexpected destination address ${destAddress} when ${address} was expected.`
      );
      onError();
    }
  };

  return (
    <SuiBox mt={1} py={2}>
      <Formik
        initialValues={{}}
        validationSchema={Yup.object().shape({
          amountToReceive: Yup.number()
            .required("Enter the amount to receive.")
            .min(0.000105)
            .typeError("Enter a number"),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <Card>
              <SuiBox px={2}>
                <SuiBox lineHeight={0}>
                  <SuiTypography variant="h5" fontWeight="bold">
                    Amount to receive
                  </SuiTypography>
                  <SuiTypography variant="caption">
                    {`Enter the amount of ${nounPhrase(sourceCurrency, sourceNetwork)} to receive.`}
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={1.625}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SuiBox mb={1.5}>
                        <SuiBox display="flex" alignItems="center">
                          <SuiBox flexGrow={1} mr={2}>
                            <NumericField
                              autoFocus
                              fullWidth
                              name="amountToReceive"
                              decimalScale={values.sourceDecimals}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {values.sourceCurrency}
                                  </InputAdornment>
                                ),
                              }}
                              disabled={isSubmitting}
                            />
                          </SuiBox>
                        </SuiBox>
                        <SuiBox mt={0.75} height={4}>
                          <SuiTypography component="div" variant="caption" color="error">
                            <ErrorMessage name="sourceAmount" />
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <SuiButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={!isValid || isSubmitting}
                    >
                      Show My Address
                    </SuiButton>
                  </Grid>
                </SuiBox>
              </SuiBox>
            </Card>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
}

function WalletAddress({ network, symbol, address, amountToSend, children }) {
  const [isCopied, setCopied] = useState(false);

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 666);
  };

  return (
    <SuiBox mt={2} mx={2} mb={2}>
      <SuiTypography variant="h5">{networkName(network)} Address</SuiTypography>
      {amountToSend ? (
        <SuiTypography variant="caption">
          Send {amountToSend} {symbol} to this address.
        </SuiTypography>
      ) : null}
      <CopyToClipboard onCopy={handleCopied} text={address} style={{ cursor: "pointer" }}>
        <SuiBox>
          <SuiTypography variant="caption" color={isCopied ? "success" : "primary"}>
            {address} {isCopied ? <Icon>check_circle</Icon> : <Icon>copy</Icon>}
          </SuiTypography>
          <SuiBox display="flex" alignItems="center" justifyContent="center" mt={2} py={2}>
            <QRCode value={address} size={240} color={isCopied ? "success" : "primary"} />
          </SuiBox>
        </SuiBox>
      </CopyToClipboard>
      {children}
    </SuiBox>
  );
}

function Receive({ asset, address }) {
  const [native, setNative] = useState(!asset.origins);
  const [displayedAddress, setDisplayedAddress] = useState(address);
  const [amountToReceive, setAmountToReceive] = useState();

  useEffect(() => {
    if (native) {
      setDisplayedAddress(address);
    } else {
      setDisplayedAddress(undefined);
    }
  }, [native, address]);

  const handleOther = useCallback(() => setNative(!native), [native]);

  const [network, symbol] = native ? [asset.network, asset.symbol] : asset.origins[0].split(":");
  const [netOther, symOther] = asset.origins
    ? native
      ? asset.origins[0].split(":")
      : [asset.network, asset.symbol]
    : [undefined, undefined];

  const help = `This address is for receiving ${nounPhrase(
    symbol,
    network
  )}.  Sending from${antiNetworkName(network, symbol)} any other network may lose funds.`;

  const handleReservation = ({ amountToReceive: amount, intermediateAddress }) => {
    setAmountToReceive(amount);
    setDisplayedAddress(intermediateAddress);
  };

  const handleReservationError = () => {};

  return (
    <SuiBox mt={1}>
      <Card>
        <SuiBox py={1} display="flex" flexDirection="column" alignContent="center">
          <SuiBox mt={1} mx={2} mb={1}>
            <SuiTypography variant="h5">Receive {nounPhrase(symbol, network)}</SuiTypography>
            <SuiTypography variant="caption">{help}</SuiTypography>
          </SuiBox>
          {asset.origins ? (
            <>
              <SuiBox mx={2}>
                <SuiButton color="primary" variant="outlined" onClick={handleOther}>
                  Receive {symOther === "BTC" ? "" : `${symOther} on`} {netOther} instead
                </SuiButton>
              </SuiBox>
              {displayedAddress ? (
                <WalletAddress
                  key={origin}
                  symbol={symbol}
                  network={network}
                  address={displayedAddress}
                  amountToSend={amountToReceive}
                />
              ) : (
                <AmountToReceive
                  sourceNetwork={network}
                  sourceCurrency={symbol}
                  asset={asset}
                  address={address}
                  onSubmit={handleReservation}
                  onError={handleReservationError}
                />
              )}
            </>
          ) : (
            <WalletAddress name={symbol} network={network} address={displayedAddress} />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Receive;
