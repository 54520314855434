import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { memo } from "react";

const NoDataContainer = styled.div(() => ({
  borderRadius: 10,
  textAlign: "center",
  padding: "36px 0",
  background: "#f3f3f3",
}));

export default memo(({ text }) => (
  <NoDataContainer>
    <Typography>{text || "No data found"}</Typography>
  </NoDataContainer>
));
