import React, { useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import styled from "@emotion/styled";
import { InputAdornment } from "@mui/material";
import { formatUnits, parseUnits } from "ethers/lib/utils";

import NumericField from "../../components/formik/NumericField";
import SuiBox from "../../components/soft-ui/SuiBox";
import SuiTypography from "../../components/soft-ui/SuiTypography";
import CurrenciesDropdown from "./CurrenciesDropdown";
import { extraPricePrecision, formatValueInUSD } from "../../utils/numeric";

const InputAdornmentWrapper = styled(InputAdornment)(() => ({
  cursor: "pointer",
  pointerEvents: "all",
}));

export default function AmountInputField({
  label,
  fields,
  values,
  error,
  balance,
  currencies,
  isCurrencyFixed,
  disabled,
  onChange,
  onTouched,
}) {
  const [amount, setAmount] = useState("");
  const [changedAmount, setChangedAmount] = useState("");
  const [currency, setCurrency] = useState(values.currency);

  const orgCurrency = currencies?.find((item) => item.symbol === values.currency);
  const selectedCurrency = currencies?.find((item) => item.symbol === currency);

  useEffect(() => {
    if (!isCurrencyFixed) {
      setAmount(values.amount);
    }
  }, [isCurrencyFixed, values.amount]);

  const getUSDAmount = useCallback(
    (rawAmount) => {
      if (selectedCurrency?.symbol === "USD" || disabled || Number.isNaN(rawAmount)) {
        return "";
      }

      return formatValueInUSD(
        parseUnits(String(rawAmount || 0), selectedCurrency.decimals),
        selectedCurrency.price,
        selectedCurrency.pxdec,
        selectedCurrency.decimals
      );
    },
    [disabled, selectedCurrency]
  );

  const getOrgAmount = useCallback(
    (usdAmount) => {
      if (orgCurrency && selectedCurrency?.symbol === orgCurrency?.symbol) {
        return usdAmount;
      }

      return formatUnits(
        parseUnits(String(usdAmount || 0), extraPricePrecision)
          .mul(selectedCurrency.price)
          .mul(parseUnits("1", (orgCurrency.pxdec || 0) + extraPricePrecision))
          .div(orgCurrency.price)
          .div(parseUnits("1", (selectedCurrency.pxdec || 0) + extraPricePrecision)),
        extraPricePrecision
      );
    },
    [selectedCurrency, orgCurrency]
  );

  const handleChangeAmount = (value) => {
    if (isCurrencyFixed && orgCurrency && orgCurrency.symbol !== selectedCurrency.symbol) {
      setAmount(value);
      const orgAmount = getOrgAmount(value);
      onChange(fields.amountField, orgAmount);
      setChangedAmount(`${orgAmount} ${orgCurrency.symbol}`);
    } else {
      setAmount(value);
      onChange(fields.amountField, value);
      const usdAmount = getUSDAmount(value);

      if (isCurrencyFixed) {
        setChangedAmount(`${usdAmount} USD`);
      }
    }
  };

  const handleMaxAmount = () => {
    setCurrency(orgCurrency.symbol);
    setAmount(balance);
    onChange(fields.amountField, balance);
    onTouched(fields.amountField);
  };

  const handleSelectCurrency = (item, network) => {
    setCurrency(item);
    if (isCurrencyFixed) {
      setAmount("");
    } else {
      onChange(fields.currencyField, item);
      onChange(fields.networkField, network);
    }
  };

  return (
    <SuiBox width="100%">
      <SuiBox
        mb={0.5}
        px={0.5}
        lineHeight={0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
        {balance && (
          <SuiTypography
            component="div"
            variant="caption"
            textTransform="capitalize"
            sx={{ cursor: "pointer", textDecoration: "underline !important" }}
            onClick={handleMaxAmount}
          >
            MAX: {balance} {values.currency}
          </SuiTypography>
        )}
      </SuiBox>
      <SuiBox>
        <NumericField
          autoFocus
          fullWidth
          name={fields.amountField}
          decimalScale={values.decimals}
          InputProps={{
            endAdornment: currencies ? (
              <InputAdornmentWrapper position="end">
                <CurrenciesDropdown
                  currencies={currencies}
                  onSelect={handleSelectCurrency}
                  value={currency}
                />
              </InputAdornmentWrapper>
            ) : null,
          }}
          disabled={disabled}
          value={amount}
          onChange={handleChangeAmount}
        />
      </SuiBox>
      <SuiBox mt={0.75} height={4}>
        {error ? (
          <SuiTypography component="div" variant="caption" color="error">
            <ErrorMessage name={fields.amountField} />
          </SuiTypography>
        ) : changedAmount ? (
          <SuiTypography component="div" variant="caption">
            ≈ {changedAmount}
          </SuiTypography>
        ) : null}
      </SuiBox>
    </SuiBox>
  );
}
