import { createContext, useCallback, useContext, useEffect, useState } from "react";
import libVES from "libves";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useClient } from "@xmtp/react-sdk";

import { createMagicWithFallback } from "../config/magic";
import { VES, vesGetEmail, composeMasterKey } from "../config/ves";

const derive = ethers.utils.HDNode.fromMnemonic;

const VaultContext = createContext();

const generateMnemonic = () => {
  const entropy = ethers.utils.randomBytes(32);
  return ethers.utils.entropyToMnemonic(entropy);
};

export function VaultProvider({ children }) {
  const { disconnect: xmtpDisconnect } = useClient();
  const [vault, setVault] = useState();
  const [account, setAccount] = useState();
  const [session, setSession] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLost, setIsLost] = useState();
  const [error, setError] = useState();
  const [currentSeed, setCurrentSeed] = useState();
  const [newSeed, setNewSeed] = useState();
  const [loginMethod, setLoginMethod] = useState();
  const [magic, setMagic] = useState(null);
  const navigate = useNavigate();

  useEffect(
    () =>
      (async () => {
        try {
          setIsLoading(true);

          const { magic: magicInstance } = await createMagicWithFallback();
          setMagic(magicInstance);

          const apiTokenCurrent = sessionStorage.getItem("apiToken");

          if (apiTokenCurrent) {
            const isMagicLoggedIn = await magicInstance.user.isLoggedIn();

            // TODO: get rid of loginMethod
            // const loginMethod = isMagicLoggedIn ? "magic" : "ves";
            // console.log("login method", loginMethod);
            const sdkName = "magic";

            try {
              const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/users/get`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${apiTokenCurrent}`,
                  "Content-Type": "application/json",
                },
              });
              const result = await response.json();
              const recoveredAddress = sessionStorage.getItem("address");
              console.log("api login success on load", recoveredAddress);
              if (!result?.apiToken) {
                throw new Error("api token does not exist");
              }

              setSession(result);
              setAccount({ address: recoveredAddress });
              setLoginMethod(sdkName);
            } catch (err) {
              console.log("api login failure on load", err);
              sessionStorage.removeItem("apiToken");
              sessionStorage.removeItem("address");
              setSession(undefined);
              setAccount(undefined);
              setLoginMethod(undefined);

              if (isMagicLoggedIn) {
                await magicInstance.user.logout();
              }
            }
          }
        } catch (e) {
          console.log("initial loading user failed", e);
        } finally {
          setIsLoading(false);
        }
      })(),
    []
  );

  const loginWithMagic = useCallback(
    async ({ email, username, type }) => {
      console.log("magic login", email, username);
      setIsLoading(true);

      try {
        let magicNew = magic;
        if (!magic) {
          magicNew = (await createMagicWithFallback())?.magic;
          setMagic(magicNew);
        }

        let didToken = "";
        if (email) {
          await magicNew.auth.loginWithEmailOTP({ email, showUI: true });
        } else if (username) {
          if (type === "register") {
            didToken = await magicNew.webauthn.registerNewUser({ username });
          } else if (type === "login") {
            didToken = await magicNew.webauthn.login({ username });
          }
        }

        const userInfo = await magicNew.user.getInfo();

        if (!didToken) {
          didToken = await magicNew.user.getIdToken();
        }

        const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/magic-login`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${didToken}`,
            "Content-Type": "application/json",
          },
        });

        const userSession = await response.json();
        sessionStorage.setItem("apiToken", userSession.apiToken);
        sessionStorage.setItem("address", userInfo.publicAddress);
        setAccount({ address: userInfo.publicAddress });
        setSession(userSession);
        setLoginMethod("magic");
        setError(undefined);
        setIsLoading(false);
      } catch (err) {
        console.error("magic login error", err);
        sessionStorage.removeItem("apiToken");
        sessionStorage.removeItem("address");
        setAccount(undefined);
        setSession(undefined);
        setLoginMethod(undefined);
        setError(err.rawMessage ?? err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [magic]
  );

  const loginWithVes = useCallback(
    async (dest) => {
      console.log("vault login");
      setIsLoading(true);
      let sess;
      try {
        const ves = await VES.flow(window.document.location.hash.length === 0, {
          rd: "none",
        });
        console.log("vault ves", ves);
        const auth = new libVES.Auth({ VES: ves });
        const vesToken = await auth.getToken();
        console.log("vault token", vesToken);

        const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/login`, {
          method: "POST",
          // mode: 'no-cors',
          headers: {
            Authorization: `Bearer ${vesToken}`,
            "Content-Type": "application/json",
          },
        });
        console.log("vault session");

        sess = await response.json();
        const { user } = sess;

        const email = await vesGetEmail(ves);
        const masterKey = composeMasterKey(user.masterName, email);
        const exists = await VES.fileExists(masterKey);
        const svSeed = sessionStorage.getItem("seed");
        if (svSeed) sessionStorage.removeItem("seed");
        let mnemonic;
        if (exists) {
          try {
            mnemonic = await VES.getValue(masterKey);
            console.log("vault using existing mnemonic");
          } catch (er) {
            if (er instanceof libVES.Error.InvalidKey) {
              console.log("vault lost");
              if (svSeed) {
                await VES.putValue(masterKey, (mnemonic = svSeed));
              } else {
                setIsLost(true);
                throw er;
              }
            } else {
              setIsLost(false);
              throw er;
            }
          }
        } else {
          mnemonic = svSeed ?? generateMnemonic();
          await VES.putValue(masterKey, mnemonic);
          if (svSeed) {
            console.log("vault using recovered mnemonic");
          } else {
            setNewSeed(mnemonic);
            console.log("vault using new mnemonic");
          }
          setIsLost(false);
        }
        setCurrentSeed(mnemonic);
        const m = derive(mnemonic);
        const { address } = m.derivePath(ethers.utils.defaultPath);

        console.log("vault ves login success", address);
        sessionStorage.setItem("apiToken", sess.apiToken);
        sessionStorage.setItem("address", address);
        setAccount({ address });
        setSession(sess);
        setLoginMethod("ves");
        setError(undefined);
        setIsLoading(false);

        if (dest) {
          console.log("vault navigate");
          navigate(dest);
        }
      } catch (err) {
        if (err.code !== "Redirect") {
          console.warn("vault ves login failure", err);
          sessionStorage.removeItem("apiToken");
          sessionStorage.removeItem("address");
          setAccount(undefined);
          setLoginMethod(undefined);
          setError(err);
          setSession(sess);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [navigate]
  ); // I do not think this will change except for on navigation to a new view
  // so this should not cause a new login callback to be returned causing
  // client components to re-render.

  const logout = useCallback(async () => {
    console.log("vault logout");

    if (loginMethod === "magic") {
      try {
        await magic?.user.logout();
      } catch (e) {
        console.log("magic logout failed", e);
      }
    }

    await xmtpDisconnect();
    sessionStorage.removeItem("apiToken");
    sessionStorage.removeItem("address");
    localStorage.removeItem("isShownAnnouncement");
    setSession(undefined);
    setAccount(undefined);
    setLoginMethod(undefined);

    navigate("/");
  }, [navigate, loginMethod, magic?.user, xmtpDisconnect]);

  const refresh = useCallback(
    async (next) => {
      console.log("vault refresh");
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/api/users/get`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.apiToken}`,
          },
        });
        const result = await response.json();
        console.log("vault refresh session ok");
        setSession(result);
        sessionStorage.setItem("apiToken", result.apiToken);
        next?.();
      } catch (reason) {
        console.log("vault refresh failed", reason);
      }
    },
    [session]
  );

  const lock = () =>
    VES.lock().then(() => {
      setVault();
      console.log("vault locked");
      return true;
    });

  const use = async (masterName) => {
    try {
      const email = await vesGetEmail();
      const masterKey = composeMasterKey(masterName, email);
      const exists = await VES.fileExists(masterKey);
      if (exists) {
        const mnemonic = await VES.getValue(masterKey);
        console.log("using existing mnemonic from Vault");
        const m = derive(mnemonic);
        const { address } = m.derivePath(ethers.utils.defaultPath);
        setAccount({ address });
        const vaultData = { address, m };
        setVault(vaultData);
        return vaultData;
      } else {
        const mnemonic = generateMnemonic();
        await VES.putValue(masterKey, mnemonic);
        console.log("using new mnemonic");
        const m = derive(mnemonic);
        const { address } = m.derivePath(ethers.utils.defaultPath);
        setAccount({ address });
        const vaultData = { address, m };
        setVault(vaultData);
        return vaultData;
      }
    } catch (er) {
      console.log("vault use error", er);
      setError(er);
    }
    return "";
  };

  const unlock = useCallback(async () => {
    if (vault) {
      return vault;
    }
    const { user, email: sessionEmail } = session;
    const ves = await VES.delegate({ email: sessionEmail, rd: "none" });
    // since it is possible for user to switch accounts, but this would invalidate the session
    const email = await vesGetEmail(ves);
    // TODO: create new session?  Otherwise client will be out of sync with its apiToken's user ID (authmap.id)
    const masterKey = composeMasterKey(user.masterName, email);
    try {
      const mnemonic = await ves.getValue(masterKey);
      const m = derive(mnemonic);
      const { address } = m.derivePath(ethers.utils.defaultPath);
      setAccount({ address });
      const vaultData = { address, m };
      setVault(vaultData);
      return vaultData;
    } catch (er) {
      if (er.code === "NotFound") {
        const mnemonic = generateMnemonic();
        try {
          await ves.putValue(masterKey, mnemonic);
          console.log("vault unlocked");
          console.log("using new mnemonic");
          const m = derive(mnemonic);
          const { address } = m.derivePath(ethers.utils.defaultPath);
          setAccount({ address });
          const vaultData = { address, m };
          setVault(vaultData);
          return vaultData;
        } catch (e) {
          console.log("vault put error", e);
          setError(e);
        }
      } else {
        console.log("vault getValue error", er);
        setError(er);
        //
        // PIN was ok but the seed could not be derived.
        // Represent this condition with an empty vault.
        //
        setVault({});
        if (er.code === "InvalidKey") {
          // User has lost the former key.
          // User might recover it in the future so what do we do now?
          // Generate a new key so the app will function?
          Swal.fire({
            title: "Invalid VES Key",
            html: "Your VES key is invalid.  You may either recover your key through VES recovery or provide a new master account name in your app settings to use a different address.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      }
    }
    return null;
  }, [session, vault]);

  const setSeed = async (seed) => {
    if (session && !isLost) {
      throw new Error("The wallet seed is not lost");
    }

    const m = derive(seed);
    const { address } = m.derivePath(ethers.utils.defaultPath);
    setAccount({ address });

    const vaultData = { address, m };
    setVault(vaultData);
    console.log(address);

    return vaultData;
  };

  const saveSeed = async (seed) => {
    const { user, email } = session;
    setError(undefined);
    setSeed(seed);
    const masterKey = composeMasterKey(user.masterName, email);
    const r = await VES.putValue(masterKey, seed);
    console.log(r);
    setIsLost(false);
  };

  const context = {
    ...session,
    setSession,
    isAuthenticated: !!session,
    isLoading,
    isLost,
    account,
    lock,
    loginWithVes,
    logout,
    refresh,
    unlock,
    use,
    vault,
    error,
    setSeed,
    saveSeed,
    currentSeed,
    newSeed,
    loginWithMagic,
    loginMethod,
  };

  return <VaultContext.Provider value={context}>{children}</VaultContext.Provider>;
}

export const useVault = () => useContext(VaultContext);

export default VaultProvider;
