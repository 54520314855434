import { useState } from "react";
import { Box, IconButton, Grid } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SuiTypography from "./soft-ui/SuiTypography";

export default function MediaFileUpload({
  filesInfo,
  fileType = "image",
  isMultiple = false,
  filePreviewMainSx,
  handleUpdateFiles,
}) {
  const [error, setError] = useState("");

  const onChangeFiles = (e) => {
    setError("");

    if (!e.target.files.length) {
      return;
    }

    if (e.target.files.length > 3) {
      setError("You can upload only 3 files.");
      return;
    }

    const selectedFiles = e.target.files;

    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      previews.push(URL.createObjectURL(selectedFiles[i]));
    }

    handleUpdateFiles({ files: selectedFiles, filePreviews: previews });
  };

  return (
    <>
      <Box
        sx={(t) => ({
          height: { xs: "160px", sm: "200px" },
          border: "1px solid",
          borderColor: t.palette.inputColors.borderColor.main,
          borderRadius: "10px",
          overflow: "hidden",
          ...filePreviewMainSx,
        })}
      >
        {!filesInfo.filePreviews.length ? (
          <IconButton
            aria-label="upload media"
            component="label"
            sx={{ width: "100%", height: "100%", p: 0 }}
          >
            <FileUploadIcon />
            <input
              type="file"
              accept={fileType === "image" ? "image/*" : "video/*"}
              multiple={isMultiple}
              onChange={onChangeFiles}
              hidden
            />
          </IconButton>
        ) : (
          <IconButton
            aria-label="upload media"
            component="label"
            sx={{ width: "100%", height: "100%" }}
          >
            {isMultiple ? (
              <Grid container columnSpacing={1}>
                {filesInfo.filePreviews.map((filePreview, i) => (
                  // eslint-disable-next-line
                  <Grid item xs={4} key={i}>
                    <Box
                      component="img"
                      src={filePreview}
                      alt="image_file"
                      width="100%"
                      sx={{ objectFit: "cover", aspectRatio: "1/1" }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : fileType === "image" ? (
              <Box
                component="img"
                src={filesInfo.filePreviews[0]}
                alt="image_file"
                width="100%"
                height="100%"
                sx={{ objectFit: "cover" }}
              />
            ) : (
              <video
                src={filesInfo.filePreviews[0]}
                width="80%"
                height="100%"
                style={{ objectFit: "cover" }}
                controls
                playsinline
              />
            )}
            <input
              type="file"
              accept={fileType === "image" ? "image/*" : "video/*"}
              multiple={isMultiple}
              onChange={onChangeFiles}
              hidden
            />
          </IconButton>
        )}
      </Box>
      {error && (
        <SuiTypography color="error" fontSize="14px">
          {error}
        </SuiTypography>
      )}
    </>
  );
}
