import { useCallback, useMemo, useState } from "react";
import { useClient, useMessages, useSendMessage, useStreamMessages } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

import MessagesList from "./MessagesList";
import MessageInput from "./MessageInput";

export default function Messages({ conversation }) {
  const [isSending, setIsSending] = useState(false);
  const { messages, isLoading } = useMessages(conversation);
  const { client } = useClient();
  useStreamMessages(conversation);
  const { sendMessage } = useSendMessage();

  const filteredMessages = useMemo(
    () => messages.filter((message) => message.content !== undefined),
    [messages]
  );

  const handleSendMessage = useCallback(
    async (message) => {
      setIsSending(true);
      await sendMessage(conversation, message);
      setIsSending(false);
    },
    [conversation, sendMessage]
  );

  return (
    <Box sx={{ flex: 1, p: 1.5, display: "flex", flexDirection: "column" }}>
      <MessagesList
        isLoading={isLoading}
        messages={filteredMessages}
        clientAddress={client?.address}
      />
      <MessageInput isDisabled={isSending} onSubmit={handleSendMessage} />
    </Box>
  );
}
