import { ContentTypeId, useReply } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

import MessageContent from "./MessageContent";

export default function ReplyContent({ message }) {
  const { originalMessage } = useReply(message);

  const reply = message.content;
  const replyMessage = {
    ...message,
    content: reply.content,
    contentType: new ContentTypeId(reply.contentType).toString(),
  };

  return (
    <>
      <Box>
        {originalMessage ? (
          <MessageContent message={originalMessage} />
        ) : (
          "Loading original message..."
        )}
      </Box>
      <MessageContent message={replyMessage} />
    </>
  );
}
