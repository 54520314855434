import { createContext, useCallback, useContext, useEffect, useState } from "react";
import useAPI from "../utils/api";
import useInterval from "../utils/interval";
import { useVault } from "./vault";
import { useSnackbar } from "./snackbar";

const QuotesContext = createContext();

export function QuotesProvider({ children }) {
  const { isAuthenticated } = useVault();
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [quotes, setQuotes] = useState(() => JSON.parse(localStorage.getItem("quotes") ?? "{}"));

  const cb = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const quotes = await api("quotes", {});
        setQuotes(quotes);
        localStorage.setItem("quotes", JSON.stringify(quotes));
      } catch (e) {
        showSnackbar("Error", "Failed to fetch quotes", "error");
      }
    }
  }, [api, isAuthenticated, showSnackbar]);

  // get the quote list every 2 mins
  useInterval(cb, 120000);

  useEffect(() => {
    if (isAuthenticated) {
      cb();
    }
  }, [isAuthenticated, cb]);

  return <QuotesContext.Provider value={quotes}>{children}</QuotesContext.Provider>;
}

export const useQuotes = () => useContext(QuotesContext);

export default QuotesProvider;
