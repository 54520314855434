import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

import SuiButton from "./soft-ui/SuiButton";

export default function BackButton({ to, styles }) {
  const navigate = useNavigate();

  return (
    <SuiButton
      variant="outlined"
      color="primary"
      size="small"
      circular
      startIcon={<ArrowBack />}
      sx={{ ...styles }}
      onClick={() => navigate(to || "/home")}
    >
      Back
    </SuiButton>
  );
}
