import { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

import useAPI from "utils/api";
import { useSnackbar } from "context";
import SuiInput from "components/soft-ui/SuiInput";
import SuiButton from "components/soft-ui/SuiButton";
import SuiBox from "components/soft-ui/SuiBox";
import SuiTypography from "components/soft-ui/SuiTypography";

const initialEducationInfo = {
  url: "",
  title: "",
  description: "",
  walletAddress: "",
};

export default function AddEducationDialog({ open, onClose, educationData: eData, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [educationInfo, setEducationInfo] = useState(initialEducationInfo);
  const [errMessage, setErrMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const isEdit = useMemo(() => !!eData, [eData]);

  const handleSave = async () => {
    setErrMessage("");

    if (!educationInfo.url) {
      setErrMessage("Please input education url");
      return;
    } else if (!educationInfo.title) {
      setErrMessage("Please input education title");
      return;
    }
    // } else if (!educationInfo.walletAddress) {
    //   setErrMessage("Please input wallet address");
    //   return;
    // } else if (!isValidAddress(educationInfo.walletAddress)) {
    //   setErrMessage("Please input valid wallet address");
    //   return;
    // }

    setSaving(true);

    const educationFormData = {
      media_url: educationInfo.url,
      title: educationInfo.title,
      description: educationInfo.description,
      wallet_address: educationInfo.walletAddress,
    };

    if (isEdit) {
      educationFormData.educationId = eData.id;
    }

    try {
      await api("save_education", educationFormData);
      onClose();
      callback();

      setEducationInfo(initialEducationInfo);

      const message = isEdit ? "Updated successfully" : "Added successfully";
      showSnackbar("Success", message, "info");
    } catch (err) {
      showSnackbar("Error", "Failed to save education", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    if (!isEdit) return;

    const updatedEducationInfo = {
      ...initialEducationInfo,
      url: eData.media_url,
      title: eData.title,
      description: eData.description,
      walletAddress: eData.wallet_address,
    };

    setEducationInfo(updatedEducationInfo);
  }, [isEdit, eData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit" : "Add a new"} Education</DialogTitle>
      <DialogContent sx={{ minWidth: { sm: "400px" } }}>
        {errMessage && (
          <SuiTypography color="error" fontSize="12px" mb={1}>
            {errMessage}
          </SuiTypography>
        )}
        <SuiBox mb={2}>
          <DialogContentText>Video Url</DialogContentText>
          <SuiInput
            value={educationInfo.url}
            error={!educationInfo.url}
            onChange={(e) => setEducationInfo({ ...educationInfo, url: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Title</DialogContentText>
          <SuiInput
            value={educationInfo.title}
            error={!educationInfo.title}
            onChange={(e) => setEducationInfo({ ...educationInfo, title: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Description</DialogContentText>
          <SuiInput
            value={educationInfo.description}
            multiline
            rows={4}
            onChange={(e) => setEducationInfo({ ...educationInfo, description: e.target.value })}
          />
        </SuiBox>
        <SuiBox>
          <DialogContentText>Wallet Address</DialogContentText>
          <SuiInput
            value={educationInfo.walletAddress}
            // error={!educationInfo.walletAddress || !isValidAddress(educationInfo.walletAddress)}
            onChange={(e) => setEducationInfo({ ...educationInfo, walletAddress: e.target.value })}
          />
        </SuiBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <SuiButton
          color="primary"
          circular
          sx={{ width: "105px" }}
          disabled={saving}
          onClick={handleSave}
        >
          {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
        </SuiButton>
        <SuiButton variant="outlined" color="primary" circular onClick={onClose}>
          Cancel
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
