import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Swal from "sweetalert2";

import AnnouncementDialog from "components/dialog/AnnouncementDialog";
import { ethers } from "ethers";
import SuiBox from "../components/soft-ui/SuiBox";
import SuiTypography from "../components/soft-ui/SuiTypography";
import Navbar from "../components/layout/Navbar";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import LightweightChart from "../components/LightweightChart";
import AcceptTermsDialog from "../components/dialog/AcceptTermsDialog";
import SeedDialog from "../components/dialog/SeedDialog";
import RedundancyDialog from "../components/dialog/RedundancyDialog";
import AssetValueCard from "../components/AssetValueCard";
import GiftInfo from "../components/GiftWallet";

import { useQuotes } from "../context";
import { useBalances } from "../utils/balances";
import useAPI from "../utils/api";
import popular from "../config/popular.json";
import { useVault } from "../context/vault";
import detectIncognito from "../utils/detectIncognito";

const fixed = (val) => (val === undefined ? val : val.toFixed(2));

const valueCell = (val) => (val === undefined ? "-" : `$${fixed(val)}`);

function Home() {
  const {
    accepted_legal_terms: acceptedLegalTerms,
    refresh,
    wallet: legacyWallet,
    user,
    loginMethod,
  } = useVault();
  const api = useAPI();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const quotes = useQuotes();
  const [acceptWait, setAcceptWait] = useState(false);
  const [seedSaved, setSeedSaved] = useState(false);
  const [redundancyShow, setRedundancyShow] = useState(false);
  const { balances } = useBalances(legacyWallet?.availableBalances);
  const [valueAll, setValueAll] = useState();
  const [valueOther, setValueOther] = useState();
  const [valueStable, setValueStable] = useState();
  const [valuePPY, setValuePPY] = useState();
  const [, setHide] = useState(false);

  const usesmartwallet = user?.use_smart_wallet;

  useEffect(() => {
    setValueAll(
      popular.reduce((acc, item) => {
        if (acc === undefined) {
          return acc;
        }
        const bal = balances.get(item.symbol);
        // console.log("1->bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0):",bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0),"balance:",bal);
        const balance = Number(
          ethers.utils.formatUnits(
            bal ? (usesmartwallet ? bal[1] : bal[0]) : ethers.BigNumber.from(0),
            item.decimals
          )
        );
        // console.log("after balance:",balance);
        const price = quotes?.[item.alt]?.rate;
        return price ? acc + price * balance : acc;
      }, 0)
    );
  }, [balances, quotes, usesmartwallet]);

  useEffect(() => {
    setValueStable(
      popular.reduce((acc, item) => {
        if (acc === undefined || !item.stable) {
          return acc;
        }
        const bal = balances.get(item.symbol);
        // console.log("2-> bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0):",bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0),"balance:",bal);
        const balance = Number(
          ethers.utils.formatUnits(
            bal ? (usesmartwallet ? bal[1] : bal[0]) : ethers.BigNumber.from(0),
            item.decimals
          )
        );
        // console.log("after balance:",balance);
        const price = quotes?.[item.alt]?.rate;
        return price ? acc + price * balance : acc;
      }, 0)
    );
  }, [balances, quotes, usesmartwallet]);

  useEffect(() => {
    setValueOther(
      popular.reduce((acc, item) => {
        if (acc === undefined || item.stable || item.alt === "PPY") {
          return acc;
        }
        const bal = balances.get(item.symbol);
        // console.log("3-> bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0):",bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0),"balance:",bal);
        const balance = Number(
          ethers.utils.formatUnits(
            bal ? (usesmartwallet ? bal[1] : bal[0]) : ethers.BigNumber.from(0),
            item.decimals
          )
        );
        // console.log("after balance:",balance);
        const price = quotes?.[item.alt]?.rate;
        return price ? acc + price * balance : acc;
      }, 0)
    );
  }, [balances, quotes, usesmartwallet]);

  useEffect(() => {
    setValuePPY(
      popular.reduce((acc, item) => {
        if (acc === undefined || item.alt !== "PPY") {
          return acc;
        }
        const bal = balances.get(item.symbol);
        // console.log("4-> bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0):",bal?(usesmartwallet?bal[1]:bal[0]):ethers.BigNumber.from(0),"balance:",bal);
        const balance = Number(
          ethers.utils.formatUnits(
            bal ? (usesmartwallet ? bal[1] : bal[0]) : ethers.BigNumber.from(0),
            item.decimals
          )
        );
        // console.log("after balance:",balance);
        const price = quotes?.[item.alt]?.rate;
        return price ? acc + price * balance : acc;
      }, 0)
    );
  }, [balances, quotes, usesmartwallet]);

  useEffect(() => {
    detectIncognito()
      .then((result) => {
        if (result.isPrivate) {
          Swal.fire({
            title: "Private Browsing Detected",
            html: "Although there's nothing wrong with private mode for Internet browsing, PatriotPay wallet's split key encryption via VESvault requires persistent storage.<br/><br/>Since storage is deleted after private browsing sessions terminate, you will likely lose access and will be unable to recover funds.<br/><br/>Please reload the app in a normal browsing context.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.log("detectIncognito", error);
      });
  }, []);

  useEffect(() => {
    const distinct = {};
    popular.forEach((item) => {
      if (!distinct[item.chainId]) {
        distinct[item.chainId] = true;
      }
    });

    const storageTestKey = "__storage_test__";
    const storage = window.sessionStorage;
    try {
      storage.setItem(storageTestKey, "test");
      storage.removeItem(storageTestKey);
    } catch (e) {
      if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
        console.log("private widow");
      } else {
        console.log("not private");
      }
    }
    if (!localStorage.getItem("ves_redundancy")) setRedundancyShow(true);
  }, []);

  const handleAcceptLegalTerms = async () => {
    setAcceptWait(true);
    if (loginMethod === "magic" || seedSaved) {
      await api("config/accept-terms");
      refresh();
    }
  };

  const handleSeedDialog = () => {
    setSeedSaved(true);
    setRedundancyShow(true);
    api("config/accept-terms").then(() => refresh());
  };

  const handleRedundancyDialog = () => {
    localStorage.setItem("ves_redundancy", 1);
    setRedundancyShow(false);
  };

  return (
    <SuiBox>
      <Header />
      <AcceptTermsDialog
        open={!acceptWait && !acceptedLegalTerms}
        onAccept={handleAcceptLegalTerms}
      />
      <SeedDialog
        open={loginMethod === "ves" && acceptWait && !seedSaved}
        onAccept={handleSeedDialog}
      />
      <RedundancyDialog
        open={loginMethod === "ves" && (acceptedLegalTerms || seedSaved) && redundancyShow}
        onAccept={handleRedundancyDialog}
      />
      <AnnouncementDialog />
      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent={{ xs: "flex-start", lg: "stretch" }}
        maxWidth="100%"
        p={{ xs: 1.5, lg: 2.5 }}
        pb={{ xs: 2, lg: 2.5 }}
        minHeight="calc(100vh - 57px)"
      >
        <SuiBox
          mr={{ xs: 0, lg: 2.5 }}
          width={{ xs: "100%", lg: "450px" }}
          minWidth={{ xs: "100%", lg: "450px" }}
        >
          <Navbar hideZeroBalance={setHide} />
          <SuiBox
            mt={2}
            p={{ xs: 2, lg: 3 }}
            bgColor="white"
            border="0px solid rgba(0, 0, 0, 0.125)"
            borderRadius="lg"
            boxShadow="rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem"
            minHeight={{ xs: 0, lg: "calc(100vh - 234px)" }}
          >
            <GiftInfo />
            <Link to="/assets">
              <SuiBox
                p={3}
                mb={{ xs: 2, lg: 3 }}
                bgColor="linear-gradient(to bottom right, #921b27, #d8171d, #921b27);"
                borderRadius="lg"
                boxShadow="4px 4px 8px #050d15, -4px -4x 8px #0c2135"
              >
                <SuiBox>
                  <SuiTypography
                    variant="h4"
                    fontWeight="light"
                    color="white"
                    textAlign="center"
                    fontSize={{ xs: 20, lg: 24 }}
                  >
                    Estimated Account Value
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <SuiTypography
                    variant="h1"
                    fontSize={{ xs: 44, lg: 60 }}
                    fontWeight="light"
                    color="white"
                    textAlign="center"
                  >
                    {valueCell(valueAll)}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </Link>
            <SuiBox>
              <Link to="/assets#stable">
                <AssetValueCard color="card1" number={valueCell(valueStable)} name="USD" />
              </Link>
            </SuiBox>
            <SuiBox mt={-16}>
              <Link to="/assets#!stable">
                <AssetValueCard color="card2" number={valueCell(valueOther)} name="Other Crypto" />
              </Link>
            </SuiBox>
            <SuiBox mt={-16} mb={3}>
              <Link to="/assets#!ppy">
                <AssetValueCard
                  color="card3"
                  variant="front"
                  number={valueCell(valuePPY)}
                  name="PPY"
                />
              </Link>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {lgUp ? <LightweightChart asset="PPY" height={xlUp ? 680 : lgUp ? 500 : 220} /> : null}
      </SuiBox>
      <Footer />
    </SuiBox>
  );
}

export default Home;
