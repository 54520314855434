import { useEffect, useRef } from "react";
import Box from "@mui/material/Box";

import Loading from "components/Loading";
import Message from "./Message";

export default function MessagesList({ clientAddress = "", isLoading = false, messages = [] }) {
  const messagesContainerRef = useRef();

  useEffect(() => {
    if (!messagesContainerRef.current) {
      return;
    }

    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  }, [messages]);

  return (
    <Box sx={{ flex: 1, mb: 1, overflow: "auto" }} ref={messagesContainerRef}>
      <Box sx={{ textAlign: "center" }}>{isLoading && !messages.length && <Loading />}</Box>
      {messages.map((message) => {
        const isIncoming = message.senderAddress !== clientAddress;

        return <Message key={message.id} message={message} isIncoming={isIncoming} />;
      })}
    </Box>
  );
}
