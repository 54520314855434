import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SuiTypography from "../soft-ui/SuiTypography";

export default function RedundancyDialog({ open, onAccept }) {
  const handleAccept = async (url) => {
    onAccept();
    if (url) document.location = url;
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogContent>
        <DialogContentText>
          <SuiTypography variant="h5" textAlign="center" fontWeight="bold">
            VES Key Redundancies
          </SuiTypography>
          <SuiTypography variant="caption" fontSize={16} py={2} style={{ lineHeight: 1 }}>
            In addition to your Seed Phrase, VES provides additional key redundancies that can be
            used to recover your wallet. You must first set up these VES redundancies before they
            can be used. You can do that now or later.
          </SuiTypography>
          <SuiTypography variant="caption" fontSize={16} py={2} style={{ lineHeight: 1 }}>
            VES uses red circle alerts to guide you through the setup of these additional key
            redundancies. The main alert is located on the upper left menu of the wallet Access
            Authorization page, where you enter your PIN. You can go back to that page with this{" "}
            <a
              href="/login"
              onClick={(e) => handleAccept(e.target.href)}
              style={{
                textTransform: "uppercase",
                color: "#17c1e8",
                fontWeight: 700,
              }}
            >
              link
            </a>
            .
          </SuiTypography>
          <SuiTypography variant="caption" fontSize={16} py={2} textAlign="center">
            Or, continue to your wallet
          </SuiTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAccept()}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
}
