import { useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import SuiButton from "components/soft-ui/SuiButton";

export default function MessageInput({ isDisabled, onSubmit, placeholder }) {
  const [value, setValue] = useState("");
  const onChange = (event) => setValue(event.target.value);

  // const handleKeyDown = useCallback(
  //   (event) => {
  //     if (event.key === "Enter" && !event.shiftKey) {
  //       event.preventDefault();
  //       if (value) {
  //         onSubmit?.(value);
  //         setValue("");
  //       }
  //     }
  //   },
  //   [onSubmit, value]
  // );

  const handleClick = useCallback(() => {
    if (value) {
      onSubmit?.(value);
      setValue("");
    }
  }, [onSubmit, value]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        multiline
        fullWidth
        maxRows={4}
        placeholder={placeholder || "Write a message..."}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        // onKeyDown={handleKeyDown}
      />
      <SuiButton
        variant="contained"
        color="primary"
        circular
        sx={{ width: "85px", textTransform: "capitalize", ml: 1 }}
        disabled={!value || isDisabled}
        onClick={handleClick}
      >
        Send
      </SuiButton>
    </Box>
  );
}
