import React, { useState } from "react";
import { Map } from "immutable";
import { Menu, MenuItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import SuiButton from "../../components/soft-ui/SuiButton";
import { adjustSymbol } from "../../utils/format";

import popular from "../../config/popular.json";

const TOKENS = popular.reduce((acc, item) => acc.set(item.symbol, item), Map());

export default function CurrenciesDropdown({ value, currencies, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <SuiButton
        color="primary"
        size="small"
        variant="standard"
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        onClick={handleClick}
        sx={{ py: 0.5, fontWeight: "normal" }}
      >
        {value}
      </SuiButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {(currencies || []).map((currency) => (
          <MenuItem
            key={currency.symbol}
            value={currency.symbol}
            onClick={() => {
              onSelect?.(currency.symbol, TOKENS.get(currency.symbol)?.network);
              setAnchorEl(null);
            }}
          >
            {adjustSymbol(currency.symbol)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
