import { useMemo } from "react";
import { ContentTypeId, ContentTypeText } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

export default function MessageContent({ message }) {
  const content = useMemo(() => {
    const contentType = ContentTypeId.fromString(message.contentType);

    if (contentType.sameAs(ContentTypeText)) {
      return typeof message.content === "string" ? message.content : undefined;
    }

    return undefined;
  }, [message.content, message.contentType]);

  return (
    <Box>
      {content ?? message.contentFallback ?? "This content is not supported by this client"}
    </Box>
  );
}
