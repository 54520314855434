import { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageWrapper from "components/layout/PageWrapper";
import NoData from "components/NoData";
import PageLoading from "components/PageLoading";
import useAPI from "utils/api";
import SuiButton from "components/soft-ui/SuiButton";
import { useVault } from "context/vault";
import { useSnackbar } from "../../context";
import AddEducationDialog from "./Admin/AddEducationDialog";
import EducationCard from "./EducationCard";
import SuiTypography from "../../components/soft-ui/SuiTypography";

export default function EducationList() {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [educations, setEducations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const { user } = useVault();

  const fetchEducations = useCallback(async () => {
    setLoading(true);

    try {
      const { educations: result } = await api("educations");
      setEducations(result);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch educations", "error");
    }

    setLoading(false);
  }, [api, showSnackbar]);

  useEffect(() => {
    fetchEducations();
  }, [fetchEducations]);

  return (
    <PageWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <SuiTypography sx={{ fontSize: "24px" }}>Education</SuiTypography>
        {user.is_admin && (
          <SuiButton
            color="primary"
            circular
            sx={{ textTransform: "capitalize" }}
            startIcon={<AddIcon />}
            onClick={() => setOpenAddDialog(!openAddDialog)}
          >
            Add
          </SuiButton>
        )}
      </Box>
      {!!educations.length && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
            gap: "40px",
          }}
        >
          {educations.map((education) => (
            <EducationCard key={education.id} education={education} />
          ))}
        </Box>
      )}
      {loading ? <PageLoading /> : !educations.length && <NoData />}
      <AddEducationDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        callback={fetchEducations}
      />
    </PageWrapper>
  );
}
