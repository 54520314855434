import React, { useEffect, useMemo, useState } from "react";
import Loading from "components/Loading";
import SuiBox from "../../components/soft-ui/SuiBox";
import SuiTypography from "../../components/soft-ui/SuiTypography";
import DataTable from "../../components/soft-ui/SuiDataTable";
import useAPI from "../../utils/api";
import { useVault } from "context";
import { getSmartWalletAddress } from "utils/contracts";
import { CONTRACTS } from "context/web3";
import { isSameAddress, shortenAddress } from "utils/format";
import NoData from "components/NoData";

const statusColors = {
  Initialized: "rgb(153, 166, 192)",
  Pending: "rgb(52, 71, 103)",
  Failed: "rgb(234, 6, 6)",
  Completed: "rgb(130, 214, 22)",
};

const amountColors = {
  plus: "rgb(130, 214, 22)",
  minus: "rgb(234, 6, 6)",
};

// const findSwapOutAmount = (log) => {
//   for (let i = log.length - 1; i >= 0; i--) {
//     if (log[i]?.d?.amount) {
//       return log[i].d.amount;
//     }
//   }
//   // let n = log.length - 1;
//   // if (n > 2) {
//   //   if (log[n].s === "completed") {
//   //     if (log[n]?.d?.amount) {
//   //       return log[n].d.amount;
//   //     }
//   //     if (log[n - 1].s === "pending") {
//   //       return log[n - 1].d.amount;
//   //     }
//   //   }
//   // }

//   return "--";
// };

export default function OrderHistory({ selectedItem }) {
  const api = useAPI();
  const { user, account } = useVault();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const smartAddress = useMemo(
    () => getSmartWalletAddress(CONTRACTS.FreedomFactory.address, account.address),
    [account.address]
  );

  const currentAddress = useMemo(
    () => (user.use_smart_wallet ? smartAddress : account.address),
    [smartAddress, user.use_smart_wallet, account.address]
  );

  const filteredOrders = useMemo(
    () =>
      orders.filter((order) => {
        const isReceive = order.dest === currentAddress;
        const isSend = order.source === currentAddress;

        return isReceive || isSend;
      }),
    [currentAddress, orders]
  );

  useEffect(() => {
    const getOrdersData = async () => {
      try {
        setLoading(true);
        const result = await api("orders", { involving: selectedItem.symbol });
        setOrders(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (selectedItem) {
      getOrdersData();
    } else {
      setOrders([]);
    }
  }, [api, selectedItem]);

  return (
    <SuiBox>
      <SuiTypography variant="h4">Order History</SuiTypography>
      <SuiBox m={-2} mt={2}>
        {loading ? (
          <SuiBox
            sx={{
              my: 10,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </SuiBox>
        ) : (
          !filteredOrders.length && <NoData />
        )}
        {!!filteredOrders.length && (
          <DataTable
            table={{
              columns: [
                {
                  Header: "date",
                  accessor: "created_at",
                  Cell: ({ value }) => (
                    <SuiTypography variant="caption">
                      {new Date(value).toLocaleString()}
                    </SuiTypography>
                  ),
                },
                {
                  Header: "amount",
                  accessor: "in_amount",
                  Cell: ({ row }) => {
                    const { out_sym, in_amount, out_amount, dest, source } = row.original;
                    let isReceive;

                    if (source === dest) {
                      isReceive = out_sym === selectedItem.symbol;
                    } else {
                      isReceive = dest === currentAddress;
                    }

                    const amount = isReceive ? out_amount : in_amount;
                    // const isNotZero = !Number.isNaN(value) && !!Number(value);

                    return (
                      <SuiTypography
                        variant="caption"
                        sx={{
                          color: isReceive ? amountColors.plus : amountColors.minus,
                        }}
                      >
                        {/* <span style={{ marginRight: "2px" }}>
                          {isNotZero && (isReceive ? "+" : "-")}
                        </span> */}
                        <span>{Number(amount)}</span>
                        {/* <span style={{ marginLeft: "2px" }}>{inSymbol}</span> */}
                      </SuiTypography>
                    );
                  },
                },
                {
                  Header: "Type",
                  accessor: "type",
                  Cell: ({ value }) => <SuiTypography variant="caption">{value}</SuiTypography>,
                },
                {
                  Header: "From",
                  accessor: "source",
                  Cell: ({ value }) => (
                    <SuiTypography variant="caption">
                      {value
                        ? isSameAddress(value, smartAddress)
                          ? "SW"
                          : isSameAddress(value, account.address)
                          ? "EW"
                          : shortenAddress(value)
                        : "--"}
                    </SuiTypography>
                  ),
                },
                {
                  Header: "To",
                  accessor: "dest",
                  Cell: ({ value }) => (
                    <SuiTypography variant="caption">
                      {value
                        ? isSameAddress(value, smartAddress)
                          ? "SW"
                          : isSameAddress(value, account.address)
                          ? "EW"
                          : shortenAddress(value)
                        : "--"}
                    </SuiTypography>
                  ),
                },
                {
                  Header: "status",
                  accessor: "status",
                  Cell: ({ value }) => (
                    <SuiTypography
                      variant="caption"
                      sx={{
                        textTransform: "capitalize",
                        color: statusColors[value],
                      }}
                    >
                      {value}
                    </SuiTypography>
                  ),
                },
                // {
                //   Header: "status",
                //   accessor: "s",
                //   Cell: ({ value }) => {
                //     let status;

                //     if (value === "paid") {
                //       status = <StatusCell icon="done" color="success" status="Paid" />;
                //     } else if (value === "refunded") {
                //       status = <StatusCell icon="replay" color="dark" status="Refunded" />;
                //     } else {
                //       status = <StatusCell icon="close" color="error" status="Canceled" />;
                //     }

                //     return status;
                //   },
                // },
              ],
              rows: filteredOrders,
            }}
            entriesPerPage={false}
            showTotalEntries={false}
          />
        )}
      </SuiBox>
    </SuiBox>
  );
}
