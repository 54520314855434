import { ethers } from "ethers";
import { Magic } from "magic-sdk";
import { WebAuthnExtension } from "@magic-ext/webauthn";

const rpcUrls = [
  "https://polygon.blockpi.network/v1/rpc/public/",
  "https://polygon-mainnet.public.blastapi.io/",
  "https://polygon-rpc.com/",
  "https://rpc-mainnet.matic.quiknode.pro/",
  "https://polygon-pokt.nodies.app/",
  "https://polygon-bor-rpc.publicnode.com/",
  "https://rpc.ankr.com/polygon",
];

export const createMagicWithFallback = async (options) => {
  let magic;
  let provider;

  // eslint-disable-next-line
  for (const rpcUrl of rpcUrls) {
    try {
      magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
        network: { rpcUrl, chainId: 137 },
        extensions: [new WebAuthnExtension()],
        ...(options || {}),
      });
      provider = new ethers.providers.Web3Provider(magic.rpcProvider);

      // eslint-disable-next-line
      await provider.getBlockNumber(); // Simple check to see if the provider works
      console.log(`Connected to ${rpcUrl}`);
      break;
    } catch (error) {
      console.error(`Failed to connect to ${rpcUrl}:`, error);
    }
  }

  if (!provider) {
    throw new Error("All RPC URLs failed.");
  }

  return { magic, provider };
};

// initialize Magic service & web3 provider
export const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
  network: {
    rpcUrl: "https://polygon-rpc.com/", // Polygon RPC URL
    chainId: 137, // Polygon chain id
  },
  extensions: [new WebAuthnExtension()],
});

export const magicProvider = new ethers.providers.Web3Provider(magic.rpcProvider);

console.log(magic, magicProvider);
