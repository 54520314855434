import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import SuiTypography from "../soft-ui/SuiTypography";
import SuiBox from "../soft-ui/SuiBox";
import SuiButton from "../soft-ui/SuiButton";
import MnemonicDialog from "./MnemonicDialog";
import SuiInput from "../soft-ui/SuiInput";
import useAPI from "../../utils/api";
import { useVault } from "../../context/vault";
import { createMagicWithFallback } from "../../config/magic";

export default function SettingsDialog({ open, onClose }) {
  const api = useAPI();
  const [mnemonic, setMnemonic] = useState(false);
  const [masterName, setMasterName] = useState();
  const { user, refresh, isAuthenticated, vault, lock, logout, unlock, use, loginMethod } =
    useVault();
  const [busy, setBusy] = useState();

  const effectiveMasterName = user?.masterName ?? "master";

  const handleClose = useCallback(async () => {
    if (masterName && masterName !== effectiveMasterName) {
      const { isConfirmed } = await Swal.fire({
        title: `Change master account name?`,
        text: `This changes the addresses stored in VESvault.  Return to settings and reenter "${effectiveMasterName}" to reuse the current addresses.  Are you sure you want to change the master account name to "${masterName}"?`,
        icon: "question",
        showCancelButton: true,
      });

      if (isConfirmed) {
        setBusy(true);
        await api("config/update-master", { masterName });
        await use(masterName);
        refresh();
        setBusy(false);
        onClose();
      } else {
        setMasterName();
        onClose();
      }
    } else {
      onClose();
    }
  }, [masterName, onClose, api, effectiveMasterName, refresh, use]);

  const handleDeleteAccount = async () => {
    const which = await Swal.fire({
      title: `Delete Account`,
      text: `This is an irreversable operation.  Are you sure that you want to delete your account?`,
      icon: "error",
      showCancelButton: true,
    });
    if (which.isConfirmed) {
      await Swal.fire({
        title: `48 Hour Grace Period`,
        text: "If you change your mind you may sign in again to cancel deletion and continue using your account, otherwise, after the grace period ends, your account will be permanantly deleted.",
        icon: "info",
      });
      try {
        await api("users/delete", {});
      } catch (e) {
        console.log("Error while removing user!", e);
      }
      logout();
    }
  };

  if (!isAuthenticated) {
    return <div />;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MnemonicDialog open={mnemonic} onClose={() => setMnemonic(false)} />

      <DialogTitle id="alert-dialog-title">
        Settings
        {busy ? (
          <CircularProgress
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        ) : (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <SuiBox display="flex">
          <SuiBox flexGrow="1" mx={3} mb={3}>
            <SuiTypography variant="h5" mb={1}>
              PatriotPay Gift Cards
            </SuiTypography>
            <SuiButton variant="outlined" size="small" color="primary" href="/redeem">
              Redeem a Gift Card
            </SuiButton>
          </SuiBox>
        </SuiBox>
        {loginMethod === "ves" && (
          <>
            <SuiBox display="flex">
              <SuiBox flexGrow="1" mx={3} mb={3}>
                <SuiTypography variant="h5" mb={1}>
                  Master Account Name
                </SuiTypography>
                <DialogContentText id="alert-dialog-description">
                  This the name of the VESvault value that stores your master seed phrase and should
                  be set to <i>master</i> for most users. Changing this to some other value will
                  reconfigure your addresses.
                </DialogContentText>
                <SuiInput
                  disabled={!vault}
                  onChange={(e) => setMasterName(e.target.value)}
                  defaultValue={effectiveMasterName}
                />
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex">
              <SuiBox flexGrow="1" mx={3} mb={3}>
                <SuiTypography variant="h5">Recovery Phrase</SuiTypography>
                <DialogContentText id="alert-dialog-description" mb={1}>
                  Reveal your secret recovery phrase to restore your wallet in case of an
                  unfortunate event.
                </DialogContentText>
                <SuiButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={async () => {
                    if (!vault) await unlock(user);
                    setMnemonic(true);
                  }}
                >
                  Show Recovery Phrase
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </>
        )}
        {loginMethod === "magic" && (
          <SuiBox display="flex">
            <SuiBox flexGrow="1" mx={3} mb={3}>
              <SuiTypography variant="h5">Private Key</SuiTypography>
              <DialogContentText id="alert-dialog-description" mb={1}>
                To keep your funds safe, make sure no one can see or screenshot your private key
                while you access it. Phishing scams often ask for your private key to steal your
                account. Never share your private key with anyone, including the Patriot Pay team.
                No Patriot Pay representative, including our Support/Admin team, will ever ask for
                your private key, no matter the situation. If someone does ask, they&apos;re likely
                trying to scam you, so don&apos;t share it.
              </DialogContentText>
              <SuiButton
                variant="outlined"
                size="small"
                color="primary"
                onClick={async () => {
                  try {
                    const { magic } = await createMagicWithFallback();
                    await magic.user.revealPrivateKey();
                  } catch (e) {
                    console.error("RPC connection error");
                  }
                }}
              >
                Private Key
              </SuiButton>
            </SuiBox>
          </SuiBox>
        )}
        <SuiBox display="flex">
          <SuiBox flexGrow="1" mx={3} mb={3}>
            <SuiTypography variant="h5">Delete Your Account</SuiTypography>
            <DialogContentText id="alert-dialog-description" mb={1}>
              By deleting your account you understand that you will lose access to any and all
              assets in the account. Please ensure all assets have been moved to another wallet
              before deleting your account.
            </DialogContentText>
            <SuiButton
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </DialogContent>
      {loginMethod === "ves" && (
        <DialogActions dir="rtl">
          <Button onClick={() => (vault ? lock() : unlock(user))}>
            {vault ? "LOCK" : "UNLOCK"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
