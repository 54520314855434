import { useEffect, useState } from "react";
import { detect } from "detect-browser";
import { FormLabel, CircularProgress } from "@mui/material";

import SuiBox from "components/soft-ui/SuiBox";
import SuiInput from "components/soft-ui/SuiInput";
import SuiTypography from "components/soft-ui/SuiTypography";
import SuiButton from "components/soft-ui/SuiButton";
import PageWrapper from "components/layout/PageWrapper";
import MediaFileUpload from "components/MediaFileUpload";
import { useVault, useSnackbar } from "context";
import useAPI from "utils/api";

const browser = detect();

const initialEnvs = {
  platform: "",
  browser: "",
  os: "",
};

const initialFileInfo = {
  files: [],
  filePreviews: [],
};

export default function Report() {
  const { email } = useVault();
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [envs, setEnvs] = useState(initialEnvs);
  const [description, setDescription] = useState("");
  const [fileInfo, setFileInfo] = useState(initialFileInfo);
  const [saving, setSaving] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const handleSubmit = async () => {
    setErrMessage("");

    if (!envs.platform) {
      setErrMessage("Please input platform");
      return;
    } else if (!envs.browser) {
      setErrMessage("Please input browser information");
      return;
    } else if (!envs.os) {
      setErrMessage("Please input operation system");
      return;
    } else if (!description) {
      setErrMessage("Please input description");
      return;
    }

    const reportFormData = new FormData();
    if (!!fileInfo.files.length) {
      reportFormData.append("file", fileInfo.files[0]);
    }
    reportFormData.append("sender", email);
    reportFormData.append("platform", envs.platform);
    reportFormData.append("browser", envs.browser);
    reportFormData.append("os", envs.os);
    reportFormData.append("description", description);

    setSaving(true);

    try {
      await api("users/report-bug", reportFormData, true);

      setDescription("");
      setFileInfo(initialFileInfo);
      showSnackbar("Success", "Submitted successfully", "info");
    } catch (err) {
      showSnackbar("Error", "Failed to submit", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    if (browser) {
      const os = browser.os;
      const browserInfo = `${browser.name}, ${browser.version}`;

      let platform = "Web";
      if (os.includes("iOS")) {
        platform = "iOS";
      } else if (os.includes("Android")) {
        platform = "Android";
      } else if (os.includes("Mac") || os.includes("Windows") || os.includes("Linux")) {
        platform = "Web";
      }

      setEnvs({ platform, browser: browserInfo, os });
    }
  }, []);

  return (
    <PageWrapper>
      <SuiTypography sx={{ fontSize: 24, textAlign: "center", mb: 5 }}>Report A Bug</SuiTypography>
      {errMessage && (
        <SuiTypography color="error" fontSize="12px" mb={1}>
          {errMessage}
        </SuiTypography>
      )}
      <SuiBox mb={2}>
        <FormLabel sx={{ fontSize: 18 }}>Sender Info</FormLabel>
        <SuiInput disabled value={email} />
      </SuiBox>
      <SuiBox mb={2}>
        <FormLabel sx={{ fontSize: 18 }}>Environments</FormLabel>
        <SuiBox
          sx={{
            display: "grid",
            gridTemplateColumns: "60px 1fr",
            gridTemplateRows: "repeat(3, 1fr)",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <FormLabel sx={{ fontSize: 14 }}>Platform:</FormLabel>
          <SuiInput
            value={envs.platform}
            error={!envs.platform}
            onChange={(e) => setEnvs({ ...envs, platform: e.target.value })}
          />
          <FormLabel sx={{ fontSize: 14 }}>Browser:</FormLabel>
          <SuiInput
            value={envs.browser}
            error={!envs.browser}
            onChange={(e) => setEnvs({ ...envs, browser: e.target.value })}
          />
          <FormLabel sx={{ fontSize: 14 }}>OS:</FormLabel>
          <SuiInput
            value={envs.os}
            error={!envs.os}
            onChange={(e) => setEnvs({ ...envs, os: e.target.value })}
          />
        </SuiBox>
      </SuiBox>
      <SuiBox mb={2}>
        <FormLabel sx={{ fontSize: 18 }}>Bug Description</FormLabel>
        <SuiInput
          value={description}
          error={!description}
          multiline
          rows={5}
          onChange={(e) => setDescription(e.target.value)}
        />
      </SuiBox>
      <SuiBox mb={2}>
        <FormLabel sx={{ fontSize: 18 }}>Attachment</FormLabel>
        <MediaFileUpload filesInfo={fileInfo} handleUpdateFiles={setFileInfo} />
      </SuiBox>
      <SuiBox mt={4} sx={{ textAlign: "center" }}>
        <SuiButton
          color="primary"
          circular
          sx={{ width: "105px" }}
          disabled={saving}
          onClick={handleSubmit}
        >
          {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Submit"}
        </SuiButton>
      </SuiBox>
    </PageWrapper>
  );
}
