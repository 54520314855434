import { useState } from "react";
import { Tab, Box, styled } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import PageWrapper from "components/layout/PageWrapper";
import Announcement from "./Announcement";
import Products from "./Products";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    fontWeight: 700,
  },
});

export default function Admin() {
  const [tabValue, setTabValue] = useState("announcement");

  const onChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageWrapper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList variant="scrollable" onChange={onChangeTabValue}>
            <StyledTab label="Announcement" value="announcement" />
            <StyledTab label="Products" value="products" />
          </TabList>
        </Box>
        <TabPanel value="announcement">
          <Announcement />
        </TabPanel>
        <TabPanel value="products">
          <Products />
        </TabPanel>
      </TabContext>
    </PageWrapper>
  );
}
