import * as React from "react";
import { IconButton, Dialog, DialogContent, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SumsubDialog({ open, onClose, children }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={1} textAlign="right">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
