import { useEffect } from "react";
import { useClient } from "@xmtp/react-sdk";
import { Box } from "@mui/material";

import { magicProvider } from "config/magic";

export default function XMTPConnect() {
  const { initialize, isLoading, error } = useClient();

  useEffect(() => {
    initialize({
      signer: magicProvider.getSigner(),
      options: {
        env: "dev",
      },
    });
  }, [initialize]);

  let text;

  if (error) {
    text = "Something went wrong";
  }

  if (isLoading) {
    text = "Awaiting signatures...";
  }

  return <Box sx={{ mt: 5, textAlign: "center" }}>{text}</Box>;
}
