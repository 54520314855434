import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import SessionTimeoutDialog from "./dialog/SessionTimeoutDialog";

let countdownInterval;
let timeout;

function SessionTimeout({ isAuthenticated, logOut }) {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const handleLogout = async () => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      logOut();
    } catch (err) {
      console.error(err);
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 60;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            countDown -= 1;
            setTimeoutCountdown(countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  /* const {} = */ useIdleTimer({
    onActive,
    onIdle,
    debounce: 250,
    timeout: 20 * 60 * 1000,
  });

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  return (
    <SessionTimeoutDialog
      countdown={timeoutCountdown}
      onContinue={handleContinue}
      onLogout={() => handleLogout(false)}
      open={timeoutModalOpen}
    />
  );
}

export default SessionTimeout;
