import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SuiTypography from "../soft-ui/SuiTypography";
import { useVault } from "../../context/vault";

export default function SeedDialog({ open, onAccept }) {
  const { currentSeed } = useVault();
  const [copied, setCopied] = useState(false);
  const [enteredSeed, setEnteredSeed] = useState("");

  const handleAccept = async () => {
    if (copied) onAccept();
    else setCopied(true);
  };

  const fmtSeed = (seed) =>
    seed
      ? seed
          .replace(/\s+/g, " ")
          .replace(/^\s|\s$/g, "")
          .toLowerCase()
      : null;

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      {copied ? (
        <DialogContent>
          <DialogContentText>
            <SuiTypography variant="h5" textAlign="center" fontWeight="bold">
              Enter Your Seed Phrase:
            </SuiTypography>
          </DialogContentText>
          <textarea
            style={{ width: "100%" }}
            rows={6}
            value={enteredSeed}
            onChange={(e) => setEnteredSeed(e.target.value)}
          />
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            <SuiTypography
              variant="h5"
              textAlign="center"
              style={{ color: "#ff0000" }}
              fontWeight="bold"
            >
              IMPORTANT!
            </SuiTypography>
            <SuiTypography
              variant="caption"
              style={{ color: "#ff0000", lineHeight: 1 }}
              fontSize={16}
              fontWeight="bold"
              py={2}
            >
              COPY THE BELOW SEED PHRASE TO A USB DRIVE OR WRITE IT DOWN ON PAPER AND SAVE IT WHERE
              IT CANNOT GET LOST. DO THIS NOW.
            </SuiTypography>
            <SuiTypography
              variant="caption"
              style={{ color: "#ff0000", lineHeight: 1, paddingBottom: 16 }}
              fontSize={16}
              fontWeight="bold"
            >
              You can always use your Seed Phrase to recovery your wallet, even if you lose your
              device. Never share your Seed Phrase with anyone or store it electronically to the
              cloud.
            </SuiTypography>
            <SuiTypography variant="h5" textAlign="center" fontWeight="bold">
              Your Seed Phrase:
            </SuiTypography>
          </DialogContentText>
          <textarea style={{ width: "100%" }} rows={6} value={currentSeed} readOnly />
          <DialogContentText>
            <SuiTypography variant="caption" py={2} fontSize={16}>
              You will need to enter this Seed Phrase on the next screen to finish setting up this
              wallet.
            </SuiTypography>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleAccept}
          disabled={copied && fmtSeed(enteredSeed) !== fmtSeed(currentSeed)}
        >
          Continue
        </Button>
        {copied ? (
          <Button
            onClick={() => {
              if (copied) setCopied(false);
              else document.location = "/login";
            }}
          >
            Back
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}
