import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function AcceptTermsDialog({ open, onAccept }) {
  const handleAccept = async () => {
    onAccept();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Legal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please review the PatriotPay Privacy Policy and Terms of Service.
        </DialogContentText>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://patriotpay.org/app-disclaimer/"
              target="_blank"
            >
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://patriotpay.org/app-privacy-policy/"
              target="_blank"
            >
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept}>Accept</Button>
      </DialogActions>
    </Dialog>
  );
}
