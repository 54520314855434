import { ethers } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";

import { CONTRACTS } from "context/web3";
import FreedomWallet from "../contracts/FreedomWallet.json";
import IERC20 from "../contracts/IERC20.json";
import { magicProvider } from "../config/magic";
import { magicSign } from "./eip712";

// The contracts support an instance ID salt in case users would like more than one
// smart wallet per owner address, however this is not yet supported in the UI so
// all wallets will use the same zero salt.
//
export const INSTANCE_SALT = "0x0000000000000000000000000000000000000000000000000000000000000000";

// Smart contract initialization bytecode for a FreedomWallet for the given owner.
//
export const getSmartWalletInitCode = (owner) =>
  `0x${FreedomWallet.bytecode}000000000000000000000000${owner.slice(2)}`;

// Returns the deterministic address for a FreedomWallet
// deployed from the given factory for the given owner and salt.
//
export const getSmartWalletAddress = (factory, owner) =>
  `0x${ethers.utils
    .keccak256(
      `0xff${factory.slice(2)}${INSTANCE_SALT.slice(2)}${ethers.utils
        .keccak256(getSmartWalletInitCode(owner))
        .slice(2)}`
    )
    .slice(26)}`;

export const getSignForSmartWallet = async (account, amount, tokenInfo) => {
  const tokenAmount = parseUnits(amount, tokenInfo.decimals);
  const erc20 = new ethers.Contract(tokenInfo.tokenAddress, IERC20, magicProvider.getSigner());
  const gasPrice = await magicProvider.getGasPrice();

  const smartWalletAddress = getSmartWalletAddress(
    CONTRACTS.FreedomFactory.address,
    account.address
  );
  const code = await magicProvider.getCode(smartWalletAddress);
  const smartWallet = new ethers.Contract(
    smartWalletAddress,
    CONTRACTS.FreedomWallet.abi,
    magicProvider
  );
  const byProxy = erc20.interface.encodeFunctionData("transfer", [
    CONTRACTS.FreedomAgentV2.address,
    tokenAmount,
  ]);

  const innerTx = {
    token: tokenInfo.tokenAddress,
    value: 0,
    data: byProxy,
    nonce: code === "0x" ? 0 : await smartWallet.nonce(),
    executor: CONTRACTS.FreedomAgentV2.address,
    gasLimit: 800000,
    gasPrice: Number(formatUnits(gasPrice, "wei")),
    chainId: Number(tokenInfo.chainId),
  };

  const sign = await magicSign(CONTRACTS.FreedomFactory, account.address, innerTx);

  return { sign, innerTx, code };
};

export const transferTokens = async (to, amount, tokenInfo) => {
  const tokenAmount = parseUnits(amount, tokenInfo.decimals);
  const erc20 = new ethers.Contract(tokenInfo.tokenAddress, IERC20, magicProvider.getSigner());
  const gasPrice = await magicProvider.getGasPrice();

  const tx = await erc20.transfer(to, tokenAmount, {
    gasPrice,
    gasLimit: 400000,
  });

  return tx;
};

export const transferTokensForSmartWallet = async (signedTx) => {
  const tx = await magicProvider.sendTransaction(signedTx);
  return tx;
};
