import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import curveBg from "assets/images/new-curved.png";
import SuiBox from "./soft-ui/SuiBox";
import SuiTypography from "./soft-ui/SuiTypography";

function AssetValueCard({ variant, color, number, name }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { linearGradient, rgba },
        boxShadows: { xl },
      }) => ({
        background: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.8),
              rgba(gradients[color].state, 0.8)
            )}, url(${curveBg})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${curveBg})`,
        boxShadow: xl,
      })}
    >
      <SuiBox px={2} py={1}>
        <SuiBox display="flex" color="white" p={1} lineHeight={0}>
          <SuiBox flexGrow="1">
            <SuiTypography
              variant="h3"
              color="white"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {name}
            </SuiTypography>
          </SuiBox>
          {variant === "back" ? (
            <SuiBox mr={3}>
              <SuiTypography
                variant="h3"
                color="white"
                fontWeight="light"
                textTransform="capitalize"
              >
                {number}
              </SuiTypography>
            </SuiBox>
          ) : null}
        </SuiBox>
        <SuiBox p={2} pb={6} justifyContent="space-between" alignItems="center">
          <SuiTypography variant="h1" color="white" fontWeight="light" textAlign="center">
            {variant === "front" ? number : "."}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of AssetValueCard
AssetValueCard.defaultProps = {
  color: "dark",
  variant: "back",
};

// Typechecking props for the AssetValueCard
AssetValueCard.propTypes = {
  color: PropTypes.oneOf([
    "card1",
    "card2",
    "card3",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  number: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["front", "back"]),
};

export default AssetValueCard;
