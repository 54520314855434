import { useState, useEffect, useCallback } from "react";
import { Box, Stack, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useSnackbar, useVault } from "../../context";
import useAPI from "../../utils/api";

import SuiButton from "../../components/soft-ui/SuiButton";
import SuiTypography from "../../components/soft-ui/SuiTypography";
import SuiSelect from "../../components/soft-ui/SuiSelect";
import PageWrapper from "../../components/layout/PageWrapper";
import NoData from "../../components/NoData";
import PageLoading from "../../components/PageLoading";

import ProductCard from "./ProductCard";
import AddProductDialog from "./AddProductDialog";

const listingOptions = [
  { value: "all", label: "Community Listings" },
  { value: "mine", label: "My Listings" },
  { value: "purchased", label: "My Purchased Listings" },
];

export default function Marketplace() {
  const { user } = useVault();
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isBusiness, setIsBusiness] = useState(true);
  const [selectedOption, setSelectedOption] = useState(listingOptions[0]);

  const fetchProducts = useCallback(async () => {
    const param = isBusiness ? "business" : selectedOption.value;
    setLoading(true);

    try {
      const res = await api("products", { param });
      setProducts(res);
    } catch (err) {
      showSnackbar("Error", "Failed to fetch products", "error");
    }

    setLoading(false);
  }, [api, isBusiness, selectedOption.value, showSnackbar]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <PageWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <SuiTypography sx={{ fontSize: "24px" }}>Marketplace</SuiTypography>
        <SuiButton
          color="primary"
          circular
          sx={{ textTransform: "capitalize" }}
          startIcon={<AddIcon />}
          onClick={() => setOpenAddDialog(true)}
        >
          Add
        </SuiButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          mb: 3,
        }}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            variant={isBusiness ? "contained" : "outlined"}
            color="primary"
            label="Businesses"
            onClick={() => setIsBusiness(true)}
          />
          <Chip
            variant={!isBusiness ? "contained" : "outlined"}
            color="primary"
            label="Classified"
            onClick={() => setIsBusiness(false)}
          />
        </Stack>
        {!user.is_admin && !isBusiness && (
          <Box sx={{ width: "280px", mt: { xs: 2, sm: 0 } }}>
            <SuiSelect
              isSearchable={false}
              size="small"
              defaultValue={selectedOption}
              options={listingOptions}
              onChange={setSelectedOption}
            />
          </Box>
        )}
      </Box>
      {!!products.length && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
            gap: "40px",
          }}
        >
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              isShowStatus={!product.is_business && selectedOption.value === "mine"}
            />
          ))}
        </Box>
      )}
      {loading ? <PageLoading /> : !products.length && <NoData />}
      <AddProductDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        callback={fetchProducts}
      />
    </PageWrapper>
  );
}
