/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/soft-ui/SuiBox";
import SuiTypography from "components/soft-ui/SuiTypography";

function Separator({ content }) {
  return (
    <SuiBox position="relative" py={0.25}>
      <Divider />
      {content && (
        <SuiBox
          bgColor="white"
          position="absolute"
          top="50%"
          left="50%"
          px={1.5}
          lineHeight={1}
          sx={{ transform: "translate(-50%, -60%)" }}
        >
          <SuiTypography variant="button" fontWeight="medium" color="secondary">
            {content}
          </SuiTypography>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default Separator;
